import React from "react";
import styled from "styled-components";
import ProspectContactCard from "./SubViews/ProspectContactCard";
import ProspectContactAddressCard from "./SubViews/ProspectContactAddressCard";
import LegalDescription from "./SubViews/LegalDescription";
import ExecutivesList from "./SubViews/ExecutivesList";
import AIDescription from "./SubViews/AIDescription";

const Overview = ({ company, isLoading, summary, isLoadingSummary, refreshSummary }) => {
	return (
		<Wrapper>
			<FlexColumn>
				<ProspectContactCard company={company} />
				<LegalDescription company={company} />
				<ExecutivesList company={company} isLoading={isLoading} />
			</FlexColumn>

			<FlexColumn>
				<AIDescription summary={summary} isLoading={isLoadingSummary} refresh={refreshSummary} />
				<ProspectContactAddressCard company={company} />
			</FlexColumn>
		</Wrapper>
	);
};
export default Overview;

const Wrapper = styled.div`
	display: flex;
	gap: 10px;
`;

const FlexColumn = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
