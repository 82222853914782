import { Buffer } from "buffer";
import { inflate } from "pako";

const getSubdomain = (url: string): string | null => {
	if (!url) return null;

	let hostname = url;

	try {
		const uniqueResourceLocation = new URL(url);
		hostname = uniqueResourceLocation.hostname;
	} catch (e) {
		console.error(e);
	}

	const parts = hostname.split(".");

	if (parts.length < 3 || (parts.length === 3 && parts[0] === "www")) {
		return null;
	}

	const subdomainIndex = parts[0] === "www" ? 1 : 0;
	return parts[subdomainIndex].toLocaleLowerCase();
};

function getMainDomain(url) {
	try {
		if (!url.includes("http")) {
			url = "https://" + url;
		}

		const hostname = new URL(url).hostname;
		const parts = hostname.split(".");
		if (parts.length < 2) return null;

		return parts[parts.length - 2];
	} catch (error) {
		// If URL parsing fails, log the error and return null
		console.error("Failed to parse URL:", error);
		return null;
	}
}

function getDomainWithTLD(url) {
	try {
		if (!url.includes("http")) {
			url = "https://" + url;
		}

		const hostname = new URL(url).hostname;
		const parts = hostname.split(".");
		if (parts.length < 2) return null;

		const domainWithTld = parts.slice(-2).join(".");
		return domainWithTld;
	} catch (error) {
		console.error("Failed to parse URL:", error);
		return null;
	}
}

const decodeTokenUser = (token: string | null): any => {
	const base64Payload = token?.split(".")?.[1];
	if (!base64Payload) return null;

	// Decode the Base64-encoded payload
	const payload = JSON.parse(Buffer.from(base64Payload, "base64").toString());

	// Check if the payload is compressed
	if (payload.compressed) {
		const { compressed, ...rest } = payload;
		const compressedData = Buffer.from(compressed, "base64");
		const decompressedData = inflate(compressedData, { to: "string" });
		return {
			...(rest || {}), // exp, iat
			...JSON.parse(decompressedData),
		}; // Parse the decompressed JSON
	}

	return payload; // Return the original payload if not compressed
};

// const decodeTokenUser = (token: string | null): any => {
// 	if (!token || !token?.split(".")?.[1]) return null;
// 	const tokenData = JSON.parse(Buffer.from(token?.split(".")[1], "base64").toString());
// 	return tokenData;
// };

const TTL = 1000 * (60 * 60) * 12;

const getTokenTTL = (token: string): number => {
	try {
		// eslint-disable-next-line new-cap
		const tokenData = decodeTokenUser(token);

		// eslint-disable-next-line no-unsafe-optional-chaining
		return ((tokenData?.exp || 0) * 1000 || Date.now() + TTL) - 60000;
	} catch (error) {
		console.error("getTokenTTL error:", error);
		return Date.now() + TTL - 60000;
	}
};

// eslint-disable-next-line import/prefer-default-export
export { getSubdomain, decodeTokenUser, getTokenTTL, TTL, getMainDomain, getDomainWithTLD };
