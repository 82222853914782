import React, { Component } from "react";
import { Checkbox, TextField, Icon, OptionList } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";

class BoardFiltersOptions extends Component {
	constructor(props) {
		super(props);
		this.state = { query: "", showInactive: false };
	}

	handleChangeFilters(v) {
		const value = this.props.allowMultiple ? v : v?.[0];

		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);
		if (foundIndex < 0) {
			this.props.filters.push({
				key: this.props.column_id + "-" + this.props.board.id,
				board_id: this.props.board.id,
				column_id: this.props.column_id,
				value,
			});
		} else {
			this.props.filters[foundIndex].value = value;
		}

		this.props.onChangeFilters([...this.props.filters]);
	}

	haveInactive() {
		for (let i = 0; i < this.props.options?.length; i++) {
			if (this.props.options[i].inactive) {
				return true;
			}
		}
		return false;
	}

	render() {
		const filterRegex = new RegExp(this.state.query, "i");

		const selectedRaw =
			this.props.filters?.find((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id)?.value ?? [];
		const selected = selectedRaw !== undefined ? (Array.isArray(selectedRaw) ? selectedRaw : [selectedRaw]) : [];

		return (
			<div
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div style={{ marginBottom: 15 }}>
					<div>
						<TextField
							prefix={<Icon source={SearchMinor} />}
							placeholder={this.props.t("board.filters.option.fields.search.placeholder", "Sök...")}
							suffix={
								this.haveInactive() ? (
									<Checkbox
										label={this.props.t("board.filters.option.fields.search.suffix.label", "Alla")}
										checked={this.state.showInactive}
										onChange={() => {
											this.setState({ showInactive: !this.state.showInactive });
										}}
									/>
								) : null
							}
							value={this.state.query}
							onChange={(v) => {
								this.setState({ query: v });
							}}
						/>
					</div>
				</div>

				<OptionList
					allowMultiple={this.props.allowMultiple}
					selected={selected}
					options={
						this.props.options
							?.filter((opt) => {
								let match = true;
								if (opt.inactive === true && !this.state.showInactive) {
									match = false;
								}
								if (this.state.query && !opt.label.match(filterRegex)) {
									match = false;
								}
								return match;
							})
							.map((opt) => {
								return {
									value: opt.value,
									label: opt.label,
									media: opt.prefix,
								};
							}) || []
					}
					onChange={this.handleChangeFilters.bind(this)}
				/>
			</div>
		);
	}
}
BoardFiltersOptions.defaultProps = {
	allowMultiple: true,
};

export default withTranslation(["board", "common"], { withRef: true })(BoardFiltersOptions);
