import React, { Component } from "react";
import { Button, ActionList, FormLayout, LegacyStack, Icon, Tooltip } from "@shopify/polaris";
import { LinkMinor, WandMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../../API";
import BoardCell from "../BoardCell.js";
import { store } from "../../../store";
import BoardHelper from "../BoardHelper";
import RowCommentForm from "./RowCommentForm.js";
import Popover from "../../../components/Popover.js";
import CalendarModalContent from "./CalendarModal/CalendarModalContent.js";
import Toast from "src/js/components/Toast";

class RowModalForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			groups: props.groups ? props.groups : props.board?.groups || props.row?.board?.groups || [],
			open: props.open,
			title: props.row ? props.row.title : "",
			initialContact: props.contact,
		};

		this.state.form = {
			title:
				store.getState().user.calendar_board && store.getState().user.calendar_board.id === (props.board && props.board.id)
					? ""
					: `+ ${(props.board && props.board.singular) || props.t("row.singular", this.props.t("row.singular", "rad"))}`,
			group_id: this.getDefaultGroup(props),
			values: props.defaultValues ? props.defaultValues.slice() : [],
			column_values: this.getColumnValues(props.defaultValues ? props.defaultValues.slice() : []),
			comments: [],
		};

		this.setDefaultValues(props);
	}

	getDefaultGroup(props = this.props) {
		const group = props.defaultOptions && props.defaultOptions.group_id;
		return group || (this.state.groups.length ? this.state.groups[0].id : null);
	}

	setDefaultValues(props) {
		if (!props.row && !props.editingDefaultValues) {
			let contactAdded = false;
			let personAdded = false;

			for (let i = 0; i < props.board?.columns?.length; i++) {
				const contacts = (props.contacts || (props.contact ? [props.contact] : [])).filter((c) => c);

				if (contacts?.length && props.board.columns[i].type == "contact" && !contactAdded) {
					contactAdded = true;
					if (!this.state.form.values.find((val) => val.column_id == props.board.columns[i].id)) {
						contacts.forEach((contact) => {
							store.dispatch({
								type: "UPDATE_BOARD_CONTACT",
								contact,
							});
						});

						this.state.form.values.push({
							column_id: props.board.columns[i].id,
							value: contacts.map((contact) => contact.id),
						});
					}
				}
				if (props.board?.id == store.getState().account.sales_board_id && props.board.columns[i].type == "person" && !personAdded) {
					personAdded = true;
					if (!this.state.form.values.find((val) => val.column_id == props.board.columns[i].id)) {
						this.state.form.values.push({
							column_id: props.board.columns[i].id,
							value: [store.getState().user.id],
						});
					}
				}
			}
			this.state.form.column_values = this.getColumnValues((props.values && !!props.values.length && props.values) || this.state.form.values);
			this.setState({ form: this.state.form });
		}
	}

	componentDidMount() {
		if (this.props.board) this.fetchGroups();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
			if (this.props.board && props.open) this.fetchGroups();

			if (!props.row) {
				this.setState({
					groups: props.groups ? props.groups : [],
					title: `+ ${(this.props.board && this.props.board.singular) || this.props.t("row.singular", this.props.t("row.singular", "rad"))}`,
					form: {
						title: `+ ${(this.props.board && this.props.board.singular) || this.props.t("row.singular", "rad")}`,
						group_id: this.getDefaultGroup(),
						values: props.defaultValues ? props.defaultValues.slice() : [],
						column_values: this.getColumnValues(props.defaultValues ? props.defaultValues.slice() : []),
					},
				});

				this.setDefaultValues(props);
			} else {
				this.setState({
					groups: props.groups ? props.groups : [],
					title: props.row.title,
				});
			}
		}
	}

	changeRowTitle(title = this.state.title) {
		if (title != (this.props.row?.id ? this.props.row.title : this.state.form.title)) {
			if (this.props.row?.id) {
				this.props.row.title = title;
				store.dispatch({ type: "UPDATE_BOARD_ROW", row: this.props.row });

				API.put("/api/boards/" + this.props.board.id + "/rows/" + this.props.row.id + ".json", { title }, { params: {} })
					.then(async (result) => {
						if (result.data.error) {
							Toast.error(result.data.error);
							return;
						}
						if (this.props.onCompleteAwaited) await this.props.onCompleteAwaited(result.data.row);
						if (this.props.onComplete) this.props.onComplete(result.data.row);
						if (this.props.onUpdateRow) this.props.onUpdateRow(result.data.row);
						store.dispatch({ type: "UPDATE_BOARD_ROW", row: result.data.row });
					})
					.catch((error) => {
						Toast.error(error);
					});
			} else {
				this.state.form.title = this.state.title;
				this.setState({ form: this.state.form });
			}
		}
	}

	getGroup() {
		if (!this.state.groups || !this.state.groups.length) return null;
		const row = this.props.row?.id ? this.props.row : this.state.form;
		const group = this.state.groups.find((g) => g.id === row.group_id);
		return group;
	}

	fetchGroups() {
		API.get("/api/boards/" + this.props.board.id + "/groups.json", {
			params: {},
		})
			.then((result) => {
				if (!this.props.rows && !this.state.form.group_id && result.data.groups.length > 0) {
					const defaultGroup = this.props.defaultOptions && this.props.defaultOptions.group_id;
					this.state.form.group_id = defaultGroup || result.data.groups[0].id;
				}
				this.setState({ groups: result.data.groups });
			})
			.catch((error) => Toast.error(error));
	}

	changeGroup(group) {
		if (this.props.row?.id) {
			this.props.row.group_id = group.id;
			API.put("/api/boards/" + this.props.board.id + "/rows/" + this.props.row.id + ".json", { group_id: group.id, position: 0 }, { params: {} })
				.then((result) => {
					store.dispatch({ type: "UPDATE_BOARD_ROW", row: result.data.row });
				})
				.catch((error) => {
					Toast.error(error);
				});
			this.setState({ showGroupSelector: false });
		} else {
			this.state.form.group_id = group.id;
			this.setState({ showGroupSelector: false, form: this.state.form });
		}
	}

	saveForm() {
		return new Promise((resolve, reject) => {
			this.setState({ saving: true });
			const data = Object.assign({}, this.state.form);
			if (this.props.editingDefaultValues) {
				API.put("/api/boards/" + this.props.board.id + ".json", { default_values: data.values }, { params: {} })
					.then((result) => {
						Toast.success(this.props.t("row.responses.changed_defaults", "Ändrade standardvärden"));
						this.setState({ saving: false });
						if (result.data.board)
							store.dispatch({
								type: "UPDATE_BOARD",
								board: result.data.board,
							});
						this.props.onUpdateBoard(result.data.board);
						resolve(result.data.board);
					})
					.catch((error) => {
						Toast.error(error);
						this.setState({ saving: false });
					});
			} else {
				data.ref = Math.random();
				if (!data.title) {
					this.setState({ saving: false });
					Toast.error(this.props.t("row.errors.title_missing", "Titel måste vara satt"));
					// eslint-disable-next-line prefer-promise-reject-errors
					reject({
						status: "title missing",
						message: this.props.t("row.errors.title_missing", "Titel måste vara satt"),
					});
					return;
				}
				API.post("/api/boards/" + this.props.board.id + "/groups/" + this.state.form.group_id + "/rows.json", data, {
					params: {},
				})
					.then((result) => {
						store.dispatch({ type: "ADD_BOARD_ROW", row: result.data.row });
						if (result.data.board)
							store.dispatch({
								type: "UPDATE_BOARD",
								board: result.data.board,
							});
						if (this.props.onUpdateBoard) this.props.onUpdateBoard(result.data.board);
						if (this.props.onCreateRow) this.props.onCreateRow(result.data.row, result.data.ref);
						if (this.props.onCreate) this.props.onCreate(result.data.row, result.data.ref);
						Toast.success(
							`${this.props.t("row.terms.created_new", "Skapade ny")} ${
								(this.props.board && this.props.board.singular) || this.props.t("row.singular", "rad")
							}`
						);
						resolve(result.data.row);
						this.setState({ saving: false });
					})
					.catch((error) => {
						Toast.error(error);
						this.setState({ saving: false });
					});
			}
		});
	}

	getColumnValues(values) {
		if (!values || values.length < 1) {
			return {};
		}
		const cols = {};
		for (let i = 0; i < values.length; i++) {
			cols[values[i].column_id] = values[i];
		}
		return cols;
	}

	onUpdateDefaultValue(row, column, data) {
		let found = false;
		for (let i = 0; i < this.state.form.values.length; i++) {
			if (this.state.form.values[i].column_id == column.id) {
				this.state.form.values[i].value = data.value;
				found = true;
			}
		}
		if (!found) {
			this.state.form.values.push({ column_id: column.id, value: data.value });
		}
		this.state.form.column_values = this.getColumnValues(row.values);
		this.setState({ form: this.state.form });
	}

	async updateCell(column, data) {
		const { row } = BoardHelper.onUpdateValue(this.props.row, column, data) || {};
		if (this.props.onComplete) this.props.onComplete();
		if (this.props.onCompleteAwaited) await this.props.onCompleteAwaited();
		if (this.props.onUpdateRow) this.props.onUpdateRow(row);
	}

	getColumnIcon(column) {
		switch (column.type) {
			case "mirror":
				return (
					<span style={{ margin: 0, marginLeft: "7px" }}>
						<Icon source={LinkMinor} />
					</span>
				);
			case "formula":
				return (
					<Tooltip
						content={
							<>
								<p>{this.props.t("row.terms.formula", "Formel kolumnn som beräknad automatiskt.")}</p>
								<br />
								<p>Formel: {column.options?.formula || "-"}</p>
							</>
						}
					>
						<span style={{ margin: 0, marginLeft: "7px" }}>
							<Icon source={WandMajor} />
						</span>
					</Tooltip>
				);
			default:
				return null;
		}
	}

	render() {
		const row = this.props.row || this.state.form;
		const isCalendar = store.getState().user.calendar_board && store.getState().user.calendar_board.id == this.props.board?.id;

		if (!this.props.editingDefaultValues && isCalendar) {
			return (
				<CalendarModalContent
					row={row}
					board={this.props.board}
					updateCell={this.updateCell.bind(this)}
					onClose={this.props.onClose}
					options={this.props.options}
					onComplete={this.props.onComplete}
					onCompleteAwaited={this.props.onCompleteAwaited}
				/>
			);
		}
		return (
			<FormLayout>
				{!this.props.editingDefaultValues ? (
					<div style={{ border: "1px dashed #777" }}>
						<textarea
							placeholder="Titel.."
							style={{
								fontSize: 16,
								padding: 5,
								display: "block",
								width: "100%",
								border: 0,
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									this.changeRowTitle(this.state.title);
								} else if (event.key === "Escape") {
									this.setState({ title: row.title });
								}
							}}
							autoFocus
							onFocus={(event) => {
								event.target.style.height = "5px";
								event.target.style.height = event.target.scrollHeight + "px";
							}}
							onChange={(event) => {
								this.setState({ title: event.target.value.replace("\n", "") });
								event.target.style.height = "5px";
								event.target.style.height = event.target.scrollHeight + "px";
							}}
							onBlur={this.changeRowTitle.bind(this, this.state.title)}
							value={this.state.title}
						/>
					</div>
				) : null}
				{!this.props.editingDefaultValues && this.getGroup() && this.state.groups?.length > 1 ? (
					<LegacyStack wrap={false}>
						<div className="overflow-ellipsis" style={{ width: 130, paddingTop: 7 }}>
							{this.props.t("row.terms.group", "Grupp")}
						</div>
						<LegacyStack.Item fill>
							<Popover
								active={this.state.showGroupSelector}
								activator={
									<a
										style={{
											display: "block",
											cursor: "pointer",
											textAlign: "center",
											padding: 7,
										}}
										onClick={() => {
											this.setState({ showGroupSelector: true });
										}}
									>
										<span style={{ color: this.getGroup().color }}>{this.getGroup().title}</span>
									</a>
								}
								onClose={() => {
									this.setState({ showGroupSelector: false });
								}}
							>
								<ActionList
									items={this.state.groups.map((group) => ({
										content: <span style={{ color: group.color }}>{group.title}</span>,
										onAction: this.changeGroup.bind(this, group),
									}))}
								/>
							</Popover>
						</LegacyStack.Item>
					</LegacyStack>
				) : null}
				{this.props.board?.columns.map((column) => {
					if (!this.props.row?.id && ["created_at", "board_number", "button", "timetrack"].indexOf(column.type) >= 0) {
						// Dont show these columns
						return null;
					}

					if (!this.props.row?.id && store.getState().account.id == 15 && column.title == "Sannolikhet för affär") {
						// Just for simplify
						return null;
					}

					if (!this.props.row?.id && store.getState().account.id == 15 && column.title == "Gammalt id") {
						// Just for simplify
						return null;
					}
					if (!this.props.editingDefaultValues && !this.props.row?.id && column.type === "mirror") {
						return null;
					}
					const columnIcon = this.getColumnIcon(column);

					return (
						<LegacyStack key={column.id} wrap={!($(window).width() > 1000)}>
							<div
								className="overflow-ellipsis sheet-board-cell"
								style={{
									width: $(window).width() > 1000 ? 130 : $(window).width() - 40,
									paddingTop: 7,
									display: "flex",
								}}
							>
								{column.title} {columnIcon}
							</div>
							<LegacyStack.Item fill>
								<BoardCell
									onUpdateValue={this.props.editingDefaultValues || !this.props.row ? this.onUpdateDefaultValue.bind(this) : this.props.onUpdateValue}
									fullWidth
									board={this.props.board}
									getContact={this.props.getContact}
									onUpdateBoard={this.props.onUpdateBoard}
									onUpdateColumn={this.props.onUpdateColumn}
									column={column}
									row={row}
									initialContact={this.state.initialContact}
									fixed
									history={this.props.history}
								/>
							</LegacyStack.Item>
						</LegacyStack>
					);
				})}
				{!this.props.row?.id && (
					<RowCommentForm
						comments={
							this.state.form.comments
								?.filter((comment) => comment && this.props.board?.id == comment.board.id)
								?.map((comment) => ({
									id: comment.id,
									user: comment.user,
									name: comment.user.name,
									row: comment.row,
									board: comment.board,
									created_at: comment.created_at,
									content: comment.content,
									mentions: comment.mentions,
									files: comment.files,
								})) || []
						}
						// row={tab.row}
						board={this.props.board}
						onSave={(comment) => {
							const form = Object.assign({}, this.state.form);
							form.comments.unshift(comment);
							this.setState({ form });
						}}
						onChange={(comment) => {
							const form = Object.assign({}, this.state.form);
							form.comment = Object.assign(form.comment || {}, comment);
							this.setState({ form });
						}}
						onCreate={(comment) => {
							const form = Object.assign({}, this.state.form);
							form.comments.unshift(comment);
							this.setState({ form });
						}}
						onUpdate={(comment, index) => {
							this.state.comments[index] = comment;
							this.setState({ comments: this.state.comments });
						}}
						onRemove={(comment) => {
							this.state.comments.filter((com) => com.id !== comment.id);
							this.setState({ comments: this.state.comments });
						}}
					/>
				)}
				{!this.props.row?.id &&
					(store.getState().user.calendar_board && store.getState().user.calendar_board.id) != (this.props.board && this.props.board.id) && (
						<div style={{ padding: 15 }}>
							<Button loading={this.state.saving} onClick={this.saveForm.bind(this)} primary>
								{this.props.t("common.actions.save", "Spara")}
							</Button>
						</div>
					)}
			</FormLayout>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(RowModalForm);
