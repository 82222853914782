/* eslint-disable camelcase */
import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { createContext, use, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import API from "src/js/API";
import Toast from "src/js/components/Toast";
import useQuery from "src/js/hooks/useQuery";
import EditSheet from "./Form/EditSheet";
import BoardRowModal from "./Modals/BoardRowModal";
import { useDispatch, useSelector } from "react-redux";
// 5774;
const DialerContext = createContext({} as any);

export const useDialerContext = () => {
	return use(DialerContext);
};

export const DialerProvider = ({ children }) => {
	const { t } = useTranslation(["dialers", "common"]);
	const { id, view, dialEntryId: paramDialEntryId }: any = useParams();
	const dialEntryId = useMemo(() => (paramDialEntryId === "empty" ? null : paramDialEntryId), [paramDialEntryId]);
	const history = useHistory();
	const [editSheetIsOpen, setEditSheetIsOpen] = React.useState(false);
	const previousView = useRef(view);
	const lastUsedDialEntryId = useRef(dialEntryId);
	const [boardRowModalIsOpen, setBoardRowModalIsOpen] = React.useState<{
		isOpen: boolean;
		status: any;
		boardRow?: any;
	}>({
		isOpen: false,
		status: null,
		boardRow: null,
	});
	const dispatch = useDispatch();

	const storeUser = useSelector((state: any) => state.user);
	const storeAccount = useSelector((state: any) => state.account);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (dialEntryId) {
			lastUsedDialEntryId.current = dialEntryId;
		}
	}, [dialEntryId]);

	const toggleEditSheet = useCallback(
		(open = !editSheetIsOpen) => {
			setEditSheetIsOpen(open);
		},
		[editSheetIsOpen]
	);

	const queryKey = useMemo(() => ["dialEntry", dialEntryId], [dialEntryId]);
	const { data: dialEntry, isFetching: isLoadingDialEntry }: UseQueryResult<DialEntryType> = useQuery({
		queryKey,
		queryFn: async () => {
			const res = await API.get(`/api/dial_entries/${dialEntryId}.json`);

			if (res.data.dial_entry?.contact) {
				dispatch({ type: "UPDATE_BOARD_CONTACT", contact: res.data.dial_entry.contact });
			}

			return res.data.dial_entry;
		},
		enabled: !!dialEntryId,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	const fetchNextDialEntry = useCallback(async () => {
		try {
			const res = await API.get(`/api/dial_lists/${id}/dial_entry.json`);
			return res.data.dial_entry;
		} catch (e) {
			if (paramDialEntryId !== "empty") Toast.error(e);
			throw e;
		}
	}, [id, paramDialEntryId]);

	useEffect(() => {
		(async () => {
			if (!dialEntryId && view === "view") {
				if (lastUsedDialEntryId.current && paramDialEntryId !== "empty") {
					history.replace(`/admin/dial_lists/${id}/view/${lastUsedDialEntryId.current}`);
				} else {
					try {
						const dialEntry: any = await fetchNextDialEntry();
						const url = `/admin/dial_lists/${id}/view/${dialEntry?.id || "empty"}`;
						history.replace(url);
					} catch (error: any) {
						if (error?.response?.status === 404) {
							const url = `/admin/dial_lists/${id}/view/empty`;
							history.replace(url);
						} else {
							Toast.error(error);
						}
					}
				}
			}
		})();
	}, [fetchNextDialEntry, history, id, dialEntryId, view, paramDialEntryId]);

	const queryListKey = useMemo(() => ["dial_list", id], [id]);
	const { data: dialList, isFetching: isLoadingDialList }: UseQueryResult<any> = useQuery({
		queryKey: queryListKey,
		queryFn: async () => {
			const res = await API.get(`/api/dial_lists/${id}.json`);
			return res.data.dial_list;
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	const queryDialStatusesKey = useMemo(() => ["dial_statuses", id], [id]);
	const { data: dialStatuses }: UseQueryResult<any> = useQuery({
		queryKey: queryDialStatusesKey,
		queryFn: async () => {
			const res = await API.get(`/api/dial_statuses.json`);
			return res.data.dial_statuses;
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
		staleTime: 1000 * 60 * 60, // 1 hour
	});

	const queryStatsKey = useMemo(() => ["dial_list", "stats", id], [id]);
	const {
		data: stats,
		isFetching: isLoadingStats,
		refetch: refreshStats,
	}: UseQueryResult<any> = useQuery({
		queryKey: queryStatsKey,
		queryFn: async () => {
			const res = await API.get(`/api/dial_lists/${id}/stats.json`);
			return res.data;
		},
		enabled: !!id,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
	});

	const queryBoardRowsKey = useMemo(() => ["dialEntry", dialEntryId, "boardRows"], [dialEntryId]);
	const { data: boardRows }: UseQueryResult<any> = useQuery({
		queryKey: queryBoardRowsKey,
		queryFn: async () => {
			const res = await API.get(`/api/dial_entries/${dialEntryId}/board_rows.json`);
			return res.data?.board_rows;
		},
		enabled: !!dialEntryId,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (["card_list", "column_list"].includes(view) && !["card_list", "column_list"].includes(previousView.current)) {
			refreshStats();
		}
		previousView.current = view;
	}, [view, refreshStats]);

	const onAnyAction = useCallback(
		async (data) => {
			if (data.dial_entry) {
				queryClient.setQueryData(["dial_entry", data.dial_entry.id], data.dial_entry);
			}
			if (data.next_dial_entry) {
				queryClient.setQueryData(["dial_entry", data.next_dial_entry.id], data.next_dial_entry);
			}

			if (view === "view") {
				lastUsedDialEntryId.current = null;

				const url = `/admin/dial_lists/${id}/view/${data?.next_dial_entry?.id || "empty"}`;
				history.replace(url);
			}
		},
		[history, id, queryClient, view]
	);

	const handleStatusAction = useCallback(
		async (status, boardRow: any = null) => {
			if (!boardRow && status?.action === "CREATE_BOARD_ROW") {
				setBoardRowModalIsOpen({ status, isOpen: true });
				return;
			}

			const res = await API.put(`/api/dial_entries/${dialEntryId}/set_dial_status.json`, {
				dial_status: status,
				board_row_id: boardRow?.id,
			});
			queryClient.invalidateQueries(queryKey);
			queryClient.setQueryData(queryKey, res.data.dial_entry);

			Toast.success(t("dialers.actions.setStatus.success", "Status uppdaterad"));

			onAnyAction(res.data);
			return res.data.dial_entry;
		},
		[onAnyAction, dialEntryId, queryKey, queryClient, t]
	);

	const handleSkipAction = useCallback(async () => {
		try {
			const res = await API.get(`/api/dial_entries/${dialEntryId}/next.json`);
			const url = `/admin/dial_lists/${id}/view/${res.data?.dial_entry?.id || "empty"}`;
			history.replace(url);
		} catch (error) {
			Toast.error(error);
		}
	}, [history, dialEntryId, id]);

	// addd mutation for dialList
	const handleEditDialListMutation = useMutation(
		async (data) => {
			const res = await API.put(`/api/dial_lists/${id}.json`, data);
			Toast.success(t("dials.actions.edit_dial_list.success", "Ringlistan har uppdaterats"));
			return res?.data.dial_list;
		},
		{
			onSuccess: (data) => {
				if (queryListKey?.length && id) queryClient.setQueryData(queryListKey, data);
			},
		}
	);

	const handleEditDialList = useCallback(
		async (data) => {
			try {
				await handleEditDialListMutation.mutateAsync(data);
			} catch (error) {
				Toast.error(error);
			}
		},
		[handleEditDialListMutation]
	);

	const handleDuplicateDialList = useCallback(async () => {
		try {
			const res = await API.post(`/api/dial_lists/${id}/duplicate.json`);
			Toast.success(
				<>
					<p>{t("dials.actions.duplicate_dial_list.success", "Ringlistan har duplicerats")}</p>
					<b>{t("dials.actions.duplicate_dial_list.success_navigate", "Klicka för att öppna ringlistan")}</b>
				</>,
				{
					onClick: () => {
						if (res.data.dial_list?.id) history.push(`/admin/dial_lists/${res.data.dial_list?.id}`);
					},
				}
			);
		} catch (error) {
			Toast.error(error);
		}
	}, [id, history, t]);

	const handleImportToContact = useCallback(
		async (prosId = dialEntryId, data) => {
			const res = await API.post(`/api/dial_entries/${prosId}/import/contact.json`, data);

			Toast.success(
				<>
					<p>{t("dial_lists.actions.handleImportToContact.success", "Dial entry har importerats till kontakter")}</p>

					<b>{t("dial_lists.actions.handleImportToContact.success_navigate", "Klicka för att öppna kontakten")}</b>
				</>,
				{
					onClick: () => {
						if (res.data.contact?.id) history.push(`/admin/contacts/${res.data.contact?.id}`);
					},
				}
			);

			onAnyAction(res.data);

			return res;
		},
		[dialEntryId, t, onAnyAction, history]
	);

	const handleImportToContactAndAsBoardrowInSalesBoard = useCallback(
		async (prosId = dialEntryId, data) => {
			const res = await API.post(`/api/dial_entries/${prosId}/import/sales_board.json`, data);

			Toast.success(t("dial_lists.actions.handleImportToContact.success", "Dial entry har importerats till kontakter och affär skapad"));

			onAnyAction(res.data);

			return res;
		},
		[dialEntryId, t, onAnyAction]
	);

	const groupedBoardRows = useMemo(() => {
		if (!boardRows) return {};

		return boardRows.reduce(
			(acc, row) => {
				const { board_id } = row;

				if ([storeUser.todo_board_id, storeUser.calendar_board_id, storeAccount.todo_board_id].includes(board_id)) {
					acc.reminders.rows.push(row);
					return acc;
				}

				if ([storeAccount.sales_board_id].includes(board_id)) {
					acc.deals.rows.push(row);
					return acc;
				}

				acc.rest.rows.push(row);
				return acc;
			},
			{
				reminders: {
					title: t("dialers.views.board_row_modal.reminders", "Påminnelser"),
					rows: [],
				},
				deals: {
					title: t("dialers.views.board_row_modal.deals", "Affärer"),
					rows: [],
				},
				rest: {
					title: t("dialers.views.board_row_modal.rest", "Övriga board rader"),
					rows: [],
				},
			}
		);
	}, [boardRows, storeUser.todo_board_id, storeUser.calendar_board_id, storeAccount.todo_board_id, storeAccount.sales_board_id, t]);

	const handleOpenBoardRow = useCallback((boardRow) => {
		setBoardRowModalIsOpen({
			isOpen: true,
			status: null,
			boardRow,
		});
	}, []);

	const value = useMemo(
		() => ({
			t,
			id,
			view,
			dialEntryId,
			dialEntry,
			company: dialEntry?.axicon_company,
			contact: dialEntry?.contact,
			isLoading: isLoadingDialEntry,
			dialList,
			handleStatusAction,
			dialStatuses,
			isLoadingDialList,
			handleSkipAction,
			toggleEditSheet,
			editSheetIsOpen,
			handleEditDialList,
			stats,
			isLoadingStats,
			handleDuplicateDialList,
			handleImportToContact,
			handleImportToContactAndAsBoardrowInSalesBoard,
			boardRows,
			groupedBoardRows,
			handleOpenBoardRow,
		}),
		[
			t,
			id,
			view,
			dialEntryId,
			dialEntry,
			isLoadingDialEntry,
			dialList,
			handleStatusAction,
			dialStatuses,
			isLoadingDialList,
			handleSkipAction,
			toggleEditSheet,
			editSheetIsOpen,
			handleEditDialList,
			stats,
			isLoadingStats,
			handleDuplicateDialList,
			handleImportToContact,
			handleImportToContactAndAsBoardrowInSalesBoard,
			boardRows,
			groupedBoardRows,
			handleOpenBoardRow,
		]
	);

	return (
		<DialerContext value={value}>
			{children}

			{/* <ImportProspectModal
				isOpen={importModalIsOpen}
				onClose={() => {
					setImportModalIsOpen(false);
					setTemporalProspectId(null);
				}}
				prospectId={paramDialEntryId}
			/>

			<EditSheet /> */}

			<EditSheet />
			<BoardRowModal
				boardRow={boardRowModalIsOpen?.boardRow}
				board={boardRowModalIsOpen?.boardRow?.board as any}
				dialList={dialList}
				dialEntry={dialEntry}
				isOpen={boardRowModalIsOpen?.isOpen}
				onClose={() =>
					setBoardRowModalIsOpen({
						isOpen: false,
						status: null,
					})
				}
				onCompleteAwaited={async (boardRow) => {
					if (boardRowModalIsOpen?.status) {
						setBoardRowModalIsOpen({
							isOpen: false,
							status: null,
						});

						await handleStatusAction(boardRowModalIsOpen?.status, boardRow);
					}
				}}
			/>
		</DialerContext>
	);
};

export default DialerContext;
