import React from "react";
import { Button, ActionList } from "@shopify/polaris";
import Popover from "../../../components/Popover";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import Spinner from "src/js/components/Spinner";

type MyActionListProps = {
	disclosure?: boolean;
	activatorProps?: any;
	title?: string | React.ReactNode;
	items: any[];
	activator?: any;
	closeOnAction?: boolean;
	disabled?: boolean;
};

const MyActionList = ({
	disclosure = false,
	activatorProps = {},
	title = "",
	items,
	activator: propsActivator = null,
	closeOnAction = true,
	disabled,
}: MyActionListProps) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [loadingItemStates, setLoadingItemStates] = React.useState(items.reduce((acc, item, index) => ({ ...acc, [item.id || index]: false }), {}));

	const toggleActive = (e: any = null) => {
		e?.preventDefault?.();
		e?.stopPropagation?.();

		setIsOpen((c) => !c);
	};

	const activator = React.cloneElement(
		propsActivator || (
			<Button
				onClick={toggleActive}
				disclosure={disclosure && isOpen}
				plain
				icon={MobileVerticalDotsMajor}
				disabled={disabled}
				{...(activatorProps || {})}
			>
				{title}
			</Button>
		),
		{
			onClick: toggleActive,
			disabled,
		}
	) as any;

	const itemsWithLoading = items.map((item, index) => {
		const { id } = item;
		const loading = loadingItemStates[id || index] || false;

		return {
			...item,
			suffix: (loading && <Spinner size="small" />) || item.suffix,
			onAction: async () => {
				if (item.onAction) {
					try {
						setLoadingItemStates((c) => ({ ...c, [id || index]: true }));
						await item.onAction();
					} finally {
						setLoadingItemStates((c) => ({ ...c, [id || index]: false }));
					}
				}
			},
		};
	});

	return (
		<Popover active={isOpen} activator={activator} onClose={toggleActive}>
			<ActionList
				onActionAnyItem={() => {
					if (closeOnAction) {
						toggleActive();
					}
				}}
				// items={this.props.items.map((i) =>
				// 	Object.assign(
				// 		{
				// 			i,
				// 		},
				// 		{
				// 			onAction: () => {
				// 				i.onAction();
				// 				this.toggleActive();
				// 			},
				// 		}
				// 	)
				// )}
				items={itemsWithLoading}
			/>
		</Popover>
	);
};

export default MyActionList;
