import { Icon } from "@shopify/polaris";
import { OrderStatusMinor } from "@shopify/polaris-icons";
import { StatsBoxItem } from "src/js/components/statsBoxes/types";

type DialStatusTypeBox = DialStatusType & {
	count: number;
};

const getStatsBoxes = (stats, isLoadingStats, t): StatsBoxItem[] => {
	return stats?.map((stat: DialStatusTypeBox) => ({
		title: stat.title,
		text: stat.count,
		loading: isLoadingStats,
		color: stat.colour,
		icon: <Icon source={OrderStatusMinor} />,
	}));
};

export default getStatsBoxes;
