import React, { Component } from "react";
import { ActionList, Labelled, LegacyStack } from "@shopify/polaris";

import { withTranslation } from "react-i18next";
import Popover from "./Popover";
import Colors from "../Colors";
import NumberBadge from "./NumberBadge";

class RelationshipSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			relationshipPopoverActive: false,
			relationship: props.relationship,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.relationship != this.state.relationship && (props.relationship != this.props.relationship || !this.state.relationship)) {
			this.setState({ relationship: props.relationship });
		}
	}

	toggleRelationShipPopover() {
		if (!this.props.onChange) return null;
		if (!this.state.relationshipPopoverActive && this.props.onClick) {
			this.props.onClick();
			return;
		}
		this.setState({ relationshipPopoverActive: !this.state.relationshipPopoverActive });
	}

	changeRelationship(value) {
		this.setState({ relationship: value });
		this.toggleRelationShipPopover();
		if (this.props.onChange) this.props.onChange(value);
	}

	getRelationshipStyle(relationship = this.state.relationship) {
		if (relationship == "none") {
			return { color: "#ffffff", textColor: "rgb(15,15,15)", relationshipTitle: this.props.t("relationship.statuses.none", "Ingen relation") };
		} else if (relationship == "contacted") {
			return { color: Colors.blue, relationshipTitle: this.props.t("relationship.statuses.contacted", "Varit i kontakt") };
		} else if (relationship == "in_progress") {
			return { color: Colors.yellow, relationshipTitle: this.props.t("relationship.statuses.in_progress", "Jobbar på det") };
		} else if (relationship == "customer") {
			return { color: Colors.green, relationshipTitle: this.props.t("relationship.statuses.customer", "Är kund") };
		} else if (relationship == "ex_customer") {
			return { color: Colors.red, relationshipTitle: this.props.t("relationship.statuses.ex_customer", "Har varit kund") };
		}

		return { color: "#ffffff", textColor: "rgb(15,15,15)", relationshipTitle: this.props.t("relationship.statuses.none", "Ingen relation") };
	}

	render() {
		const { color, textColor, relationshipTitle } = this.getRelationshipStyle();

		const button = this.props.button ? (
			this.props.button({
				title: relationshipTitle,
				// icon: !this.props.compact && icon,
				onClick: this.toggleRelationShipPopover.bind(this),
				color,
			})
		) : (
			<span
				className="relationship-button"
				style={{ cursor: !this.props.disabled ? "pointer" : "initial", pointerEvents: this.props.disabled ? "none" : "initial" }}
				disabled={this.props.disabled}
				onClick={this.toggleRelationShipPopover.bind(this)}
			>
				<LegacyStack spacing="extraTight" wrap={false}>
					<NumberBadge textColor={textColor} color={color} fontSize={this.props.fontSize || "0.8em"} round style={this.props.style}>
						{relationshipTitle}
					</NumberBadge>
					{/* <Badge status={status} progress={progress}>
						{relationshipTitle}
					</Badge> */}
				</LegacyStack>
			</span>
		);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<LegacyStack>
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<Popover active={this.state.relationshipPopoverActive} activator={button} onClose={this.toggleRelationShipPopover.bind(this)}>
							<ActionList
								items={[
									{
										content: (() => {
											const { color, textColor, relationshipTitle } = this.getRelationshipStyle("none");

											return (
												<NumberBadge textColor={textColor} color={color} fontSize={this.props.fontSize || "0.8em"} round>
													{relationshipTitle}
												</NumberBadge>
											);
										})(),
										// icon: QuestionMarkMajor,
										onAction: this.changeRelationship.bind(this, "none"),
									},
									{
										content: (() => {
											const { color, textColor, relationshipTitle } = this.getRelationshipStyle("contacted");

											return (
												<NumberBadge textColor={textColor} color={color} fontSize={this.props.fontSize || "0.8em"} round>
													{relationshipTitle}
												</NumberBadge>
											);
										})(),
										// icon: SmileyNeutralMajor,
										onAction: this.changeRelationship.bind(this, "contacted"),
									},
									{
										content: (() => {
											const { color, textColor, relationshipTitle } = this.getRelationshipStyle("in_progress");

											return (
												<NumberBadge textColor={textColor} color={color} fontSize={this.props.fontSize || "0.8em"} round>
													{relationshipTitle}
												</NumberBadge>
											);
										})(),
										// icon: SmileyHappyMajor,
										onAction: this.changeRelationship.bind(this, "in_progress"),
									},
									{
										content: (() => {
											const { color, textColor, relationshipTitle } = this.getRelationshipStyle("customer");

											return (
												<NumberBadge textColor={textColor} color={color} fontSize={this.props.fontSize || "0.8em"} round>
													{relationshipTitle}
												</NumberBadge>
											);
										})(),
										// icon: SmileyJoyMajor,
										onAction: this.changeRelationship.bind(this, "customer"),
									},
									{
										content: (() => {
											const { color, textColor, relationshipTitle } = this.getRelationshipStyle("ex_customer");

											return (
												<NumberBadge textColor={textColor} color={color} fontSize={this.props.fontSize || "0.8em"} round>
													{relationshipTitle}
												</NumberBadge>
											);
										})(),
										// icon: SmileySadMajor,
										onAction: this.changeRelationship.bind(this, "ex_customer"),
									},
								]}
							/>
						</Popover>
					</span>
				</LegacyStack>
			</Labelled>
		);
	}
}
export default withTranslation(["relationship", "common"], { withRef: true })(RelationshipSelector);
