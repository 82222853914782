import React from "react";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import { AxiconBarGraph, AxiconLineGraph } from "src/js/views/Contacts/axiconGraphs/AxiconGraphs";
import IconWithBackround from "src/js/components/IconWithBackround";
import Colors, { colorToRgba } from "src/js/Colors";
import { DollarIcon } from "src/js/icons";
import { CalendarMajor, CustomersMajor } from "@shopify/polaris-icons";
import styled from "styled-components";

const Economy = ({ company, isLoading }) => {
	const profitMargin = company?.graphs?.series?.find((series) => series.handle === "profit_margin")?.data?.slice(-1)[0] as number;
	const boxes = [
		{
			title: "Anställda",
			text: company?.graphs?.series?.find((series) => series.handle === "employees")?.data.slice(-1)[0],
			helpText: "",
			icon: CustomersMajor,
			color: Colors.blue,
		},
		{
			title: "Vinstmarginal (%)",
			text: `${profitMargin}%`,
			helpText: "",
			icon: <DollarIcon />,
			color: profitMargin >= 0 ? Colors.green : Colors.red,
		},
		{
			title: "Nästa bokslut",
			text: company?.financial_year_end?.slice(0, -3),
			helpText: "",
			icon: CalendarMajor,
			color: profitMargin >= 0 ? Colors.green : Colors.red,
			enabled: company?.financial_year_end,
		},
	].filter((b) => !("enabled" in b) || b.enabled);

	return (
		<Wrapper>
			<GridArea area="boxes">
				<StatsBoxWrapper
					style={{ justifyContent: "flex-start", margin: 0 }}
					perRow={3}
					items={boxes?.map((stat: any) => ({
						title: stat.title,
						text: stat.text,
						helpText: stat.helpText,
						loading: isLoading,
						icon: stat.icon && (
							<IconWithBackround
								// icon={stat.icon}
								icon={React.isValidElement(stat.icon) ? React.cloneElement(stat.icon, { color: stat.color }) : stat.icon}
								backgroundColor={colorToRgba(stat.color, 0.2)}
							/>
						),
					}))}
				/>
			</GridArea>
			<GridArea area="barGraph">
				<AxiconBarGraph loading={isLoading} graphs={company?.graphs} />
			</GridArea>

			<GridArea area="lineGraph">
				<AxiconLineGraph loading={isLoading} graphs={company?.graphs} />
			</GridArea>
		</Wrapper>
	);
};
export default Economy;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas: "boxes" "barGraph" "lineGraph";
	gap: 1rem;
`;

const GridArea = styled.div<{ area: string }>`
	grid-area: ${(props) => props.area};
`;
