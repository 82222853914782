import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { Wrapper } from "./styles";
import Select from "src/js/components/select/Select";
import Stats from "./Views/Stats";
import ProspectCompany from "./Views/ProspectCompany";
import CardList from "./Views/CardList";
import BasicList from "./Views/BasicList";
import { ProspectProvider, useProspectContext } from "./Context/ProspectContext";
import { Route, Switch, useHistory } from "react-router-dom";
import CustomRedirect from "src/js/components/CustomRedirect/CustomRedirect";
import ProspectCompanyEmptyScreen from "./Views/ProspectCompany/Views/ProspectCompanyEmptyScreen";

const ProspectView = () => {
	const { t } = useTranslation(["prospects", "common"]);
	const { id, view, prospectList, handleLoadMoreProspects, toggleEditSheet, handleRemoveProspectList, matchesLeftThisMonth } = useProspectContext();
	const history = useHistory();

	const views: any[] = useMemo(() => {
		return [
			{
				id: `prospect/empty`,
				exact: true,
				label: t("prospects.view.prospect", "Kortvy"),
				render: <ProspectCompanyEmptyScreen />,
				selectedble: false,
			},
			{
				id: `prospect`,
				label: t("prospects.view.prospect", "Kortvy"),
				render: <ProspectCompany />,
			},

			{
				id: "card_list",
				label: t("prospects.view.card_list", "Detaljerad lista"),
				render: <CardList />,
			},
			{
				id: "column_list",
				label: t("prospects.view.column_list", "Lista"),
				render: <BasicList />,
			},
			{
				id: "stats",
				label: t("prospects.view.stats", "Stats"),
				render: <Stats />,
			},
		];
	}, [t]);

	const title = prospectList?.title;

	const viewOptions = views.reduce((acc, view) => {
		if (acc.selectedble || !("selectedble" in view)) {
			acc.push({
				label: view.label,
				value: view.id,
			});
		}

		return acc;
	}, []);

	return (
		<Page
			title={title}
			secondaryActions={[
				{
					content: t("prospects.actions.loadMore", "Hämta fler företag") as string,
					onAction: handleLoadMoreProspects,
					confirmation: {
						title: t("prospects.actions.loadMore.confirmation.title", "Är du säker på att du vill hämta fler företag?") as string,
						content: (
							<Trans
								i18nKey="prospects.actions.loadMore.confirmation.content"
								defaults="Du kan impotera <b>{{matchesLeftThisMonth}}</b> fler denna månad."
								values={{ matchesLeftThisMonth }}
								components={{ b: <b /> }}
							/>
						),
					},
				},
				{
					content: t("prospects.list.actions.edit.label", "Redigera lista") as string,
					onAction: () => {
						toggleEditSheet(true);
					},
				},
				{
					content: t("prospects.list.actions.delete.label", "Ta bort") as string,
					destructive: true,
					onAction: handleRemoveProspectList,
					confirmation: {
						title: t("prospects.list.actions.delete.confirmation.title", "Är du säker på att du vill ta bort listan?") as string,
						content: t(
							"prospects.list.actions.delete.confirmation.content",
							"Alla prospekter kommer att tas bort. Kontakter, affärer osv.. som är importerade kommer ej tas bort."
						) as string,
					} as any,
				},
			]}
			primaryAction={
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
					}}
				>
					<p>{t("prospects.view.select", "Välj vy")}</p>

					<Select
						options={viewOptions}
						value={view}
						onChange={(value) => {
							history.push(`/admin/prospect_lists/${id}/${value}`);
						}}
						primary
						minWidth={100}
					/>
				</div>
			}
		>
			<Wrapper>
				<Switch>
					{views.map((view) => {
						return (
							<Route key={view.id} exact={view.exact} path={`/admin/prospect_lists/:id/${view.id}`}>
								{view.render}
							</Route>
						);
					})}

					<Route path="*">
						<CustomRedirect to={`/admin/prospect_lists/${id}/card_list`} replace />;
					</Route>
				</Switch>

				{/* {typeof view?.render === "function" ? view?.render() : view?.render} */}
			</Wrapper>
		</Page>
	);
};

export default (props) => {
	return (
		<ProspectProvider {...props}>
			<ProspectView {...props} />
		</ProspectProvider>
	);
};
