import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const baseIcons = ["❅", "❆"];
// Multiply the base icons to get a denser snowfall
const icons = Array.from({ length: 20 }, () => baseIcons).flat();

const Snowing = () => {
	const account = useSelector((state: any) => state.account);
	const snowingAccounts = [145, 1514, 24];
	if (!snowingAccounts.includes(account?.id)) return null;

	return (
		<Wrapper aria-hidden="true">
			{icons.map((icon, index) => (
				<SnowFlake key={index}>
					<Inner>{icon}</Inner>
				</SnowFlake>
			))}
		</Wrapper>
	);
};

export default React.memo(Snowing);

const SnowFlake = styled.div``;
const Inner = styled.div``;

const Wrapper = styled.div`
	@keyframes snowflakes-fall {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(110vh);
		}
	}

	@keyframes snowflakes-shake {
		0%,
		100% {
			transform: translateX(0);
		}
		50% {
			transform: translateX(80px);
		}
	}

	${SnowFlake},
	${Inner} {
		animation-iteration-count: infinite;
		animation-play-state: running;
	}

	/* SnowFlake shakes side to side */
	${SnowFlake} {
		position: fixed;
		top: -10%;
		z-index: 9999;
		-webkit-user-select: none;
		user-select: none;
		cursor: default;
		animation-name: snowflakes-shake;
	}

	/* Inner actually falls downward */
	${Inner} {
		animation-name: snowflakes-fall;
		/* We'll override the duration below with a random value. */
	}

	/* Dynamically inject random left positions, delays, and speeds for each snowflake */
	${icons
		.map((icon, index) => {
			const size = (Math.floor(Math.random() * 10) + 1) / 10;

			// Random left position (0–100%)
			const randomLeft = Math.floor(Math.random() * 100);
			// Random delay (0–10s)
			const randomDelay = Math.floor(Math.random() * 10);

			// Random shake duration (2–4s)
			const randomShakeDuration = ((2 + Math.random() * 2) / size).toFixed(2);
			// Random fall duration (8–12s)
			const randomFallDuration = ((8 + Math.random() * 4) / size).toFixed(2);

			return `
        /* nth-of-type is 1-based, so we add +1 to the index */
        ${SnowFlake}:nth-of-type(${index + 1}) {
          left: ${randomLeft}%;
          animation-delay: ${randomDelay}s;
          animation-duration: ${randomShakeDuration}s;
          animation-timing-function: ease-in-out;
          font-size: ${size}vmin;
          opacity: ${size};
        }

        ${SnowFlake}:nth-of-type(${index + 1}) ${Inner} {
          animation-delay: ${randomDelay}s;
          animation-duration: ${randomFallDuration}s;
          animation-timing-function: linear;
        }
      `;
		})
		.join("")}
`;
