import { FormLayout } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import Select from "src/js/components/select/Select";
import { useProspectContext } from "../../Context/ProspectContext";
import SelectSearchField from "src/js/components/SelectSearchField";

type ImportProspectModalProps = {
	onClose: () => void;
	isOpen: boolean;
	prospectId?: string;
	prospectIds?: string[];
};

const ImportProspectModal = ({ onClose, isOpen, prospectId, prospectIds }: ImportProspectModalProps) => {
	const {
		handleImportToContact,
		handleImportToContactAndAsBoardrowInSalesBoard,
		handleImportToDialList,
		handleImportToContactBulk,
		handleImportToContactAndAsBoardrowInSalesBoardBulk,
		handleImportToDialListBulk,
	} = useProspectContext();
	const [comment, setComment] = useState("");
	const [dialList, setDialList] = useState<any>();
	const { t } = useTranslation(["prospects", "common"]);
	const importMethodsOptions = useMemo(
		() => [
			{
				label: t("prospects.actions.import.modal.importContact", "Importera kontakt"),
				value: "handleImportToContact",
			},
			{
				label: t("prospects.actions.import.modal.importContactAndCreateDeal", "Importera kontakt och skapa affär"),
				value: "handleImportToContactAndAsBoardrowInSalesBoard",
			},
			{
				label: t("prospects.actions.import.modal.importContactToCallList", "Importera kontakt till ringlista"),
				value: "handleImportToDialList",
			},
		],
		[t]
	);

	const [importMethod, setImportMethod] = useState(importMethodsOptions[0].value);

	useEffect(() => {
		if (!isOpen) {
			setComment("");
			setDialList(null);
		}
	}, [isOpen]);

	const handleOnClose = () => {
		onClose();
	};

	const handleOnConfirm = async () => {
		switch (importMethod) {
			case "handleImportToContact":
				if (!prospectId && prospectIds?.length) return await handleImportToContactBulk(prospectIds, { comment });

				return await handleImportToContact(prospectId, { comment });
			case "handleImportToContactAndAsBoardrowInSalesBoard":
				if (!prospectId && prospectIds?.length) return await handleImportToContactAndAsBoardrowInSalesBoardBulk(prospectIds, { comment });

				return await handleImportToContactAndAsBoardrowInSalesBoard(prospectId, { comment });
			case "handleImportToDialList":
				if (!prospectId && prospectIds?.length) return await handleImportToDialListBulk(prospectIds, { comment, dial_list: dialList });

				return await handleImportToDialList(prospectId, { comment, dial_list: dialList });
			default:
				break;
		}

		// await handleLikeActionDirectly(prospectId);
	};

	const disabled = importMethod === "handleImportToDialList" && !dialList;

	return (
		<Modal
			open={isOpen}
			title={t("prospects.actions.import.modal.title", "Importera prospekt")}
			onClose={handleOnClose}
			primaryAction={{
				content: t("prospects.actions.import.modal.confirm", "Importera") as string,
				onAction: handleOnConfirm,
				disabled,
			}}
			secondaryActions={[
				{
					content: "Avbryt",
					onAction: handleOnClose,
				},
			]}
			sectioned
		>
			<FormLayout>
				<Select
					value={importMethod}
					onChange={setImportMethod}
					label={t("prospects.actions.import.modal.selectMethod", "Välj hur du vill importera") as string}
					options={importMethodsOptions}
				/>

				<TextField
					label="Kommentar"
					value={comment}
					onChange={setComment}
					placeholder={t("prospects.actions.import.modal.commentPlaceholder", "Kommentar") as string}
					helpText={t("prospects.actions.import.modal.commentHelpText", "En kommentar som sparas på kundkortet & prospekten") as string}
					multiline={2}
				/>

				{importMethod === "handleImportToDialList" && (
					<SelectSearchField
						label={t("prospects.actions.import.modal.selectDialList", "Välj ringlista") as string}
						placeholder={t("prospects.actions.import.modal.selectDialListPlaceholder", "Välj ringlista") as string}
						onSelect={(dialList) => {
							setDialList(dialList);
						}}
						value={dialList?.id}
						resourceName={{ singular: "ringlista", plural: "ringlistor" }}
						resource="dial_lists"
						resourceHandle="dial_lists"
						resourceURL="/api/dial_lists.json"
						labelHandle="title"
						onClear={() => setDialList(null)}
						renderValue={(value, data) => {
							return data?.find((item) => item.id == value)?.title || (value === dialList?.id ? dialList?.title : value);
						}}
					/>
				)}
			</FormLayout>
		</Modal>
	);
};
export default ImportProspectModal;
