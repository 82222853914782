import React from "react";
import IndexTable from "src/js/components/IndexTable";
import { numberFormatter } from "src/js/Utilities";
import Badge from "src/js/components/Badge";
import { useHistory } from "react-router-dom";
// import getStatsBoxes from "../../Utilities/getStatsBoxes";
import { useDialerContext } from "../../DialerContext";
import Colors from "src/js/Colors";
import getStatsBoxes from "../../Utilities/getStatsBoxes";

const BasicList = () => {
	const { id, t, stats, isLoadingStats, dialStatuses } = useDialerContext();
	const history = useHistory();

	return (
		<IndexTable
			stats={getStatsBoxes(stats, isLoadingStats, t)}
			defaultSort="ID_ASC"
			filters={[
				{
					key: "dial_statuses",
					label: t("dial_entry.fields.status.label", "Status"),
					type: "options",
					allowMultiple: true,
					shortcut: true,
					options:
						dialStatuses?.map((status) => ({
							label: status.title,
							value: status.id,
						})) || [],
				},
			]}
			// promotedBulkActions={bulkActions}
			inline
			limit={20}
			resourceUrl={`/api/dial_lists/${id}/dial_entries.json`}
			resourceHandle="dial_entries"
			resourceName={{
				singular: "ringlist entry",
				plural: "ringlistor entries",
			}}
			onClick={(row) => {
				history.push(`/admin/dial_lists/${id}/view/${row.id}`);
			}}
			columns={[
				{
					handle: "TITLE",
					label: t("dial_entry.fields.title.label", "Titel"),
					render: (row: DialEntryType) => row.title,
				},
				{
					handle: "STATUS",
					label: t("dial_entry.fields.status.label", "Status"),
					render: (row: DialEntryType) => {
						if (!row.dial_status) return null;
						return <Badge color={row.dial_status?.colour}>{row.dial_status.title}</Badge>;
					},
				},
				{
					handle: "TYPE",
					label: t("dial_entry.fields.type.label", "Typ"),
					render: (row: DialEntryType) => {
						switch (row.type) {
							case "CONTACT":
								return <Badge color={Colors.accent}>{t("dial_entry.fields.type.contact", "CRM Kontakt")}</Badge>;
							case "PROSPECT":
								return <Badge color={Colors.green}>{t("dial_entry.fields.type.company", "Prospekt")}</Badge>;
							case "EXCEL":
								return <Badge color={Colors.blue}>{t("dial_entry.fields.type.excel", "Excelimport")}</Badge>;
							default:
								return null;
						}
					},
				},
				{
					handle: "ORGANISATIONAL_NUMBER",
					label: t("dial_entry.fields.organisational_number.label", "Organisationsnummer"),
					render: (row: DialEntryType) => row.axicon_company?.org_number,
				},
				{
					handle: "TURNOVER",
					label: t("dial_entry.fields.turnover.label", "Omsättning"),
					render: (row: DialEntryType) => {
						const serie = row.axicon_company?.graphs?.series?.find((s) => s.handle === "turnover");
						const value = serie?.data?.at(-1) as any;
						return numberFormatter({ value });
					},
				},
				{
					handle: "PROFIT",
					label: t("dial_entry.fields.profit.label", "Vinst/Förlust"),
					render: (row: DialEntryType) => {
						const serie = row.axicon_company?.graphs?.series?.find((s) => s.handle === "profit");
						const value = serie?.data?.at(-1) as any;
						return numberFormatter({ value });
					},
				},
				{
					handle: "PROFIT_MARGIN",
					label: t("dial_entry.fields.profit_margin.label", "Vinstmarginal (%)"),
					render: (row: DialEntryType) => {
						const serie = row.axicon_company?.graphs?.series?.find((s) => s.handle === "profit_margin");
						const value = serie?.data?.at(-1);
						return `${value || ""}%`;
					},
				},
				{
					handle: "CITY",
					label: t("dial_entry.fields.city.label", "Stad"),
					render: (row: DialEntryType) => row.axicon_company?.city,
				},
			]}
			defaultSavedSearches={[
				{
					id: "unhandled",
					title: t("prospects.saved_searches.unhandled", "Ej bearbetade"),
					filters: [
						{
							key: "unhandled",
							value: 1,
						},
					],
				},
				...(dialStatuses?.map((status: DialStatusType) => ({
					id: status.id,
					title: status.title,
					filters: [
						{
							key: "dial_statuses",
							value: [status.id],
						},
					],
				})) || []),
			]}
		/>
	);
};
export default BasicList;
