import { FormLayout } from "@shopify/polaris";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import API from "src/js/API";
import Modal from "src/js/components/modal";
import SelectSearchField from "src/js/components/SelectSearchField";
import TextField from "src/js/components/TextField";
import Toast from "src/js/components/Toast";

type ImportToDiallistModalProps = {
	onClose: () => void;
	onSuccess: () => void;
	isOpen: boolean;
	contactIds: string[];
};

const ImportToDiallistModal = ({ onClose, isOpen, contactIds, onSuccess }: ImportToDiallistModalProps) => {
	const [comment, setComment] = useState("");
	const [dialList, setDialList] = useState<any>();
	const history = useHistory();

	const { t } = useTranslation(["dial_lists", "common"]);

	const handleOnClose = () => {
		onClose();
	};

	const handleConfirm = async () => {
		if (contactIds?.length) {
			try {
				const res = await API.post("/api/contacts/bulk/import/dial_entries.json", {
					dial_list: dialList,
					comment,
					contact_ids: contactIds,
				});

				const dialEntries = res.data.dial_entries;
				Toast.success(
					<>
						<p>
							{t("dial_lists.responses.contacts.imported", "{{contactsCount}} kontakter importarade till {{dialEntriesCount}}st prospekt", {
								dialEntriesCount: dialEntries.length,
								contactsCount: contactIds.length,
							})}
						</p>
						<br />
						<b>{t("dial_lists.responses.contacts.imported_dial_list_navigate", "Klicka för att öppna ringlistan")}</b>
					</>,
					{
						onClick: () => {
							if (res.data.dial_list?.id) history.push(`/admin/dial_lists/${res.data.dial_list?.id}`);
						},
					}
				);

				onSuccess();
			} catch (error) {
				Toast.error(error);
			}
		}
	};

	const disabled = !dialList;

	return (
		<Modal
			open={isOpen}
			title={t("dial_lists.actions.import.modal.title", "Importera prospekt")}
			onClose={handleOnClose}
			primaryAction={{
				content: t("dial_lists.actions.import.modal.confirm", "Importera") as string,
				onAction: handleConfirm,
				disabled,
			}}
			secondaryActions={[
				{
					content: "Avbryt",
					onAction: handleOnClose,
				},
			]}
			sectioned
		>
			<FormLayout>
				<TextField
					label="Kommentar"
					value={comment}
					onChange={setComment}
					placeholder={t("dial_lists.actions.import.modal.commentPlaceholder", "Kommentar") as string}
					helpText={t("dial_lists.actions.import.modal.commentHelpText", "En kommentar som sparas på diallist entrien") as string}
					multiline={2}
				/>

				<SelectSearchField
					label={t("dial_lists.actions.import.modal.selectDialList", "Välj ringlista") as string}
					placeholder={t("dial_lists.actions.import.modal.selectDialListPlaceholder", "Välj ringlista") as string}
					onSelect={(dialList) => {
						setDialList(dialList);
					}}
					value={dialList?.id}
					resourceName={{ singular: "ringlista", plural: "ringlistor" }}
					resource="dial_lists"
					resourceHandle="dial_lists"
					resourceURL="/api/dial_lists.json"
					labelHandle="title"
					onClear={() => setDialList(null)}
					renderValue={(value, data) => {
						return data?.find((item) => item.id == value)?.title || (value === dialList?.id ? dialList?.title : value);
					}}
				/>
			</FormLayout>
		</Modal>
	);
};
export default ImportToDiallistModal;
