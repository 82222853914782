import React, { useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Page from "src/js/components/page";
import { DialerProvider, useDialerContext } from "../../DialerContext";
import Select from "src/js/components/select/Select";
import { Wrapper } from "../styles";
import CustomRedirect from "src/js/components/CustomRedirect/CustomRedirect";
import DialEntryView from "./DialEntryView";
import { useTranslation } from "react-i18next";
import BasicList from "./BasicList";
import Spinner from "src/js/components/Spinner";
import Empty from "./Empty";
import { DuplicateMinor, ImportMinor } from "@shopify/polaris-icons";
import ImportModal from "src/js/components/Import/ImportModal";

const Routes = () => {
	const { id, view, dialList, isLoadingDialList, toggleEditSheet, handleDuplicateDialList, groupedBoardRows, handleOpenBoardRow } =
		useDialerContext();
	const { t } = useTranslation(["dialers", "common"]);
	const history = useHistory();
	const [importModalIsOpen, setImportModalIsOpen] = React.useState(false);

	const views: any[] = useMemo(() => {
		return [
			{
				id: `view/empty`,
				exact: true,
				label: t("dial_entry.view.view", "Kortvy"),
				render: <Empty />,
				selectedble: false,
			},

			{
				id: `view`,
				label: t("dial_entry.view.view", "Kortvy"),
				render: <DialEntryView />,
			},
			{
				id: "column_list",
				label: t("dial_entry.view.column_list", "Lista"),
				render: <BasicList />,
			},
			// {
			// 	id: "card_list",
			// 	label: t("dial_entry.view.card_list", "Detaljerad lista"),
			// 	render: <CardList />,
			// },
		];
	}, [t]);

	const viewOptions = views.reduce((acc, view) => {
		if (acc.selectedble || !("selectedble" in view)) {
			acc.push({
				label: view.label,
				value: view.id,
			});
		}

		return acc;
	}, []);

	const title = dialList?.title || "-";

	return (
		<Page
			flex
			title={title}
			titleMetadata={isLoadingDialList && <Spinner size="small" />}
			primaryAction={
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
					}}
				>
					<p>{t("prospects.view.select", "Välj vy")}</p>

					<Select
						options={viewOptions}
						value={view}
						onChange={(value) => {
							history.push(`/admin/dial_lists/${id}/${value}`);
						}}
						primary
						minWidth={100}
					/>
				</div>
			}
			secondaryActions={[
				{
					content: t("prospects.list.actions.edit.label", "Redigera lista") as string,
					onAction: () => {
						toggleEditSheet(true);
					},
				},
				{
					content: t("dial_lists.list.import.excel", "Importera ifrån excel") as string,
					icon: ImportMinor,
					onAction: () => setImportModalIsOpen(true),
				},
				{
					content: t("dial_lists.list.actions.duplicate", "Duplicera lista") as string,
					icon: DuplicateMinor,
					onAction: handleDuplicateDialList,
				},
				...(Object.values(groupedBoardRows || {})?.map((group: any) => {
					return {
						title: `${group.title} (${group.rows.length})`,
						renderGroupEvenWithJustOneAction: true,
						actions:
							group.rows.map((boardRow) => {
								return {
									content: boardRow.title,
									onAction: () => {
										handleOpenBoardRow(boardRow);
									},
								};
							}) || [],
					};
				}) || []),
			]}
		>
			<Wrapper>
				<Switch>
					{views.map((view) => {
						return (
							<Route key={view.id} exact={view.exact} path={`/admin/dial_lists/:id/${view.id}`}>
								{view.render}
							</Route>
						);
					})}

					<Route path="*">
						<CustomRedirect to={`/admin/dial_lists/${id}/column_list`} replace />
					</Route>
				</Switch>

				{/* {typeof view?.render === "function" ? view?.render() : view?.render} */}
			</Wrapper>

			<ImportModal
				open={importModalIsOpen}
				onClose={() => setImportModalIsOpen(false)}
				type={"dial_entry"}
				resourceName={{
					singular: "dial_entry",
					plural: "dial_entries",
				}}
				metadata={{
					dial_list_id: id,
				}}
			/>
		</Page>
	);
};

export default (props) => {
	return (
		<DialerProvider {...props}>
			<Routes {...props} />
		</DialerProvider>
	);
};
