import React from "react";
import Colors, { colorToRgba } from "src/js/Colors";
import CustomCard from "src/js/components/CustomCard";
import styled from "styled-components";
import { LoadingBox } from "src/js/components/Skeletons/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AIDescription = ({ summary, isLoading, refresh }) => {
	const { t } = useTranslation(["prospects", "common"]);
	return (
		<Wrapper>
			<CustomCard
				sectioned
				title="Beskrivningar"
				subdued
				collapsible
				actions={[refresh && { content: t("prospects.fields.prospect_summary.refresh", "Uppdatera") as string, onAction: refresh }].filter(Boolean)}
			>
				<b>{t("prospects.fields.prospect_summary.customer_fit.label", "Prospektmatchning")}</b>

				{!isLoading && (
					<Description>
						{summary?.customer_fit || (
							<Link to="/admin/account">{t("prospect.acocunt.description.empty", "Saknar konto beskrivning, redigera här")}</Link>
						)}
					</Description>
				)}

				{isLoading && !summary?.customer_fit && <LoadingBox height={25} width={"100%"} loading style={{ background: "var(--tertiary)" }} />}

				<br />

				<b>{t("prospects.fields.prospect_summary.description.label", "Beskrivning")}</b>
				{summary?.description && <Description>{summary?.description}</Description>}
				{isLoading && !summary?.description && <LoadingBox height={25} width={"100%"} loading style={{ background: "var(--tertiary)" }} />}
			</CustomCard>
		</Wrapper>
	);
};
export default AIDescription;

const Wrapper = styled.div`
	.Polaris-LegacyCard {
		background-color: ${colorToRgba(Colors.green, 0.25)};
		border: 1px solid ${Colors.green};
	}
`;

const Description = styled.p`
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
	overflow-wrap: break-word;
`;
