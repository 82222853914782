import { FormLayout } from "@shopify/polaris";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import API from "src/js/API";
import CustomCard from "src/js/components/CustomCard";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import SelectSearchField from "src/js/components/SelectSearchField";
import TextField from "src/js/components/TextField";
import Toast from "src/js/components/Toast";
import Page from "src/js/components/page";
import { store } from "src/js/store";

const DialForm = ({ inline = false, dialList, ref }) => {
	const [form, setForm] = useState<any>(dialList || {});
	const { t } = useTranslation(["dial_lists", "common"]);
	const history = useHistory();

	useEffect(() => {
		setForm(dialList || {});
	}, [dialList]);

	useImperativeHandle(ref, () => ({
		form,
		getForm: () => form,
	}));

	const getChangeHandler = (key: string) => (value) => {
		setForm({ ...form, [key]: value });
	};

	const handleSave = async () => {
		try {
			const res = await API.post("/api/dial_lists.json", form);
			Toast.success(t("dial_lists.responses.created", "Ringlista skapad") as string);

			history.push(`/admin/dial_lists/${res.data.dial_list.id}`);
		} catch (error) {
			Toast.error(error);
		}
	};

	const content = (
		<FormLayout>
			<TextField label={t("dial_list.form.fields.title.label", "Titel") as string} value={form?.title} onChange={getChangeHandler("title")} />

			{form?.id && (
				<SelectSearchField
					label="Användare"
					resourceName={{ singular: "användare", plural: "användare" }}
					resource="users"
					resourceHandle="users"
					onSelect={getChangeHandler("assigned_to_user")}
					value={form?.assigned_to_user?.id}
					labelHandle="name"
					renderMedia={(item) => <ProfileAvatar size={25} user={item} />}
					renderValue={(value, data) => {
						return data?.find((item) => item.id === value)?.name || store.getState().users?.find((item) => item.id === value)?.name || value;
					}}
					helpText={t("prospect.fields.user.help_text", "Välj användare som kommer att ha tillgång till denna lista.")}
				/>
			)}
		</FormLayout>
	);

	if (inline) return content;

	return (
		<Page
			title={(form?.id ? t("dial_list.form.title", "Redigera ringlista") : t("dial_list.form.title", "Ringlista")) as string}
			primaryAction={{
				content: form?.id ? (t("common.acions.save", "Spara") as string) : (t("common.acions.create", "Skapa") as string),
				onAction: handleSave,
			}}
		>
			<CustomCard sectioned>{content}</CustomCard>
		</Page>
	);
};
export default DialForm;
