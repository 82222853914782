import React from "react";
import styled from "styled-components";
import ProfileAvatar from "../ProfileAvatar";
import moment from "moment";

const Comment = ({ data }) => {
	return (
		<Wrapper>
			<GridArea area="user">
				<ProfileAvatar user={data?.created_by_user} size={24} />
			</GridArea>
			<GridArea area="content">
				<Content>{data?.content}</Content>
			</GridArea>
			<GridArea area="date" style={{ color: "#6b6b6b", fontSize: "0.75rem", textAlign: "right" }}>
				{moment(data?.created_at).format("YYYY-MM-DD HH:mm")}
			</GridArea>
		</Wrapper>
	);
};
export default Comment;

const Wrapper = styled.div`
	display: grid;
	grid-template-areas: "user content content" "date date date";
	grid-template-columns: max-content auto max-content;
	column-gap: 0.5rem;
	padding: 1rem;
`;

const Content = styled.div`
	font-size: 0.875rem;

	max-width: 100%; /* Ensure it doesn't overflow the container */
	white-space: pre-wrap; /* Keep line breaks but wrap long content */
	word-wrap: break-word; /* Break long words if necessary */
`;
const GridArea = styled.div<{
	area: string;
}>`
	grid-area: ${(props) => props.area};
`;
