import React from "react";
import ProspectContactCard from "src/js/views/Prospect/View/Views/ProspectCompany/Views/SubViews/ProspectContactCard";
import styled from "styled-components";
import { useDialerContext } from "../../../DialerContext";
import Details from "src/js/components/Details";
import Comments from "src/js/components/Comments";
import CustomCard from "src/js/components/CustomCard";

const DialOverview = () => {
	const { company, dialEntry, dialEntryId } = useDialerContext();

	return (
		<Wrapper>
			<FlexColumn>
				<ProspectContactCard company={company}>
					<hr />
					<Details
						gap={{
							horizontal: "3rem",
							vertical: "0.5rem",
						}}
						items={dialEntry?.custom_fields || []}
					/>
				</ProspectContactCard>
			</FlexColumn>

			<FlexColumn>
				<CommentsWrapper>
					<CustomCard title="Kommentarer">
						<Comments url={`/api/dial_entry/${dialEntryId}/activity_logs.json`} resourceHandle={"dial_entry_activity_logs"} />
					</CustomCard>
				</CommentsWrapper>
			</FlexColumn>
		</Wrapper>
	);
};
export default DialOverview;

const Wrapper = styled.div`
	display: flex;
	gap: 10px;
	flex: 1;
`;

const FlexColumn = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const CommentsWrapper = styled.div`
	&,
	.Polaris-LegacyCard {
		height: 100%;

		display: flex;
		flex-direction: column;
	}

	.Polaris-Collapsible:not(.Polaris-Collapsible--isFullyClosed) {
		/* height: 100%; */
		flex: 1;
	}
`;
