import React, { useMemo, useState } from "react";
import { useDialerContext } from "../../DialerContext";
import styled from "styled-components";
import Overview from "src/js/views/Prospect/View/Views/ProspectCompany/Views/Overview";
import Economy from "src/js/views/Prospect/View/Views/ProspectCompany/Views/Economy";
import Tabs from "src/js/components/Tabs";
import DialOverview from "./subviews/DialOverview";
import Header from "./subviews/Header";
import ContactCard from "src/js/views/Contacts/ContactCard/ContactCard";

const DialEntryView = () => {
	const { t, company, contact, isLoading } = useDialerContext();
	const tabs = useMemo(() => {
		return [
			{
				id: "overview",
				value: "overview",
				content: t("dial.views.view.tabs.overview", "Översikt"),
				render: () => <DialOverview />,
			},
			{
				id: "compamy_overview",
				value: "compamy_overview",
				content: t("dial.views.view.tabs.company_overview", "Bolagsinformation"),
				render: () => <Overview company={company} isLoading={isLoading} isLoadingSummary summary={{}} refreshSummary={() => {}} />,
				disabled: !company,
			},
			{
				id: "economy",
				value: "economy",
				content: t("dial.views.view.tabs.economy", "Ekonomi"),
				render: () => <Economy company={company} isLoading={isLoading} />,
				disabled: !company,
			},
			{
				id: "contact",
				value: "contact",
				content: t("prospects.view.tabs.contact", "CRM kontakt"),
				render: () => <ContactCard contact={contact} />,
				enabled: !!contact,
			},
		].filter((tab) => !("enabled" in tab) || tab.enabled);
	}, [t, company, isLoading, contact]);

	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const tab = tabs[activeTabIndex];
	return (
		<Wrapper>
			<StyledHeader>
				<Header />
				<Tabs style={{ marginTop: "0.65rem", marginBottom: "0.65rem" }} tabs={tabs} selected={activeTabIndex} onSelect={setActiveTabIndex} />
			</StyledHeader>
			{tab.render()}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;
const StyledHeader = styled.div``;

export default DialEntryView;
