import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalBoxes from "src/js/components/ModalBoxes";
import Modal from "src/js/components/modal";
import { useTranslation } from "react-i18next";
import BoardHelper from "../../Workspaces/BoardHelper";
import RowSheet from "../../Workspaces/components/RowSheet";

type BoardRowModalProps = {
	isOpen: boolean;
	onClose: () => void;
	dialEntry?: DialEntryType;
	onComplete?: (row: any) => void;
	onCompleteAwaited?: (row: any) => void;
	dialList: any;
	boardRow?: any;
	board?: any;
};

const BoardRowModal = ({ isOpen, onClose, dialEntry, dialList, onComplete, onCompleteAwaited, boardRow, board: propsBoard }: BoardRowModalProps) => {
	const [board, setBoard] = useState<BoardType | null>(propsBoard || null);
	const storeUser = useSelector((state: any) => state.user);
	const { t } = useTranslation(["dialers", "common"]);

	useEffect(() => {
		setBoard(propsBoard || null);
	}, [isOpen, propsBoard]);

	const handleClose = () => {
		onClose();
		setBoard(null);
	};

	const defaultForm = board?.columns.reduce(
		(acc, column) => {
			if (column.type === "contact" && dialEntry?.contact) {
				acc.column_values[column.id] = {
					column,
					column_id: column.id,
					value: [dialEntry.contact?.id],
				};
			} else if (column.type === "link") {
				acc.column_values[column.id] = {
					column,
					column_id: column.id,
					value: {
						url: `/admin/dial_lists/${dialList?.id}/view/${dialEntry?.id}`,
						caption: `Ringlist entry "${dialEntry?.title}"`,
					},
				};
			} else if (column.type === "text") {
				acc.column_values[column.id] = {
					column,
					column_id: column.id,
					value: `Påminelse skapad från ringlistan "${dialList?.title}"`,
				};
			}
			return acc;
		},
		{
			title: `Återkoppla till kunden "${dialEntry?.title}"`,
			column_values: {},
		}
	);

	return (
		<>
			<Modal open={isOpen && !board} onClose={handleClose} title={t("dialers.views.board_row_modal.title", "Välj typ av påminelse")}>
				<ModalBoxes
					items={
						[
							{
								title: "Att göra",
								// center: true,
								onAction: () => {
									setBoard(storeUser.todo_board);
								},
							},
							{
								title: "Kalenderinlägg",
								// center: true,
								onAction: () => {
									setBoard(storeUser.calendar_board);
								},
							},
						] as any
					}
					style={{
						justifyContent: "space-around",
					}}
				/>
			</Modal>

			<RowSheet
				open={!!board}
				row={boardRow || defaultForm}
				// groups={this.props.groups}
				board={board}
				onUpdateValue={(row, column, data) => {
					BoardHelper.onUpdateValue(row, column, data);
				}}
				defaultValues={[]}
				getContact={() => {}}
				onClose={handleClose}
				onUpdateRow={(row) => {
					BoardHelper.updateRows([row], "update");
				}}
				onComplete={onComplete}
				onCompleteAwaited={onCompleteAwaited}
			/>
		</>
	);
};
export default BoardRowModal;
