import React, { useCallback } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import ItemCard from "src/js/components/ItemCard";
import API from "src/js/API";
import IndexTable from "src/js/components/IndexTable";
import Toast from "src/js/components/Toast";

type CustomFieldsListProps = {
	history: any;
} & WithTranslation;

const CustomFieldsList = ({ history, t }: CustomFieldsListProps) => {
	const ref = React.useRef<any>(undefined);

	const handleBulkDisabled = useCallback(async () => {
		try {
			const ids = ref.current.getSelectedItems();
			const result = await API.put("/api/custom_fields.json", { ids, disabled: 1 });
			Toast.success(t("customFields.responses.bulk_disabled", "{{count}} egna fält inaktiverade", { count: result.data?.custom_fields?.length }));
			ref.current?.reload();
			return true;
		} catch (error) {
			Toast.error(error);
		}
	}, [t]);

	const handleBulkEnabled = useCallback(async () => {
		try {
			const ids = ref.current.getSelectedItems();
			const result = await API.put("/api/custom_fields.json", { ids, disabled: 0 });
			Toast.success(t("customFields.responses.bulk_enabled", "{{count}} egna fält aktiverade", { count: result.data?.custom_fields?.length }));
			ref.current?.reload();
			return true;
		} catch (error) {
			Toast.error(error);
		}
	}, [t]);

	const renderItem = useCallback(
		(item: CustomFieldType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/customfields/${item.id}`, {
							data: item,
						});
					}}
					title={item.title}
					leftColumn={[
						{
							label: t("articles.status", "Status"),
							value: item.disabled ? (
								<Badge color={Colors.red}>{t("common.inactive", "Inaktiv")}</Badge>
							) : (
								<Badge color={Colors.green}>{t("common.active", "Aktiv")}</Badge>
							),
						},

						{
							label: t("customfields.description", "Beskrivning"),
							value: item.description,
						},
						{
							label: t("customfields.articles_display", "Artiklar"),
							value: item.articles_display ?? "Inga artiklar",
						},
						{
							label: t("customfields.categories_display_string", "Kategorier"),
							value: item.categories_display ?? "Inga kategorier",
						},
					]}
					rightColumn={[
						{
							label: t("customfields.type", "Typ"),
							value: item.type,
						},
						{
							label: t("customfields.default_value", "Standardvärde"),
							value: item.default_value,
						},
					]}
				/>
			);
		},
		[history, t]
	);

	return (
		<IndexTable
			title={t("customfields.title", "Egna fält") as string}
			history={history}
			primaryAction={{
				content: t("customfields.new", "Nytt fält") as string,
				url: "/admin/customfields/create",
			}}
			ref={ref}
			promotedBulkActions={[
				{
					content: t("categories.bulkDisable", "Inaktivera") as string,
					onAction: handleBulkDisabled,
				},
				{
					content: t("categories.bulkEnable", "aktivera") as string,
					onAction: handleBulkEnabled,
				},
			]}
			defaultFilters={[
				{
					key: "disabled",
					value: "0",
				},
			]}
			resourceUrl="/api/custom_fields.json"
			resourceName={{
				singular: t("customfields.singular", "fält"),
				plural: t("customfields.plural", "fält"),
			}}
			resourceHandle="custom_fields"
			columns={[
				{
					handle: "",
					label: "",
					render: renderItem,
				},
			]}
		/>
	);
};
export default withTranslation(["customfields", "common"])(CustomFieldsList);
