import React, { useContext } from "react";
import IndexTable from "src/js/components/IndexTable";
import NotificationItem from "./NotificationItem";
import NotificationContext from "src/js/ContextProviders/NotificationContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Sheet from "src/js/components/sheet";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import { VocabularyMajor } from "@shopify/polaris-icons";

const NotificationSheet = () => {
	const {
		markNotificationAsRead,
		markNotificationAsReadBulk,
		markNotificationAsReadAll,
		markNotificationAsUnReadBulk,
		notificationSheetIsOpen,
		toggleNotificationSheet,
		unreadCount,
	} = useContext(NotificationContext);
	const history = useHistory();
	const { t } = useTranslation(["notifications", "common"]);
	const ref = React.createRef<any>();

	return (
		<Sheet
			title={
				<>
					{t("notifications.sheet.title", "Notifikationer") as string}
					{!!unreadCount && <Badge color={Colors.red}>{unreadCount}</Badge>}
				</>
			}
			open={notificationSheetIsOpen}
			onClose={toggleNotificationSheet(false)}
		>
			<Wrapper>
				<IndexTable
					ref={ref}
					inline
					defaultSelectedTab={"unread"}
					defaultSavedSearches={[
						{
							id: "unread",
							title: t("notification.unread", "Olästa"),
							filters: [{ key: "unread", value: "1" }],
						},
					]}
					promotedBulkActions={[
						{
							content: t("notification.mark_all_as_read", "Markera som lästa"),
							onAction: async () => {
								const selected = ref.current?.getSelectedItems();
								const res = await markNotificationAsReadBulk(selected);
								ref.current?.refresh(false);
								return res;
							},
						},
						{
							content: t("notification.mark_all_as_unread", "Markera som olästa"),
							onAction: async () => {
								const selected = ref.current?.getSelectedItems();
								const res = await markNotificationAsUnReadBulk(selected);
								ref.current?.refresh(false);
								return res;
							},
						},
					]}
					limit={10}
					secondaryActions={[
						{
							content: t("notification.mark_all_as_read", "Markera alla som lästa") as string,
							icon: VocabularyMajor,
							onAction: async () => {
								await markNotificationAsReadAll();

								ref.current?.refresh();
							},
						},
					]}
					resourceUrl="/api/notifications.json"
					resourceName={{
						singular: t("notification.singular", "Notifikation"),
						plural: t("notification.plural", "Notifikationer"),
					}}
					resourceHandle="notifications"
					urlParamsEnabled={false}
					columns={[
						{
							label: "",
							handle: "",
							render: (notification) => {
								return (
									<NotificationItem
										closeSheet={toggleNotificationSheet(false)}
										notification={notification}
										history={history}
										markNotificationAsRead={markNotificationAsRead}
									/>
								);
							},
						},
					]}
				/>
			</Wrapper>
		</Sheet>
	);
};
export default NotificationSheet;
const Wrapper = styled.div`
	padding-inline: 1rem;

	.Polaris-IndexTable__BulkActionsWrapper.Polaris-IndexTable__BulkActionsWrapperSticky {
		width: 100% !important;
		inset-inline-start: 0 !important;
	}
`;
