import { Icon, LegacyCard } from "@shopify/polaris";
import { ThumbsDownMinor, ThumbsUpMinor } from "@shopify/polaris-icons";
import React from "react";
import Colors from "src/js/Colors";
import Button from "src/js/components/Button";
// import CircleDiagram from "src/js/components/CircleDiagram";
import { CompanyIcon } from "src/js/icons";
import styled from "styled-components";
import { useProspectContext } from "../../../../Context/ProspectContext";
import Details from "src/js/components/Details";
import Badge from "src/js/components/Badge";
import Spinner from "src/js/components/Spinner";

const Header = () => {
	const { company, prospect, handleLikeAction, handleDislikeAction, prospectId, isLoadingProspect, handleSkipAction } = useProspectContext();

	return (
		<LegacyCard sectioned>
			<Wrapper>
				{/* <GridArea area="score">
					<Tooltip content={t("prospects.fields.score.tooltip", "Sellfinity score baserat på ...")}>
						<CircleDiagram
							loading={isLoadingProspect}
							duration={2000}
							percentage={!company ? 0 : 100}
							color={Colors.accent}
							backgroundColor={Colors.tertiary}
							suffix={""}
							fontSize={10}
						/>
					</Tooltip>
				</GridArea> */}
				<GridArea area="content">
					<Title>
						<CompanyIcon color={Colors.white} />
						{company?.name}
						{isLoadingProspect && <Spinner size="small" />}
						{prospect?.qualified && <Badge status="success">Kvalificerad</Badge>}
						{prospect?.denied && <Badge status="critical">Ignorerad</Badge>}
					</Title>
				</GridArea>

				<GridArea area="skip" style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button disabled={isLoadingProspect} outline onClick={handleSkipAction}>
						Skip
					</Button>
				</GridArea>

				<GridArea area="buttons">
					<ButtonsWrapper>
						<Button
							disabled={isLoadingProspect}
							outline
							primary
							icon={<Icon source={ThumbsUpMinor} />}
							onClick={handleLikeAction}
							connectedDisclosure={{
								actions: [
									{
										content: "Importera",
										onAction: () => handleLikeAction(prospectId, true),
									},
								],
							}}
						>
							Kvalificera
						</Button>

						<Button disabled={isLoadingProspect} outline destructive icon={<Icon source={ThumbsDownMinor} />} onClick={handleDislikeAction}>
							Ignorera
						</Button>
					</ButtonsWrapper>
				</GridArea>
				<GridArea area="extra" style={{ alignItems: "end", display: "flex" }}>
					<Details
						items={[
							{
								label: "Registrerad",
								value: company?.established,
							},
						]}
					/>
				</GridArea>
			</Wrapper>
		</LegacyCard>
	);
};
export default Header;

const Wrapper = styled.div`
	display: grid;
	/* grid-template-columns: max-content auto max-content; */
	grid-template-columns: auto max-content;
	/* gap: 1rem; */
	gap: 1rem;
	/* grid-template-areas: "score content" "empty buttons"; */
	/* grid-template-areas: "score content extra" "score buttons extra"; */
	grid-template-areas: "content skip" "buttons extra";
`;

const GridArea = styled.div<{ area: string }>`
	grid-area: ${(props) => props.area};
`;
const Title = styled.h2`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	font-size: 24px;
	line-height: 24px;
	font-weight: 600;

	svg {
		height: 24px;
		width: 24px;
	}
`;

const ButtonsWrapper = styled.div<{ subdued?: boolean }>`
	display: flex;
	gap: 1rem;

	& > * {
		transition: opacity 0.3s;
		opacity: ${(props) => (props.subdued ? 0.5 : 1)};

		&:hover {
			opacity: 1 !important;
		}
	}
`;
