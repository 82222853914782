import React from "react";
import Sheet from "src/js/components/sheet";
import styled from "styled-components";
import DialForm from ".";
import { useDialerContext } from "../DialerContext";

const EditSheet = () => {
	const { editSheetIsOpen, dialList, toggleEditSheet, handleEditDialList, t } = useDialerContext();
	const formRef = React.useRef(null);

	const handleClose = () => {
		toggleEditSheet(false);
	};

	const handleSave = async () => {
		const form = (formRef.current as any)?.form as any;
		await handleEditDialList(form);
		handleClose();
	};

	return (
		<Sheet
			open={editSheetIsOpen}
			title={`Redigera "${dialList?.title}"`}
			onClose={handleClose}
			primaryAction={{
				content: t("common.actions.save", "Spara"),
				onAction: handleSave,
			}}
		>
			<Wrapper>
				<DialForm ref={formRef} dialList={dialList} inline />
			</Wrapper>
		</Sheet>
	);
};
export default EditSheet;

const Wrapper = styled.div`
	padding: 1rem;
`;
