import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import IndexTable from "src/js/components/IndexTable";
import ProfileAvatar from "src/js/components/ProfileAvatar";

const ProspectList = () => {
	const { t } = useTranslation(["prospects", "common"]);
	const history = useHistory();
	return (
		<IndexTable
			title={t("prospects.list.title", "Prospektlistor") as string}
			limit={25}
			primaryAction={{
				content: t("prospects.list.create", "Skapa ny prospektlista") as string,
				url: "/admin/prospect_lists/create",
			}}
			resourceUrl="/api/prospect_lists.json"
			resourceHandle="prospect_lists"
			resourceName={{
				singular: "prospekt",
				plural: "prospekter",
			}}
			onClick={(row) => {
				history.push(`/admin/prospect_lists/${row.id}`);
			}}
			columns={[
				{
					label: t("prospects.list.fields.title.label", "Titel"),
					handle: "TITLE",
					render: (row) => row?.title,
				},
				{
					label: t("prospects.list.fields.counts.label", "Ej bearbetade/kvalificerade/ignorerade"),
					handle: "COUNTS",
					render: (row) => (
						<>
							<Badge color={Colors.blue}>{row.unhandled_prospect_count}</Badge>
							{"/"}
							<Badge color={Colors.green}>{row.qualified_prospect_count}</Badge>
							{"/"}
							<Badge color={Colors.red}>{row.denied_prospect_count}</Badge>
						</>
					),
				},
				{
					label: t("prospects.list.fields.assigned_to_user.label", "Tilldelad till"),
					handle: "ASSIGNED_TO_USER",
					render: (row) => (
						<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
							<ProfileAvatar size={26} user={row.assigned_to_user} />
							{row.assigned_to_user?.name}
						</div>
					),
				},
				{
					label: t("prospects.list.fields.created_by_user.label", "Skapad av"),
					handle: "CREATED_BY_USER",
					render: (row) => (
						<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
							<ProfileAvatar size={26} user={row.created_by_user} />
							{row.created_by_user?.name}
						</div>
					),
				},
				{
					label: t("prospects.list.fields.created_at.label", "Skapad"),
					handle: "CREATED_AT",
					render: (row) => row.created_at,
				},
			]}
		/>
	);
};
export default ProspectList;
