import { Wrapper as IndexTableWrapper } from "src/js/components/IndexTable/IndexTable";
import styled from "styled-components";

export const Wrapper = styled.div`
	min-height: 100%;
	height: 100%;

	&&& {
		${IndexTableWrapper} {
			margin-top: 0;

			.Polaris-Page__Content {
				padding-top: 0;
			}
		}
	}
`;
