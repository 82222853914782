import { ActionList, LegacyCard } from "@shopify/polaris";
import React from "react";
import Colors from "src/js/Colors";
import Button from "src/js/components/Button";
// import CircleDiagram from "src/js/components/CircleDiagram";
import { CompanyIcon } from "src/js/icons";
import styled from "styled-components";
import Details from "src/js/components/Details";
import Spinner from "src/js/components/Spinner";
import { useDialerContext } from "../../../DialerContext";
import Popover from "src/js/components/Popover";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import ImportFromDialEntryModal from "../../../Modals/ImportFromDialEntryModal";

const Header = () => {
	const { company, dialEntry, isLoading, dialStatuses, handleStatusAction, handleSkipAction, t } = useDialerContext();
	const [importModalIsOpen, setImportModalIsOpen] = React.useState(false);

	const phoneNumbers: {
		label: string;
		value: string;
	}[] = [...(dialEntry?.telephone_numbers || [])];

	const getCallAction = (phoneNumber) => () => {
		const url = `tel:${phoneNumber}`;
		window.open(url, "_blank");
	};

	return (
		<LegacyCard sectioned>
			<Wrapper>
				{/* <GridArea area="score">
					<Tooltip content={t("prospects.fields.score.tooltip", "Sellfinity score baserat på ...")}>
						<CircleDiagram
							loading={isLoadingProspect}
							duration={2000}
							percentage={!company ? 0 : 100}
							color={Colors.accent}
							backgroundColor={Colors.tertiary}
							suffix={""}
							fontSize={10}
						/>
					</Tooltip>
				</GridArea> */}
				<GridArea area="content">
					<Title>
						<CompanyIcon color={Colors.white} />
						{dialEntry?.title}
						{isLoading && <Spinner size="small" />}
						{/* {prospect?.qualified && <Badge status="success">Kvalificerad</Badge>}
						{prospect?.denied && <Badge status="critical">Ignorerad</Badge>} */}
					</Title>
				</GridArea>

				<GridArea area="skip" style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button disabled={isLoading} outline onClick={handleSkipAction}>
						Skip
					</Button>
				</GridArea>
				<GridArea area="buttons">
					<ButtonsWrapper>
						<Popover
							activator={
								<Button disabled={isLoading || !phoneNumbers?.length} primary icon={ChevronDownMinor}>
									{t("dial_lists.dial_entry.actions.call", "Ring")}
								</Button>
							}
						>
							<ActionList items={phoneNumbers.map((phoneNumber) => ({ content: phoneNumber?.label, onAction: getCallAction(phoneNumber?.value) }))} />
						</Popover>

						<div style={{ width: 1, height: 36, backgroundColor: "grey" }} />

						{dialStatuses?.map((status) => {
							const { title, colour, id } = status;
							return (
								<ButtonWrapper key={id} color={colour}>
									<Button
										outline
										onClick={async () => await handleStatusAction(status)}
										connectedDisclosure={
											status?.id === 1
												? {
														actions: [
															{
																content: "Importera",
																onAction: () => setImportModalIsOpen(true),
															},
														],
												  }
												: undefined
										}
									>
										{title}
									</Button>
								</ButtonWrapper>
							);
						})}
					</ButtonsWrapper>
				</GridArea>
				{company && (
					<GridArea area="extra" style={{ alignItems: "end", display: "flex" }}>
						<Details
							items={[
								{
									label: "Registrerad",
									value: company?.established,
								},
							]}
						/>
					</GridArea>
				)}
			</Wrapper>

			<ImportFromDialEntryModal isOpen={importModalIsOpen} onClose={() => setImportModalIsOpen(false)} dialEntryId={dialEntry?.id} />
		</LegacyCard>
	);
};
export default Header;

const Wrapper = styled.div`
	display: grid;
	/* grid-template-columns: max-content auto max-content; */
	grid-template-columns: auto max-content;
	/* gap: 1rem; */
	gap: 1rem;
	/* grid-template-areas: "score content" "empty buttons"; */
	/* grid-template-areas: "score content extra" "score buttons extra"; */
	grid-template-areas: "content skip" "buttons extra";
`;

const GridArea = styled.div<{ area: string }>`
	grid-area: ${(props) => props.area};
`;
const Title = styled.h2`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	font-size: 24px;
	line-height: 24px;
	font-weight: 600;

	svg {
		height: 24px;
		width: 24px;
	}
`;

const ButtonsWrapper = styled.div<{ subdued?: boolean }>`
	display: flex;
	gap: 1rem;

	& > * {
		transition: opacity 0.3s;
		opacity: ${(props) => (props.subdued ? 0.5 : 1)};

		&:hover {
			opacity: 1 !important;
		}
	}
`;

const ButtonWrapper = styled.div<{ color?: string }>`
	&&&&&& {
		.Polaris-Button {
			border-color: ${(props) => props.color} !important;
		}
	}
`;
