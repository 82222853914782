import React from "react";
import moment from "moment";

import Snowing from "./Snowing";
// Example placeholders:
// import Fireworks from './Fireworks';
// import Pumpkins from './Pumpkins';

const HolidayAnimations = () => {
	const date = moment();
	const currentMonth = date.month();
	const currentDay = date.date();

	const holidayRanges = [
		{
			// December 20–31
			startMonth: 11,
			startDay: 20,
			endMonth: 11,
			endDay: 31,
			component: <Snowing />,
		},
		// Example: New Year's Day (Jan 1)
		// {
		//   startMonth: 0,  // January
		//   startDay: 1,
		//   endMonth: 0,    // January
		//   endDay: 1,
		//   component: <Fireworks />,
		// },
		// Example: Halloween (Oct 31)
		// {
		//   startMonth: 9,  // October
		//   startDay: 31,
		//   endMonth: 9,    // October
		//   endDay: 31,
		//   component: <Pumpkins />,
		// },
	];

	const isWithinRange = ({ currentMonth, currentDay, holiday: { startMonth, startDay, endMonth, endDay } }) => {
		const currentYear = moment().year(); // Use the current year

		// Adjust years for cross-year ranges
		const startYear = currentYear; // Start year is always the current year
		const endYear = endMonth < startMonth ? currentYear + 1 : currentYear; // Increment only for cross-year ranges

		// Construct startDate, endDate, and currentDate
		const startDate = moment({ year: startYear, month: startMonth - 1, day: startDay }); // Start month corrected
		const endDate = moment({ year: endYear, month: endMonth - 1, day: endDay }); // End month corrected
		const currentDate = moment({ year: currentYear, month: currentMonth, day: currentDay }); // Corrected current date

		// Debugging output
		// console.log("currentYear:", currentYear);
		// console.log("currentMonth:", currentMonth);
		// console.log("currentDay:", currentDay);
		// console.log("currentDate:", currentDate.isValid() ? currentDate.format("YYYY-MM-DD") : "Invalid date");
		// console.log("startDate:", startDate.format("YYYY-MM-DD"));
		// console.log("endDate:", endDate.format("YYYY-MM-DD"));

		return currentDate.isBetween(startDate, endDate, "day", "[]"); // Inclusive of start and end dates
	};

	const matchingHolidays = holidayRanges.filter((holiday) => isWithinRange({ currentMonth, currentDay, holiday }));

	if (matchingHolidays.length) {
		return <>{matchingHolidays.map((holiday, index) => React.cloneElement(holiday.component, { key: index }))}</>;
	}

	return null;
};

export default HolidayAnimations;
