import { FormLayout, Select } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import { useDialerContext } from "../DialerContext";

type ImportFromDialEntryModalProps = {
	isOpen: boolean;
	onClose: () => void;
	dialEntryId: string | number;
};

const ImportFromDialEntryModal = ({ isOpen, onClose, dialEntryId }: ImportFromDialEntryModalProps) => {
	const { t, handleImportToContact, handleImportToContactAndAsBoardrowInSalesBoard } = useDialerContext();

	const importMethodsOptions = useMemo(
		() => [
			{
				label: t("dial_entry.actions.import.modal.importContact", "Importera kontakt"),
				value: "handleImportToContact",
			},
			{
				label: t("dial_entry.actions.import.modal.importContactAndCreateDeal", "Importera kontakt och skapa affär"),
				value: "handleImportToContactAndAsBoardrowInSalesBoard",
			},
		],
		[t]
	);
	const [importMethod, setImportMethod] = useState(importMethodsOptions[0].value);
	const [comment, setComment] = useState("");

	useEffect(() => {
		if (!isOpen) {
			setComment("");
		}
	}, [isOpen]);

	const handleOnClose = () => {
		onClose();
	};

	const handleOnConfirm = async () => {
		if (!dialEntryId) return;

		switch (importMethod) {
			case "handleImportToContact":
				// if (!prospectId && prospectIds?.length) return await handleImportToContactBulk(prospectIds, { comment });
				await handleImportToContact(dialEntryId, { comment });
				break;
			case "handleImportToContactAndAsBoardrowInSalesBoard":
				// if (!prospectId && prospectIds?.length) return await handleImportToContactAndAsBoardrowInSalesBoardBulk(prospectIds, { comment });
				await handleImportToContactAndAsBoardrowInSalesBoard(dialEntryId, { comment });
				break;
			default:
				break;
		}

		handleOnClose();

		// await handleLikeActionDirectly(prospectId);
	};

	const disabled = false;

	return (
		<Modal
			open={isOpen}
			title={t("dial_entry.actions.import.modal.title", "Importera dial entry")}
			onClose={handleOnClose}
			primaryAction={{
				content: t("dial_entry.actions.import.modal.confirm", "Importera") as string,
				onAction: handleOnConfirm,
				disabled,
			}}
			secondaryActions={[
				{
					content: "Avbryt",
					onAction: handleOnClose,
				},
			]}
			sectioned
		>
			<FormLayout>
				<Select
					value={importMethod}
					onChange={setImportMethod}
					label={t("dial_entry.actions.import.modal.selectMethod", "Välj hur du vill importera") as string}
					options={importMethodsOptions}
				/>

				<TextField
					label="Kommentar"
					value={comment}
					onChange={setComment}
					placeholder={t("dial_entry.actions.import.modal.commentPlaceholder", "Kommentar") as string}
					helpText={t("dial_entry.actions.import.modal.commentHelpText", "En kommentar som sparas på kundkortet") as string}
					multiline={2}
				/>
			</FormLayout>
		</Modal>
	);
};
export default ImportFromDialEntryModal;
