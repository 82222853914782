/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import debugSocket from "src/js/Utilities/debugSocket";
import API from "../../API";
import { store } from "../../store";
import BoardViewList from "./BoardViewList";
import BoardViewSimpleList from "./BoardViewSimpleList";
import BoardViewTimeline from "./BoardViewTimeline";
import BoardViewCalendar from "./BoardViewCalendar";
import BoardViewKanban from "./BoardViewKanban";
import BoardViewForm from "./BoardViewForm";
import BoardHeader from "./BoardHeader";
import BoardHelper from "./BoardHelper";
import RowModal from "./components/RowModal";
import SyncCalendarModal from "./components/SyncCalendarModal.js";
import RowSheet from "./components/RowSheet";
import SkeletonBoard from "../../components/Skeletons/SkeletonBoard";
import VisitorBoardEmptyScreen from "./VisitorBoardEmptyScreen.js";
import TicketModal from "../../components/TicketModal.js";
import FixedCalendarView from "./components/Calendar/FixedCalendarView.js";
import BoardViewCallList from "./BoardViews/CallList/BoardViewCallList.js";
import { filterDefaultValues } from "./boardutils/BoardUtils";
import Toast from "src/js/components/Toast";

class BoardView extends Component {
	constructor(props) {
		super(props);
		this.boardString = null;
		this.state = {
			containerClass: props.containerClass || "board-container",
			boardClass: "board-container",
			saved_filters: [],
			id: props.id ? props.id : props.match.params.id,
			workspace_id: props.id || props.match.params.workspace_id,
			loading: true,
			loadingRows: true,
			form: null,
			offset: 0,
			limit: 50,
			filters: [],
			sorting: [],
			groups: [],
			rows: [],
			filteredRows: [],
			defaultValues: [],
			archived: this.getQueryVariable("archived"),
			also_archived: this.getQueryVariable("also_archived"),
			view_id: this.getQueryVariable("view_id"),
			switchDirection: 0,
			ticket: {},
			location: props.location,
		};

		this.windowFocusCallback = this.onFocusWindow.bind(this);
		this.windowResizeCallback = this.windowOnResize.bind(this);
		this.stopDragGroupCallback = this.onDragGroupEnd.bind(this);
		this.onDragGroupStartCallback = this.onDragGroupStart.bind(this);
		this.onDragGroupOverCallback = this.onDragGroupOver.bind(this);

		if (window.location.hash.length > 0) {
			const data = JSON.parse(decodeURIComponent(window.location.hash.substring(1)));
			if (data) {
				this.state.filters = data;
			}
		}
	}

	clearMyStuff(id) {
		store.dispatch({ type: "CLEAR_BOARD_ROWS", board_id: id });
		store.dispatch({ type: "CLEAR_BOARD_GROUPS", board_id: id });
	}

	windowOnResize() {
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: Math.random() });
	}

	UNSAFE_componentWillReceiveProps(props) {
		const board = BoardHelper.getBoard(this.state.id);

		// after leaving a board or access is removed to board
		if (!this.state.loading && !props.loading && !board) {
			if (this.state.form) {
				// eslint-disable-next-line quotes
				Toast.info(this.props.t("board.access_removed", "Board åtkomst borttagen"));
			}
			if (this.props.history.length > 1) {
				this.props.history.goBack();
			} else {
				this.props.history.push("/admin");
			}
		}

		if (board && JSON.stringify(board) !== JSON.stringify(this.state.form)) {
			this.onUpdateBoard(board);
		}

		if (this.props.loading && !props.loading) {
			if (this.props.loaded_groups) {
				this.fetchGroups(props.id);
			}
			if (this.props.loaded_groups) {
				this.fetchRows(props.id);
			}
			this.setState({ loadingRows: false });
		}
		if (board) {
			this.boardString = CryptoJS.MD5(JSON.stringify(Object.assign({}, board, props.board_companies, props.board_contacts, props.groups)));
		}
		const queryViewId = this.getQueryVariable("view_id");
		if (queryViewId && queryViewId != this.state.view_id) {
			this.setState({ view_id: queryViewId });
			for (let i = 0; i < this.state.form.views.length; i++) {
				if (this.state.form.views[i].id + "" == queryViewId + "") {
					this.setState({ view: this.state.form.views[i] });
				}
			}
		}

		if (props.match && props.match.params.id != this.state.id) {
			this.state.filters = [];
			this.cancelRequests();
			this.setState({
				location: props.location,
				viewingRow: null,
				showRowSheet: false,
			});
			this.fetchItem(props.match.params.id);

			window.socket?.emit("boards/join", {
				board_id: props.match.params.id,
				user_id: store.getState().user.id,
			});
		}

		if (window.location.hash.length > 0) {
			const data = JSON.parse(decodeURIComponent(window.location.hash.substring(1)));
			if (data) {
				this.state.filters = data;
			}
		}
		this.changeFilters(this.state.filters);

		if (!this.state.loadingRows) {
			const queryRowId = this.getQueryVariable("row_id");
			const boardRowSheetIsOpen = this.state.viewingRow && (this.state.showRowSheet || this.state.showRowModal);

			if (queryRowId && (!boardRowSheetIsOpen || String(this.state.viewingRow?.id) != queryRowId)) {
				const row = BoardHelper.getRow(queryRowId);
				this.setState({ showRowSheet: true, viewingRow: row }, this.removeUrlParamRowId.bind(this));
			}
		}
	}

	removeUrlParamRowId() {
		try {
			const params = new URLSearchParams();
			params.delete("row_id");

			const path = window.location.pathname + (Object.keys(params).length ? `?${params}` : "");
			// const url = window.location.origin + path;
			// http://127.0.0.1:8000/admin/workspaces/none/boards/7?row_id=145832

			setTimeout(() => {
				// window.history.replaceState(null, null, url);
				this.props.history.replace(path);
			}, 0);
		} catch (error) {
			console.error("error:", error);
		}
	}

	onFocusWindow() {}

	cancelRequests() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
		BoardHelper.cancelRequests();
	}

	componentDidMount() {
		window.addEventListener("resize", this.windowResizeCallback);
		window.addEventListener("focus", this.windowFocusCallback);
		this.fetchItem(this.state.id);
		window.socket?.emit("boards/join", {
			board_id: this.state.id,
			user_id: store.getState().user.id,
		});

		window.socket?.on("board.update", (board) => {
			debugSocket("board.update", board);
			if (board.columns?.length !== this.state.form?.columns?.length) {
				const windowWidth = $("." + this.state.boardClass).width();
				board.title_width = Math.round(windowWidth / 3);
			}

			this.onUpdateBoard(board);
		});

		window.socket?.on("board_row.create", (row) => {
			debugSocket("board_row.create", row);

			// console.log("board_row.create:", row);
			BoardHelper.updateRows([row], "add");
		});

		window.socket?.on("board_row.update", (row) => {
			debugSocket("board_row.update", row);
			// console.log("socket update row", row);
			if (!this.state.archived && !this.state.also_archived && row.archived) {
				BoardHelper.removeRow(row.id);
				if (this.state.viewingRow && this.state.viewingRow.id == row.id) {
					this.setState({ showRowSheet: false, viewingRow: null });
				}
			} else if (this.state.archived && !row.archived) {
				BoardHelper.removeRow(row.id);
				if (this.state.viewingRow && this.state.viewingRow.id == row.id) {
					this.setState({ showRowSheet: false, viewingRow: null });
				}
			} else {
				this.onUpdateRow(row);
			}
		});

		window.socket?.on("board_row.remove", (row) => {
			debugSocket("board_row.remove", row);

			BoardHelper.removeRow(row.id);
			if (this.state.viewingRow && this.state.viewingRow.id == row.id) {
				this.setState({ showRowSheet: false, viewingRow: null });
			}
		});

		window.socket?.on("board_group.create", (group) => {
			debugSocket("board_group.create", group);

			for (let i = 0; i < this.state.groups.length; i++) {
				if (this.state.groups[i].id == group.id || (group.ref && this.state.groups[i].ref == group.ref)) {
					return;
				}
			}
			this.state.groups.push(group);
			store.dispatch({ type: "ADD_BOARD_GROUP", group });
			this.setState({ groups: this.state.groups });
		});

		window.socket?.on("board_group.update", (group) => {
			debugSocket("board_group.update", group);

			if (group.archived) {
				this.onRemoveGroup(group);
			} else {
				this.onUpdateGroup(group);
			}
		});

		window.socket?.on("board_group.remove", (group) => {
			debugSocket("board_group.remove", group);
			this.onRemoveGroup(group);
		});
	}

	componentWillUnmount() {
		this.cancelRequests();
		// Leave board room?
		window.socket?.emit("boards/leave", {
			board_id: this.state.id,
			user_id: store.getState().user.id,
		});
		window.removeEventListener("focus", this.windowFocusCallback);
		window.removeEventListener("resize", this.windowResizeCallback);
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	fetchItem(id, silent = false, verySilent = false) {
		if (this.props.loaded_board) {
			const windowWidth = $("." + this.state.boardClass).width();
			this.props.loaded_board.title_width = Math.round(windowWidth / 3);
			this.setState({
				loading: false,
				form: BoardHelper.filterOutBlockedColumns(this.props.loaded_board, this.props.showOnlyColumns),
				view: this.props.loaded_board.views.find((v) => v.preferred) || this.props.loaded_board.views[0],
			});
			return;
		}
		this.setState((c) => ({ id, loading: silent ? c.loading : true }));
		if (!verySilent) {
			this.state.rows = [];
			this.state.groups = [];
		}

		const filters = window.location.hash && JSON.parse(decodeURIComponent(window.location.hash.substring(1) || ""));
		const personfilter = filters && filters?.find((f) => f.column_id === "person");

		const params = Object.assign({}, {});

		if (!silent && !verySilent && !this.props.nopreview) params.with_preview = 1;
		if (this.props.archived || this.state.archived == 1) params.archived = 1;
		if (this.props.also_archived || this.state.also_archived == 1) params.also_archived = 1;
		if (this.getQueryVariable("row_id")) params.row_id = this.getQueryVariable("row_id");
		if (personfilter && personfilter.value && Array.isArray(personfilter.value)) params.user_ids = personfilter.value;

		API.get("/api/boards/" + id + ".json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				if (result.data.preview_rows) {
					this.clearMyStuff(result.data.board.id);
				}
				this.setState((c) => ({
					loading: false,
					saved_filters: result.data.saved_filters,
					filteredRows: verySilent ? c.filteredRows : [],
					rows: verySilent ? c.rows : result.data.preview_rows,
					groups: verySilent ? c.groups : result.data.preview_groups,
					favorite: result.data.favorite,
					form: BoardHelper.filterOutBlockedColumns(result.data.board, this.props.showOnlyColumns),
					defaultValues: result.data.board.default_values || [],
					view: this.props.forceList
						? result.data.board.views.find((v) => v.type == "table") || result.data.board.views[0]
						: result.data.board.views.find((v) => v.preferred) || result.data.board.views[0],
					loadingRows: !(result.data.review_rows && result.data.review_rows.length) && c.loadingRows,
				}));

				if ((!this.state.filters || this.state.filters.length < 1) && result.data.saved_filters.find((v) => v.preferred)) {
					this.state.filters = result.data.saved_filters.find((v) => v.preferred).filters;
					this.setState({ filters: this.state.filters });
				}

				this.addMeAsFilter(result.data.board);

				if (this.state.view_id) {
					for (let i = 0; i < result.data.board.views.length; i++) {
						if (result.data.board.views[i].id + "" == this.state.view_id + "") {
							this.setState({ view: result.data.board.views[i] });
						}
					}
				}

				const windowWidth = $("." + this.state.boardClass).width();
				result.data.board.title_width = Math.round(windowWidth / 3);

				if (!verySilent)
					store.dispatch({
						type: "SET_BOARDS",
						boards: [BoardHelper.filterOutBlockedColumns(result.data.board, this.props.showOnlyColumns)],
					});
				if (!verySilent)
					store.dispatch({
						type: "SET_BOARD_GROUPS",
						groups: result.data.preview_groups,
					});
				if (!verySilent) BoardHelper.updateRows(result.data.preview_rows, "set");
				store.dispatch({ type: "SET_BOARD_LINKED_ROWS", linked_rows: [] });
				store.dispatch({ type: "SET_BOARD_CONTACTS", contacts: [] });
				store.dispatch({ type: "SET_BOARD_TICKETS", tickets: [] });
				if (this.getQueryVariable("row_id")) {
					const row = BoardHelper.getRow(this.getQueryVariable("row_id"));
					this.setState({ showRowSheet: true, viewingRow: row }, this.removeUrlParamRowId.bind(this));
				}

				this.fetchGroups(result.data.board.id, silent);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				Toast.error(error);
			});
	}

	addMeAsFilter(board) {
		if (board.me_as_person_filter && !this.state.filters.find((f) => f.key === `person-${board.id}`)) {
			this.state.filters.unshift({
				column_id: "person",
				board_id: board.id,
				key: `person-${board.id}`,
				value: [store.getState().user.id],
			});

			window.location.hash = JSON.stringify(this.state.filters);

			this.changeFilters(this.state.filters);
		}
	}

	fetchCompanies(id) {
		API.get("/api/boards/" + id + "/companies.json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params: {},
		})
			.then((result) => {
				store.dispatch({
					type: "SET_BOARD_COMPANIES",
					companies: result.data.companies,
				});
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				Toast.error(error);
			});
	}

	fetchGroups(id, silent) {
		if (this.props.loaded_groups) {
			this.setState({ groups: this.props.loaded_groups });
			return;
		}
		const params = Object.assign({}, {});
		if (this.props.archived || this.state.archived == 1) params.archived = 1;
		if (this.props.also_archived || this.state.also_archived == 1) params.also_archived = 1;

		API.get("/api/boards/" + id + "/groups.json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				store.dispatch({
					type: "SET_BOARD_GROUPS",
					groups: result.data.groups,
				});
				this.setState({ groups: result.data.groups }, this.fetchRows.bind(this, id, silent));

				for (let i = 0; i < this.state.form?.columns?.length; i++) {
					if (this.state.form.columns[i].type == "company_number") {
						this.fetchCompanies(this.state.form.id, silent);
						break;
					}
				}
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				Toast.error(error);
			});
	}

	getQueryVariable(variable) {
		const params = new URLSearchParams(window.location.search);
		const searchParamVarible = params.get(variable);

		try {
			return JSON.parse(searchParamVarible);
		} catch (error) {
			return searchParamVarible;
		}
	}

	fetchRows(id, silent = false) {
		if (this.props.loaded_rows) {
			this.setState({ filteredRows: this.props.loaded_rows });
			if (this.props.filters) {
				if (this.state.form.me_as_person_filter && !this.props.filters.find((f) => f.key === `person-${id}`)) {
					this.props.filters.unshift({
						column_id: "person",
						board_id: id,
						key: `person-${id}`,
						value: [store.getState().user.id],
					});
					this.conditionallyUpdateHash(this.props.filters);
				}

				this.setState({ filters: this.props.filters }, this.updateFilteredRows.bind(this));
			}
			return;
		}
		if (!silent) this.setState({ loadingRows: true });
		const params = {};
		if (this.props.contact_id) {
			params.contact_id = this.props.contact_id;
			params.also_archived = 1;
		}
		if (this.props.archived || this.state.archived == 1) params.archived = 1;
		if (this.props.also_archived || this.state.also_archived == 1) params.also_archived = 1;

		BoardHelper.fetchRows(
			[id],
			null,
			() => {
				const linkedFromBoardIds = store.getState().board_linked_from_rows.reduce((acc, cur) => {
					if (acc.indexOf(cur.board_id) < 0) {
						acc.push(cur.board_id);
					}
					return acc;
				}, []);

				if (linkedFromBoardIds.length > 0) {
					this.fetchBoards(linkedFromBoardIds);
				}
				this.setState({ loadingRows: false });
			},
			params
		);
	}

	fetchBoards(boardIds) {
		const params = Object.assign({ ids: boardIds }, {});

		API.get("/api/boards/" + this.state.id + "/boards.json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				store.dispatch({ type: "SET_BOARDS", boards: result.data.boards });
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				Toast.error(error);
			});
	}

	handleOpenModal(name, title) {
		const ticket = Object.assign(this.state.ticket || {}, {
			title,
			origin: "ticket",
		});
		this.setState({ [name]: true, ticket });
	}

	handleCloseModal(name) {
		this.setState({ [name]: false, ticket: {} });
	}

	addRow(title, groupId, propsDefaultValues) {
		const defaultValues = filterDefaultValues(propsDefaultValues);
		if (BoardHelper.isSupportBoard(this.state.id)) {
			this.handleOpenModal("ticketmodal");
			return;
		}
		if (!this.state.groups.length) {
			return;
		}
		if (this.state.view.type != "table") {
			// Show modal in other views
			this.setState({
				showRowModal: true,
				editingRow: null,
				tempDefaultValues: defaultValues,
				defaultOptions: { group_id: groupId },
			});
			return;
		}
		if (!title) {
			Toast.warning(this.props.t("row.errors.missing_title", "Titel måste vara satt"));
			return;
			// throw new Error("Title måste vara satt");
		}
		const data = {
			title,
			comment_count: 0,
			group_id: groupId || this.state.groups[0].id,
			values: this.mergeDefaultValues(defaultValues),
			column_values: {},
			ref: Math.random(),
		};

		API.post("/api/boards/" + this.state.form.id + "/groups/" + (groupId || this.state.groups[0].id) + "/rows.json", data, {
			params: {},
		})
			.then((result) => BoardHelper.updateRows([result.data.row], "add"))
			.catch((error) => Toast.error(error));
	}

	onDragStart(item, hover) {
		this.setState({ draggingRow: item, hoveringRow: hover });
	}

	onDragEndKanban(/* item */) {
		this.setState({ hoveringRow: null });
	}

	onDragEnd(item) {
		if (this.state.view.type == "kanban") {
			return this.onDragEndKanban(item);
		}
		const newItem = Object.assign({}, item);
		newItem.group_id = JSON.parse(JSON.stringify(this.state.hoveringRow.group_id));
		const newArr = [];
		const found = false;
		const pos = -1;

		if (!found) {
			newItem.position = JSON.parse(JSON.stringify(pos + 1));
			newArr.push(newItem);
		}

		if (newItem.position != item.position || newItem.group_id != item.group_id) {
			API.put(
				"/api/boards/" + this.state.form.id + "/rows/" + newItem.id + ".json",
				{ group_id: newItem.group_id, position: newItem.position },
				{ params: {} }
			)
				// .then((result) => {})
				.catch((error) => {
					Toast.error(error);
				});
		}
		this.setState({ hoveringRow: null, rows: newArr });
		store.dispatch({ type: "SET_BOARD_ROWS", rows: newArr });
	}

	onDragHover(item) {
		if (
			!this.state.hoveringRow ||
			this.state.hoveringRow.status != item.status ||
			this.state.hoveringRow.group_id != item.group_id ||
			((this.state.hoveringRow.id != item.id || this.state.hoveringRow.group_id != item.group_id) && item.id != this.state.draggingRow.id)
		) {
			this.setState({ hoveringRow: item });
		}
	}

	onUpdateBoard(board = this.state.form) {
		const filteredBoard = BoardHelper.filterOutBlockedColumns(board, this.props.showOnlyColumns);
		this.setState({ form: filteredBoard });
		store.dispatch({ type: "SET_BOARD", board: filteredBoard });
	}

	onCreateColumn(column) {
		for (let i = 0; i < this.state.form.columns.length; i++) {
			if (this.state.form.columns[i].id == column.id) {
				return;
			}
		}
		this.state.form.columns.push(column);
		const filteredBoard = BoardHelper.filterOutBlockedColumns(this.state.form, this.props.showOnlyColumns);
		this.setState({ form: filteredBoard });
		store.dispatch({ type: "SET_BOARD", board: filteredBoard });
	}

	onUpdateColumn(column) {
		for (let i = 0; i < this.state.form.columns.length; i++) {
			if (this.state.form.columns[i].id == column.id) {
				this.state.form.columns[i] = column;
				break;
			}
		}
		const filteredBoard = BoardHelper.filterOutBlockedColumns(this.state.form, this.props.showOnlyColumns);
		this.setState({ form: filteredBoard });
		store.dispatch({ type: "SET_BOARD", board: filteredBoard });
	}

	onRemoveColumn(column) {
		for (let i = 0; i < this.state.form.columns.length; i++) {
			if (this.state.form.columns[i].id == column.id) {
				this.state.form.columns.splice(i, 1);
				break;
			}
		}

		/*
		TODO: Remove values from all rows also
    */
		const filteredBoard = BoardHelper.filterOutBlockedColumns(this.state.form, this.props.showOnlyColumns);
		this.setState({ form: filteredBoard });
		store.dispatch({ type: "SET_BOARD", board: filteredBoard });
	}

	onUpdateGroup(group) {
		for (let i = 0; i < this.state.groups.length; i++) {
			if (this.state.groups[i].id == group.id) {
				this.state.groups[i] = group;
				break;
			}
		}
		store.dispatch({ type: "UPDATE_BOARD_GROUP", group });
		this.setState({ groups: this.state.groups });
	}

	onRemoveGroup(group) {
		BoardHelper.removeGroup(group.id);
	}

	onUpdateRow(row) {
		BoardHelper.updateRows([row], "update");
	}

	onRemoveRow(row) {
		BoardHelper.removeRow(row.id);
	}

	onDuplicateRow(row, position) {
		const data = {
			...row,
			dupe_id: row.id,
			ref: Math.random(),
			position,
		};

		delete data.id;
		delete data.metadata;
		delete data.column_values;
		delete data.values;

		API.post("/api/boards/" + this.state.form.id + "/groups/" + row.group_id + "/rows.json", data, {
			params: {},
		})
			.then((result) => {
				BoardHelper.updateRows([result.data.row], "add");
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	onUpdateView(view) {
		for (let i = 0; i < this.state.form.views.length; i++) {
			if (this.state.form.views[i].id == view.id) {
				this.state.form.views[i] = view;
				break;
			}
		}
		const filteredBoard = BoardHelper.filterOutBlockedColumns(this.state.form, this.props.showOnlyColumns);
		this.setState({ form: filteredBoard, view });
		store.dispatch({ type: "SET_BOARD", board: filteredBoard });
		API.put("/api/boards/" + this.state.form.id + "/views/" + view.id + ".json", view, { params: {} })
			.then((result) => {
				if (result.data.error) {
					Toast.error(result.data.error);
				}
			})
			.catch((error) => {
				Toast.error(error);
			});
	}

	onDragGroupStart(group, event) {
		event.preventDefault();
		this.setState({ draggingGroup: group });
		window.addEventListener("mouseup", this.stopDragGroupCallback);
	}

	onDragGroupOver(group /* event */) {
		const arr = [];
		let found = false;
		for (let i = 0; i < this.state.groups.length; i++) {
			if (group && this.state.groups[i].id == group.id) {
				arr.push(this.state.draggingGroup);
				found = true;
			}
			if (this.state.groups[i].id != this.state.draggingGroup.id) {
				arr.push(this.state.groups[i]);
			}
		}
		if (!found) {
			arr.push(this.state.draggingGroup);
		}
		store.dispatch({ type: "SET_BOARD_GROUPS", groups: arr });
		this.setState({ groups: arr });
	}

	onDragGroupEnd(/* event */) {
		for (let i = 0; i < this.state.groups.length; i++) {
			if (this.state.draggingGroup.id == this.state.groups[i].id && this.state.groups[i].position != i) {
				API.put("/api/boards/" + this.state.form.id + "/groups/" + this.state.draggingGroup.id + ".json", { position: i }, { params: {} })
					.then((result) => {
						if (result.data.error) {
							Toast.error(result.data.error);
							return;
						}
						this.onUpdateGroup(result.data.group);
					})
					.catch((error) => {
						Toast.error(error);
					});
				break;
			}
		}
		window.removeEventListener("mouseup", this.stopDragGroupCallback);
		this.setState({ draggingGroup: null });
	}

	changeView(view, switchDirection) {
		this.setState({
			view,
			switchDirection,
			tempDefaultValues: null,
			defaultOptions: null,
		});
	}

	addView(view) {
		this.state.form.views.push(view);
		this.setState({ form: this.state.form });
		store.dispatch({
			type: "SET_BOARD",
			board: this.state.form,
			tempDefaultValues: null,
			defaultOptions: null,
		});
	}

	changeFilters(filters) {
		this.state.filters = filters;
		this.setState({ filters }, this.updateFilteredRows.bind(this));
	}

	updateFilteredRows() {
		this.setState({
			filteredRows: BoardHelper.filterRows(
				Object.values(store.getState().board_rows).filter((row) => row.board_id == this.state.id),
				this.state.filters
			),
			offset: 0,
		});

		this.conditionallyUpdateHash(this.state.filters);
	}

	conditionallyUpdateHash(filters = this.state.filters) {
		const data = decodeURIComponent(window.location.hash.substring(1));

		if (!data && !filters?.length) return;
		if (data == decodeURIComponent(JSON.stringify(filters))) return;

		// eslint-disable-next-line no-console
		console.log("Updating hash");
		window.location.hash = JSON.stringify(this.state.filters);
	}

	mergeDefaultValues(defaultValues = []) {
		const n = filterDefaultValues(this.state.form.default_values.slice() || []);
		if (defaultValues) {
			defaultValues.forEach((defaultValue) => {
				let found = false;
				for (let s = 0; s < n.length; s++) {
					if (defaultValue.column_id == n[s].column_id) {
						found = true;
						n[s].value = defaultValue.value;
						break;
					}
				}
				if (!found) {
					n.push(defaultValue);
				}
			});
		}
		return n;
	}

	haveSearch() {
		if (this.props.contact_id) {
			return true;
		}
		for (let i = 0; i < this.state.filters.length; i++) {
			if (this.state.filters[i].column_id != "person") {
				return true;
			}
		}
		return false;
	}

	onChangeSorting(sorting) {
		for (let i = 0; i < sorting.length; i++) {
			if (sorting[i].column_id == "title") {
				sorting[i].column_type = "title";
			} else {
				for (let s = 0; s < this.state.form.columns.length; s++) {
					if (this.state.form.columns[s].id + "" == sorting[i].column_id + "") {
						sorting[i].column_type = this.state.form.columns[s].type;
						sorting[i].column = this.state.form.columns[s];
						break;
					}
				}
			}
		}
		this.setState({ sorting: sorting.slice() });
	}

	render() {
		let view = null;
		let header = null;

		if (!this.state.loading) {
			if (
				store.getState().account.visitor_board_id == this.state.form.id &&
				!this.state.loadingRows &&
				this.state.filteredRows.length < 1 &&
				this.state.filters.length < 1
			) {
				return <VisitorBoardEmptyScreen board={this.state.form} />;
			}

			/*
			if (BoardHelper.isSupportBoard(this.state.id)) {
				bottomBanner = (
					<div className="fixed-column" style={{ width: $(".Polaris-Frame__Content").width(), paddingLeft: 20, paddingRight: 20 }}>
						<Banner status="info" title={"Mail som skickas till " + store.getState().account.support_mail + " kommer in som ärenden här."}>
							<p>Vill du ta emot och skicka e-post från egen domän? Hör av dig till kundtjänst</p>
						</Banner>
					</div>
				);
			}
			*/
			const filteredRows = this.state.filteredRows;

			if ((store.getState().user.calendar_board && store.getState().user.calendar_board.id) === this.state.form.id) {
				return (
					<FixedCalendarView
						boardString={this.boardString}
						// haveSearch={this.haveSearch()}
						view={this.state.view}
						onCreateRow={this.addRow.bind(this)}
						// onUpdateColumn={this.onUpdateColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						// onUpdateRow={this.onUpdateRow.bind(this)}
						// onRemoveRow={this.onRemoveRow.bind(this)}
						onChangeDefaultValues={(defaultValues) => {
							this.setState({
								defaultValues: this.mergeDefaultValues(defaultValues),
							});
						}}
						// editRow={(row) => {
						// 	this.setState({ showRowModal: true, editingRow: row });
						// }}
						// openRow={(row) => {
						// 	this.setState({ showRowSheet: true, viewingRow: row });
						// }}
						onCloseRow={() => {
							this.setState({ showRowSheet: false, viewingRow: null });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						row={this.state.showRowSheet && this.state.viewingRow}
					/>
				);
			}

			if (this.getQueryVariable("embed") || this.props.noheader) {
				header = <div className="board-main-header" />;
			} else {
				header = (
					<div className="board-main-header">
						<BoardHeader
							boardClass={this.state.boardClass}
							saved_filters={this.state.saved_filters}
							updateSavedFilters={(savedFilters) => {
								this.setState({ saved_filters: savedFilters });
							}}
							workspace_id={this.state.workspace_id}
							view={this.state.view}
							favorite={this.state.favorite}
							board={this.state.form}
							switchDirection={this.state.switchDirection}
							roles={BoardHelper.getMyRoles(this.state.form)}
							groups={this.state.groups}
							history={this.props.history}
							rows={Object.values(store.getState().board_rows)}
							filteredRows={filteredRows}
							filters={this.state.filters}
							sorting={this.state.sorting}
							onChangeSorting={this.onChangeSorting.bind(this)}
							onUpdateBoard={this.onUpdateBoard.bind(this)}
							onCreateView={this.addView.bind(this)}
							onChangeView={this.changeView.bind(this)}
							onChangeFilters={this.changeFilters.bind(this)}
							onCreateRow={this.addRow.bind(this)}
							openCalendarModal={() => {
								this.setState({ showCalendarModal: true });
							}}
							onToggleSettings={() => {
								this.setState({ showViewSettings: true });
							}}
							onUpdateColumn={this.onUpdateColumn.bind(this)}
							form={this.state.form}
							onUpdatePagination={(offset, limit) => {
								this.setState({ offset, limit });
							}}
							offset={this.state.offset}
							limit={this.state.view.type == "simple" ? this.state.limit : 0}
							openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
							location={this.state.location}
							contact_id={this.props.contact_id}
						>
							{this.state.view.type == "simple" ? (
								<BoardViewSimpleList
									header={header}
									containerClass={this.state.containerClass}
									boardClass={this.state.boardClass}
									boardString={this.boardString}
									haveSearch={this.haveSearch()}
									offset={this.state.offset}
									limit={this.state.limit}
									view={this.state.view}
									onCreateRow={this.addRow.bind(this)}
									hoveringRow={this.state.hoveringRow}
									sorting={this.state.sorting}
									onChangeSorting={this.onChangeSorting.bind(this)}
									onDragGroupStart={this.onDragGroupStartCallback}
									onDragGroupOver={this.onDragGroupOverCallback}
									onDragHover={this.onDragHover.bind(this)}
									onDragStart={this.onDragStart.bind(this)}
									onDragEnd={this.onDragEnd.bind(this)}
									onCreateColumn={this.onCreateColumn.bind(this)}
									onUpdateColumn={this.onUpdateColumn.bind(this)}
									onRemoveColumn={this.onRemoveColumn.bind(this)}
									onUpdateBoard={this.onUpdateBoard.bind(this)}
									onUpdateGroup={this.onUpdateGroup.bind(this)}
									onRemoveGroup={this.onRemoveGroup.bind(this)}
									onUpdateRow={this.onUpdateRow.bind(this)}
									onRemoveRow={this.onRemoveRow.bind(this)}
									onDuplicateRow={this.onDuplicateRow.bind(this)}
									onUpdateView={this.onUpdateView.bind(this)}
									draggingGroup={this.state.draggingGroup}
									draggingColumn={this.state.draggingColumn}
									openCalendarModal={() => {
										this.setState({ showCalendarModal: true });
									}}
									editRow={(row) => {
										this.setState({ showRowModal: true, editingRow: row });
									}}
									openRow={(row) => {
										this.setState({ showRowSheet: true, viewingRow: row });
									}}
									board={this.state.form}
									rows={filteredRows}
									groups={this.state.groups}
									openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
									history={this.props.history}
								/>
							) : null}
						</BoardHeader>
					</div>
				);
			}

			if (this.state.view.type == "table") {
				view = (
					<BoardViewList
						header={header}
						noheader={this.props.noheader}
						containerClass={this.state.containerClass}
						boardClass={this.state.boardClass}
						boardString={this.boardString}
						haveSearch={this.haveSearch()}
						view={this.state.view}
						onCreateRow={this.addRow.bind(this)}
						hoveringRow={this.state.hoveringRow}
						sorting={this.state.sorting}
						onChangeSorting={this.onChangeSorting.bind(this)}
						onDragGroupStart={this.onDragGroupStartCallback}
						onDragGroupOver={this.onDragGroupOverCallback}
						onDragHover={this.onDragHover.bind(this)}
						onDragStart={this.onDragStart.bind(this)}
						onDragEnd={this.onDragEnd.bind(this)}
						onCreateColumn={this.onCreateColumn.bind(this)}
						onUpdateColumn={this.onUpdateColumn.bind(this)}
						onRemoveColumn={this.onRemoveColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						onUpdateGroup={this.onUpdateGroup.bind(this)}
						onRemoveGroup={this.onRemoveGroup.bind(this)}
						onUpdateRow={this.onUpdateRow.bind(this)}
						onRemoveRow={this.onRemoveRow.bind(this)}
						onDuplicateRow={this.onDuplicateRow.bind(this)}
						onUpdateView={this.onUpdateView.bind(this)}
						draggingGroup={this.state.draggingGroup}
						draggingColumn={this.state.draggingColumn}
						openCalendarModal={() => {
							this.setState({ showCalendarModal: true });
						}}
						editRow={(row) => {
							this.setState({ showRowModal: true, editingRow: row });
						}}
						openRow={(row) => {
							this.setState({ showRowSheet: true, viewingRow: row });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
						onChangeFilters={this.changeFilters.bind(this)}
						history={this.props.history}
					/>
				);
			} else if (this.state.view.type == "simple") {
				view = header;
			} else if (this.state.view.type == "timeline") {
				view = (
					<BoardViewTimeline
						header={header}
						boardString={this.boardString}
						haveSearch={this.haveSearch()}
						showSettings={this.state.showViewSettings}
						onCloseSettings={() => {
							this.setState({ showViewSettings: false });
						}}
						view={this.state.view}
						onCreateRow={this.addRow.bind(this)}
						hoveringRow={this.state.hoveringRow}
						onCreateColumn={this.onCreateColumn.bind(this)}
						onUpdateColumn={this.onUpdateColumn.bind(this)}
						onRemoveColumn={this.onRemoveColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						onUpdateGroup={this.onUpdateGroup.bind(this)}
						onUpdateRow={this.onUpdateRow.bind(this)}
						onRemoveRow={this.onRemoveRow.bind(this)}
						onDuplicateRow={this.onDuplicateRow.bind(this)}
						onUpdateView={this.onUpdateView.bind(this)}
						draggingColumn={this.state.draggingColumn}
						onChangeDefaultValues={(defaultValues) => {
							this.setState({
								defaultValues: this.mergeDefaultValues(defaultValues),
							});
						}}
						editRow={(row) => {
							this.setState({ showRowModal: true, editingRow: row });
						}}
						openRow={(row) => {
							this.setState({ showRowSheet: true, viewingRow: row });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
					/>
				);
			} else if (this.state.view.type == "calendar") {
				view = (
					<BoardViewCalendar
						header={header}
						boardString={this.boardString}
						haveSearch={this.haveSearch()}
						showSettings={this.state.showViewSettings}
						onCloseSettings={() => {
							this.setState({ showViewSettings: false });
						}}
						view={this.state.view}
						onCreateRow={this.addRow.bind(this)}
						hoveringRow={this.state.hoveringRow}
						onCreateColumn={this.onCreateColumn.bind(this)}
						onUpdateColumn={this.onUpdateColumn.bind(this)}
						onRemoveColumn={this.onRemoveColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						onUpdateGroup={this.onUpdateGroup.bind(this)}
						onUpdateRow={this.onUpdateRow.bind(this)}
						onRemoveRow={this.onRemoveRow.bind(this)}
						onDuplicateRow={this.onDuplicateRow.bind(this)}
						onUpdateView={this.onUpdateView.bind(this)}
						draggingColumn={this.state.draggingColumn}
						onChangeDefaultValues={(defaultValues) => {
							this.setState({
								defaultValues: this.mergeDefaultValues(defaultValues),
							});
						}}
						editRow={(row) => {
							this.setState({ showRowModal: true, editingRow: row });
						}}
						openRow={(row) => {
							this.setState({ showRowSheet: true, viewingRow: row });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
					/>
				);
			} else if (this.state.view.type == "kanban") {
				view = (
					<BoardViewKanban
						header={header}
						boardString={this.boardString}
						haveSearch={this.haveSearch()}
						showSettings={this.state.showViewSettings}
						onCloseSettings={() => {
							this.setState({ showViewSettings: false });
						}}
						view={this.state.view}
						onCreateRow={this.addRow.bind(this)}
						onCreateColumn={this.onCreateColumn.bind(this)}
						onUpdateColumn={this.onUpdateColumn.bind(this)}
						onRemoveColumn={this.onRemoveColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						onUpdateGroup={this.onUpdateGroup.bind(this)}
						onUpdateRow={this.onUpdateRow.bind(this)}
						onRemoveRow={this.onRemoveRow.bind(this)}
						onDuplicateRow={this.onDuplicateRow.bind(this)}
						onUpdateView={this.onUpdateView.bind(this)}
						draggingColumn={this.state.draggingColumn}
						onChangeDefaultValues={(defaultValues) => {
							this.setState({
								defaultValues: this.mergeDefaultValues(defaultValues),
							});
						}}
						editRow={(row) => {
							this.setState({ showRowModal: true, editingRow: row });
						}}
						openRow={(row) => {
							this.setState({ showRowSheet: true, viewingRow: row });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
					/>
				);
			} else if (this.state.view.type == "form") {
				view = (
					<BoardViewForm
						header={header}
						boardString={this.boardString}
						haveSearch={this.haveSearch()}
						showSettings={this.state.showViewSettings}
						onCloseSettings={() => {
							this.setState({ showViewSettings: false });
						}}
						view={this.state.view}
						editing
						onCreateRow={this.addRow.bind(this)}
						hoveringRow={this.state.hoveringRow}
						onDragHover={this.onDragHover.bind(this)}
						onDragStart={this.onDragStart.bind(this)}
						onDragEnd={this.onDragEnd.bind(this)}
						onCreateColumn={this.onCreateColumn.bind(this)}
						onUpdateColumn={this.onUpdateColumn.bind(this)}
						onRemoveColumn={this.onRemoveColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						onUpdateGroup={this.onUpdateGroup.bind(this)}
						onUpdateRow={this.onUpdateRow.bind(this)}
						onRemoveRow={this.onRemoveRow.bind(this)}
						onDuplicateRow={this.onDuplicateRow.bind(this)}
						onUpdateView={this.onUpdateView.bind(this)}
						draggingColumn={this.state.draggingColumn}
						onChangeDefaultValues={(defaultValues) => {
							this.setState({
								defaultValues: this.mergeDefaultValues(defaultValues),
							});
						}}
						// onCreateRow={(row) => {}}
						editRow={(row) => {
							this.setState({ showRowModal: true, editingRow: row });
						}}
						openRow={(row) => {
							this.setState({ showRowSheet: true, viewingRow: row });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
					/>
				);
			} else if (this.state.view.type == "call_list") {
				view = (
					<BoardViewCallList
						filters={this.state.filters}
						header={header}
						noheader={this.props.noheader}
						containerClass={this.state.containerClass}
						showSettings={this.state.showViewSettings}
						onCloseSettings={() => {
							this.setState({ showViewSettings: false });
						}}
						boardClass={this.state.boardClass}
						boardString={this.boardString}
						haveSearch={this.haveSearch()}
						view={this.state.view}
						onCreateRow={this.addRow.bind(this)}
						hoveringRow={this.state.hoveringRow}
						sorting={this.state.sorting}
						onChangeSorting={this.onChangeSorting.bind(this)}
						onDragGroupStart={this.onDragGroupStartCallback}
						onDragGroupOver={this.onDragGroupOverCallback}
						onDragHover={this.onDragHover.bind(this)}
						onDragStart={this.onDragStart.bind(this)}
						onDragEnd={this.onDragEnd.bind(this)}
						onCreateColumn={this.onCreateColumn.bind(this)}
						onUpdateColumn={this.onUpdateColumn.bind(this)}
						onRemoveColumn={this.onRemoveColumn.bind(this)}
						onUpdateBoard={this.onUpdateBoard.bind(this)}
						onUpdateGroup={this.onUpdateGroup.bind(this)}
						onRemoveGroup={this.onRemoveGroup.bind(this)}
						onUpdateRow={this.onUpdateRow.bind(this)}
						onRemoveRow={this.onRemoveRow.bind(this)}
						onDuplicateRow={this.onDuplicateRow.bind(this)}
						onUpdateView={this.onUpdateView.bind(this)}
						draggingGroup={this.state.draggingGroup}
						draggingColumn={this.state.draggingColumn}
						openCalendarModal={() => {
							this.setState({ showCalendarModal: true });
						}}
						editRow={(row) => {
							this.setState({ showRowModal: true, editingRow: row });
						}}
						openRow={(row) => {
							this.setState({ showRowSheet: true, viewingRow: row });
						}}
						board={this.state.form}
						rows={filteredRows}
						groups={this.state.groups}
						openTicketModal={this.handleOpenModal.bind(this, "ticketmodal")}
						onChangeFilters={this.changeFilters.bind(this)}
					/>
				);
			}
		}

		return (
			<div
				className={this.state.boardClass + " " + (!this.state.loading ? "view-" + this.state.view.type : "loading")}
				style={{
					overflow: this.props.containerClass ? "visible" : "auto",
					width: "100%",
					// height: "100%",
					height: window.innerHeight - 56,
					maxHeight: window.innerHeight - 56,
				}}
			>
				{this.state.loading || (this.props.noheader && this.state.loadingRows) ? (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<SkeletonBoard {...this.props} />
				) : (
					<React.Fragment>
						{view}
						<RowModal
							open={this.state.showRowModal}
							row={this.state.editingRow}
							board={this.state.form}
							groups={this.state.groups}
							onUpdateBoard={this.onUpdateBoard.bind(this)}
							onUpdateColumn={this.onUpdateColumn.bind(this)}
							onUpdateRow={this.onUpdateRow.bind(this)}
							defaultValues={
								[]
									.concat(
										this.state.defaultValues?.filter(
											(val) => !this.state.tempDefaultValues || !this.state.tempDefaultValues?.find((v) => v.column_id == val.column_id)
										)
									)
									.concat((this.state.tempDefaultValues && this.state.tempDefaultValues?.filter((i) => i && i.column_id)) || []) || []
							}
							defaultOptions={this.state.defaultOptions}
							onCreateRow={(row) => {
								if (this.state.view && this.state.view.type === "kanban") {
									this.state.view.options.kanbanOrdering.unshift(row.id);
									this.setState({ view: this.state.view });
								}
								if (this.state.view && this.state.view.type === "call_list") {
									this.state.view.options.order.unshift(row.id);
									this.setState({ view: this.state.view });
								}
							}}
							onClose={() => {
								this.setState({
									showRowModal: false,
									tempDefaultValues: null,
									defaultOptions: null,
								});
							}}
						/>

						<RowSheet
							open={this.state.showRowSheet && (this.state.viewingRow && this.state.viewingRow.board_id) == this.state.id}
							row={this.state.viewingRow}
							board={this.state.form}
							initialTabIndex={BoardHelper.isSupportBoard(this.state.id) ? 1 : 0}
							onUpdateRow={this.onUpdateRow.bind(this)}
							onClose={() => {
								this.setState({ showRowSheet: false });
							}}
							onCreateRow={(row) => {
								if (this.state.view && this.state.view.type === "kanban") {
									this.state.view.options.kanbanOrdering.unshift(row.id);
									this.setState({ view: this.state.view });
								}
								if (this.state.view && this.state.view.type === "call_list") {
									this.state.view.options.order.unshift(row.id);
									this.setState({ view: this.state.view });
								}
							}}
						/>
						<TicketModal
							open={this.state.ticketmodal}
							onCreate={() => {
								this.handleCloseModal("ticketmodal");
							}}
							ticket={this.state.ticket || {}}
							contact={this.state.form.contact}
							onMessage={() => {
								this.handleCloseModal("ticketmodal");
							}}
							onClose={this.handleCloseModal.bind(this, "ticketmodal")}
						/>
						{this.state.form.columns.map((column) => {
							if (column.type == "timeline") {
								return (
									<SyncCalendarModal
										key={column.id}
										open={this.state.showCalendarModal}
										onClose={() => {
											this.setState({ showCalendarModal: false });
										}}
										onOpen={() => {
											this.setState({ showCalendarModal: true });
										}}
										onUpdateColumn={this.onUpdateColumn.bind(this)}
										column={column}
										board={this.state.form}
									/>
								);
							}
							return null;
						})}
					</React.Fragment>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	boards: state.boards,
	rows: state.board_rows,
	groups: state.board_groups,
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
});

export default connect(mapStateToProps)(withTranslation(["board", "row", "common"], { withRef: true })(BoardView));
