import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import API from "src/js/API";
import Mappings from "src/js/components/Mappings/Mappings";
import Spinner from "src/js/components/Spinner";
import Upload from "src/js/components/Upload";
import Modal from "src/js/components/modal";
import Toast from "src/js/components/Toast";

type ImportModalProps = {
	open: boolean;
	onClose: () => void;
	resourceName: { singular: string; plural: string };
	type: string;
	metadata?: {
		[key: string]: any; //! dial_list_id
	};
};

const ImportModal = ({ open, onClose, resourceName, type, metadata }: ImportModalProps) => {
	const { t } = useTranslation(["import", "common"]);
	const [upload, setUpload] = useState(null);
	const [form, setForm] = useState<any>(null);

	const [mappings, setMappings] = useState<any>({});
	const [sourceMappings, setSourceMappings] = useState<any>([]);
	const [fileMappings, setFileMappings] = useState<any>([]);

	const [isLoading, setIsLoading] = useState(false);

	const handleClose = () => {
		onClose();
		setUpload(null);
		setMappings([]);
		setForm(null);
		setSourceMappings([]);
		setFileMappings([]);
	};

	const handleAnalizeExcel = async (file) => {
		try {
			setIsLoading(true);
			setUpload(file);
			const result = await API.post("/api/imports.json", { file: file.data, type, metadata });

			const importData = result.data.import;
			setForm(importData);
			setSourceMappings(result.data.source_mappings);
			setFileMappings(result.data.file_mappings);
		} catch (error) {
			Toast.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleImport = async () => {
		try {
			const result = await API.put(`/api/imports/${form?.id}.json`, { mappings });
			setForm(result.data.import);

			await API.post(`/api/imports/${form?.id}/start.json`);
			Toast.success(t("import.articles.modal.success", "Importen tillagd i kö"));

			handleClose();
		} catch (error) {
			Toast.error(error);
		}
	};

	const content = (() => {
		if (!upload) return <Upload width="100%" height={300} type="file" onUpload={handleAnalizeExcel} allowMultiple={false} />;

		return (
			<Mappings
				title={t("import.articles.modal.mapping.title", "Välj hur du vill koppla dina kolumner")}
				onChange={setMappings}
				mappings={mappings}
				sourceMappings={sourceMappings}
				fileMappings={fileMappings}
			/>
		);
	})();

	const allRequiredMapped = sourceMappings.every((m) => (m.required ? mappings[m.value] : true));

	return (
		<Modal
			title={t("import.modal.title", "Importera {{plural}}", {
				plural: resourceName.plural,
			})}
			open={open}
			onClose={handleClose}
			sectioned
			primaryAction={{
				content: t("import.articles.modal.primaryAction.label", "Importera") as string,
				onAction: handleImport,
				disabled: !allRequiredMapped || isLoading,
			}}
			secondaryActions={[
				{
					content: t("import.articles.modal.secondaryActions.cancel.label", "Avbryt") as string,
					onAction: handleClose,
				},
			]}
		>
			{isLoading && <Spinner />}
			{!isLoading && content}
		</Modal>
	);
};
export default ImportModal;
