import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import BoardHelper from "./BoardHelper.js";
import CellText from "./CellText.js";
import CellStatus from "./CellStatus.js";
import CellTags from "./CellTags.js";
import CellNumber from "./CellNumber.js";
import CellCheckbox from "./CellCheckbox.js";
import CellTimetrack from "./CellTimetrack.js";
import CellPerson from "./CellPerson.js";
import CellDateTime from "./CellDateTime.js";
import CellTimeline from "./CellTimeline.js";
import CellRowLink from "./CellRowLink.js";
import CellRowLinkFrom from "./CellRowLinkFrom.js";
import CellChecklist from "./CellChecklist.js";
import CellContact from "./CellContact.js";
import CellTicket from "./CellTicket.js";
import CellVisitor from "./CellVisitor.js";
import CellPhone from "./CellPhone.js";
import CellDropdown from "./CellDropdown.js";
import CellCategory from "./CellCategory.js";
import CellButton from "./CellButton.js";
import CellLink from "./CellLink.js";
import CellCreatedAt from "./CellCreatedAt.js";
import CellBoardNumber from "./CellBoardNumber.js";
import CellCompanyNumber from "./CellCompanyNumber.js";
import CellUpload from "./CellUpload";
import Tooltip from "./components/Tooltip.js";
import CellBadges from "./CellBadges.js";

class BoardCell extends Component {
	constructor(props) {
		super(props);

		this.state = { focused: false };
	}

	focusCell() {
		this.setState({ focused: true });
	}

	blurCell() {
		this.setState({ focused: false });
	}

	updateCell(column, data) {
		// When used in BoardViewList the "this.props.onUpdateValue" is defined so we can handle when multiple rows are selected
		if (this.props.onUpdateValue) {
			this.props.onUpdateValue(this.props.row, column, data);
		} else {
			BoardHelper.onUpdateValue(this.props.row, column, data);
		}
	}

	getValue() {
		let values = [];
		if (this.props.row) {
			values = BoardHelper.getColumnValues(this.props.row, this.props.column);
		} else {
			values = [];
			for (let i = 0; i < this.props.rows.length; i++) {
				if (this.props.rows[i] != "summary" && this.props.rows[i] != "quickadd") {
					const rowValues = BoardHelper.getColumnValues(this.props.rows[i], this.props.column);
					for (let x = 0; x < rowValues.length; x++) {
						values.push(rowValues[x]);
					}
				}
			}
		}

		let summary = this.props.summary;

		if (this.props.column.type == "mirror" && this.props.column.connected_column) {
			if (this.props.summary) {
				summary = true;
			} else {
				if (values.length == 1) {
					summary = false;
				} else {
					summary = true;
				}
			}
		}

		let value = null;
		if (!summary && values.length > 0 && values[0]) {
			value = values[0].value;
		}

		return {
			value,
			values,
			summary,
			empty: !value || !values?.length,
		};
	}

	getCell() {
		const column = BoardHelper.getColumn(this.props.column);

		let linkedRow = null;
		if (this.props.column.type == "mirror" && this.props.column.link_column_id) {
			const rowId = this.props.row?.column_values?.[this.props.column?.link_column_id]?.value?.[0];

			if (rowId) {
				linkedRow = BoardHelper.getLinkedRow(rowId);
			}
		}

		const { value, values, summary } = this.getValue();

		if (column.type == "text") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<Tooltip content={value}>
						<CellText
							height={25}
							onChange={this.updateCell.bind(this, column)}
							column={column}
							onUpdateColumn={BoardHelper.updateColumn}
							onFocus={this.focusCell.bind(this)}
							onBlur={this.blurCell.bind(this)}
							value={value}
							disabled={column.disabled || column.values_locked}
						/>
					</Tooltip>
				</div>
			);
		} else if (column.type == "phone") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellPhone
						height={25}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onUpdateColumn={BoardHelper.updateColumn}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "button") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellButton height={25} row={this.props.column.type == "mirror" ? linkedRow : this.props.row} column={column} />
				</div>
			);
		} else if (column.type == "number") {
			return (
				<div style={{ width: "100%", height: "100%", padding: summary ? 0 : 3 }}>
					<CellNumber
						height={summary ? 35 : 25}
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onUpdateColumn={BoardHelper.updateColumn}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "status") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellStatus
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "contact") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellContact
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						row={this.props.row}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
						initialContact={this.props.initialContact}
						fixed={this.props.fixed}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "ticket") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellTicket
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "visitor") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellVisitor
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "upload") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellUpload
						height={35}
						width={800}
						row={this.props.row}
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
						board={this.props.board}
						history={this.props.history}
					/>
				</div>
			);
		} else if (column.type == "checkbox") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellCheckbox
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "datetime") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellDateTime
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "created_at") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellCreatedAt row={this.props.column.type == "mirror" ? linkedRow : this.props.row} />
				</div>
			);
		} else if (column.type == "board_number") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellBoardNumber row={this.props.column.type == "mirror" ? linkedRow : this.props.row} />
				</div>
			);
		} else if (column.type == "company_number") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellCompanyNumber
						height={25}
						row={this.props.row}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onUpdateColumn={BoardHelper.updateColumn}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "link") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellLink
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "timeline") {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3 }}>
					<CellTimeline
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						row={this.props.row}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "tags") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellTags
						summary={summary}
						height={35}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						onUpdateBoard={this.props.onUpdateBoard}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "dropdown") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellDropdown
						summary={summary}
						height={35}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						// onUpdateColumn={BoardHelper.updateColumn}

						onUpdateColumn={(column) => {
							BoardHelper.updateColumn(column);
						}}
						onUpdateBoard={this.props.onUpdateBoard}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "badges") {
			// Only used for contact tags, because its not index based and read only
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellBadges
						summary={summary}
						height={35}
						values={summary ? values : null}
						column={column}
						board={this.props.board}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						// onUpdateColumn={BoardHelper.updateColumn}

						value={value}
					/>
				</div>
			);
		} else if (column.type == "category") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellCategory
						summary={summary}
						height={35}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						onUpdateBoard={this.props.onUpdateBoard}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "checklist") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellChecklist
						summary={summary}
						height={35}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						onUpdateBoard={this.props.onUpdateBoard}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "person") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellPerson
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						onUpdateBoard={this.props.onUpdateBoard}
						value={value}
						fixed={this.props.fixed}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		} else if (column.type == "timetrack") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellTimetrack
						summary={summary}
						values={summary ? values : null}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						row={this.props.row}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "rowlink") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellRowLink
						values={this.state.values}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						board={this.props.board}
						row={this.props.row}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
					/>
				</div>
			);
		} else if (column.type == "rowlinkfrom") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellRowLinkFrom
						values={this.state.values}
						onChange={() => {}}
						column={column}
						board={this.props.board}
						row={this.props.row}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						onUpdateColumn={BoardHelper.updateColumn}
					/>
				</div>
			);
		} else if (column.type == "mirror") {
			return <div style={{ width: "100%", height: "100%" }}>{this.props.t("board.text.missing_link", "missing link")}</div>;
		} else if (column.type == "formula" && column.options.is_string) {
			return (
				<div style={{ width: "100%", height: "100%", padding: 3, opacity: 0.75 }}>
					<Tooltip content={value}>
						<CellText
							readonly
							height={25}
							onChange={() => {}}
							column={column}
							onUpdateColumn={BoardHelper.updateColumn}
							onFocus={this.focusCell.bind(this)}
							onBlur={this.blurCell.bind(this)}
							value={value}
							disabled={column.disabled || column.values_locked}
						/>
					</Tooltip>
				</div>
			);
		} else if (column.type == "formula" && !column.options.is_string) {
			return (
				<div style={{ width: "100%", height: "100%", padding: summary ? 0 : 3, opacity: 0.75 }}>
					<CellNumber
						readonly
						height={summary ? 35 : 25}
						summary={summary}
						values={summary ? values : null}
						onChange={() => {}}
						column={column}
						onUpdateColumn={BoardHelper.updateColumn}
						onFocus={this.focusCell.bind(this)}
						onBlur={this.blurCell.bind(this)}
						value={value}
						disabled={column.disabled || column.values_locked}
					/>
				</div>
			);
		}
	}

	render() {
		const { fullWidth, column, row } = this.props;
		const style = {
			flexBasis: fullWidth ? null : column.width,
			maxWidth: fullWidth ? null : column.width,
			minWidth: fullWidth ? null : column.width,
			border: column.id === row?.deadline?.dateColumn?.id || column.id === row?.deadline?.statusColumn?.id ? "1px solid var(--accent)" : null,
		};
		const { empty } = this.getValue();

		const className = `
		board-column
		column-${column.id}
		column-${column.type}
		${this.state.focused ? "focused" : ""}
		${this.props.summary ? "board-summary" : ""}
		${column.type === "mirror" ? "board-mirror" : ""}
		${empty ? "empty" : ""}
		`;
		return (
			<div className={className} style={style}>
				{this.getCell()}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardCell);
