import React, { useMemo, useState } from "react";
import Tabs from "src/js/components/Tabs";
import styled from "styled-components";
import { useProspectContext } from "../../Context/ProspectContext";
import Overview from "./Views/Overview";
import Header from "./Views/SubViews/Header";
import Economy from "./Views/Economy";

const ProspectCompany = () => {
	const { t, company, isLoadingProspect, isLoadingProspectSummary, prospectSummary, refetchProspectSummary } = useProspectContext();

	const tabs = useMemo(() => {
		return [
			{
				id: "overview",
				value: "overview",
				content: t("prospects.view.prospect_company.tabs.overview", "Översikt"),
				render: () => (
					<Overview
						company={company}
						isLoading={isLoadingProspect}
						isLoadingSummary={isLoadingProspectSummary}
						summary={prospectSummary}
						refreshSummary={refetchProspectSummary}
					/>
				),
			},
			{
				id: "economy",
				value: "economy",
				content: t("prospects.view.prospect_company.tabs.economy", "Ekonomi"),
				render: () => <Economy company={company} isLoading={isLoadingProspect} />,
				disabled: !company,
			},
			// {
			// 	id: "comments",
			// 	value: "comments",
			// 	content: t("prospects.view.prospect_company.tabs.comments", "Kommentarer"),
			// 	render: () => <p>Kommentarer</p>,
			// },
		];
	}, [t, company, isLoadingProspectSummary, prospectSummary, isLoadingProspect, refetchProspectSummary]);

	const [activeTabIndex, setActiveTabIndex] = useState(0);

	const tab = tabs[activeTabIndex];
	return (
		<Wrapper>
			<StyledHeader>
				<Header />
				<Tabs style={{ marginTop: "0.65rem", marginBottom: "0.65rem" }} tabs={tabs} selected={activeTabIndex} onSelect={setActiveTabIndex} />
			</StyledHeader>
			{tab.render()}
		</Wrapper>
	);
};
export default ProspectCompany;

const Wrapper = styled.div``;
const StyledHeader = styled.div``;
