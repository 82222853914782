import { EmptyState } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "react-i18next";

const Empty = () => {
	const { t } = useTranslation(["dial_lists", "common"]);

	return (
		<EmptyState
			image="/assets/emptystate-files.png"
			heading={t("dial_lists.view.call_entries.empty_state.title", "Inga fler ring entries i listan") as string}
		>
			{t(
				"dial_lists.view.call_entries.empty_state.description",
				"Det finns inga fler ring entries i listan. Du kan importera fler via prospekt listor, kontakter/företag eller via excel import."
			)}
		</EmptyState>
	);
};
export default Empty;
