/* eslint-disable quotes */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Sheet from "src/js/components/sheet";
import { store } from "src/js/store";
import { Checkbox, FormLayout, Icon, Labelled, LegacyStack } from "@shopify/polaris";
import ContactSelector from "src/js/components/ContactSelector";
import TextField from "src/js/components/TextField";
import SearchField from "src/js/components/search_field";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import { connect, useSelector } from "react-redux";
import API from "src/js/API";
import Banner from "src/js/components/banner";
import transformRowDataToDifferentBoard from "src/js/Utilities/transformRowDataToDifferentBoard";
import { useDeepCompareEffect } from "use-deep-compare";
import styled from "styled-components";
import getDefaultForm from "./getDefaultForm";
import CellDateTime from "../../Workspaces/CellDateTime";
import BoardHelper from "../../Workspaces/BoardHelper";
import RowContactForm from "../../Workspaces/components/RowContactForm";
import Tabs from "src/js/components/Tabs";
import Toast from "src/js/components/Toast";
import { Link } from "react-router-dom";
import TaskCompleteModal from "../TaskCompleteModal";
import { formatDeadlineRows } from "../../Workspaces/boardutils/BoardUtils";
import { MobileAcceptMajor } from "@shopify/polaris-icons";
import Button from "src/js/components/Button";

interface TaskSheetProps extends WithTranslation {
	row?: Readonly<DeadlineBoardRowType | BoardRowType | null>;
	open: boolean;
	onComplete?: (row: BoardRowType) => void;
	onCompleteAwaited?: (row: BoardRowType) => void;
	onClose?: () => void;
	onOpen?: () => void;
	inline?: boolean;
	external?: boolean;

	// Options is an object with keys that are column types and values for the columnvalue["value"],
	// example: { contact: [123], person: [123], datetime: {datetime: "2020-01-01" }}
	options?: Record<BoardColumnType["type"], BoardColumnValueValueType>;
	optionsByTitle?: Record<string, BoardColumnValueValueType>;
	submitSuffix?: string;
	submitPrefix?: string;
	board?: BoardType;
}

const TaskSheet = ({
	row,
	open: propsOpen,
	onComplete,
	onCompleteAwaited,
	onClose,
	onOpen,
	inline,
	options = {},
	optionsByTitle = {},
	external,
	t,
	submitSuffix,
	submitPrefix,
	board: propsBoard,
}: TaskSheetProps) => {
	const [open, setOpen] = useState(propsOpen);
	const [isRemoving, setIsRemoving] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState<{ title: string; message: string } | any>();
	const [form, setForm] = useState<BoardRowType | DeadlineBoardRowType>(
		getDefaultForm({
			row,
			board:
				row?.board ||
				(row?.board_id && store.getState().boards[row?.board_id]) ||
				propsBoard ||
				store.getState().account.todo_board ||
				store.getState().user.todo_board,
			options,
			optionsByTitle,
		})
	);
	const boardId = form?.board?.id || form?.board_id || propsBoard || store.getState().account.todo_board || store.getState().user.todo_board_id_id;
	const board = useMemo(
		() => form?.board || store.getState().boards[boardId] || store.getState().account.todo_board || store.getState().user.todo_board,
		[boardId, form?.board]
	);
	const personSearchField = useRef<any>(undefined);
	const [privateTask, setPrivateTask] = useState(board?.id == store.getState().user.todo_board_id);
	const [selectedTabId, setSelectedTabId] = useState("task");
	const boardContacts = useSelector((state: any) => state.board_contacts);
	const boardCompanies = useSelector((state: any) => state.board_companies);

	useEffect(() => {
		setOpen(propsOpen);
		setSelectedTabId("task");
	}, [propsOpen]);

	useDeepCompareEffect(() => {
		const board =
			row?.board ||
			(row?.board_id && store.getState().boards[row?.board_id]) ||
			propsBoard ||
			store.getState().account.todo_board ||
			store.getState().user.todo_board;
		setForm(
			getDefaultForm({
				row,
				board,
				options,
				optionsByTitle,
			})
		);
		setPrivateTask(board?.id == store.getState().user.todo_board_id);
		setError(null);
		setIsRemoving(false);
	}, [open, row, options, optionsByTitle, propsBoard]);

	const columnsByTitle: Record<string, BoardColumnType> = useMemo(
		() =>
			board?.columns.reduce((acc: Record<string, BoardColumnType>, column: BoardColumnType) => {
				if (!column?.connected_column_id && acc[column.title]) return acc;

				return { ...acc, [column.title?.toLowerCase()]: column };
			}, {}),
		[board]
	);

	const columnsByType: Record<string, BoardColumnType> = useMemo(
		() =>
			board?.columns.reduce((acc: Record<string, BoardColumnType>, column: BoardColumnType) => {
				if (!column?.connected_column_id && acc[column.type]) return acc;

				return { ...acc, [column.type]: column };
			}, {}),
		[board]
	);

	const handleClose = (e?: any) => {
		setOpen(false);
		onClose?.();
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleOpen = () => {
		setOpen(true);
		onOpen?.();
	};

	const handleComplete = async () => {
		setError(null);
		setIsSaving(true);

		if (form.id) {
			try {
				const response = await API.put(`/api/boards/${board.id}/rows/${form.id}.json`, form);

				const data = response.data;
				if (data.error) throw new Error(data.error);

				Toast.success(t("task.responses.updated", "Uppdaterade Uppgift"));
				BoardHelper.updateRows([data.row], "update");
				await onCompleteAwaited?.(data.row);
				onComplete?.(data.row);
			} catch (error) {
				console.error(error);
				setError({ title: `Kunde inte uppdatera ${board?.singular}`, message: Toast.error(error) });
			}
		} else {
			try {
				const response = await API.post(`/api/boards/${board.id}/rows.json`, form);

				const data = response.data;
				if (data.error) throw new Error(data.error);

				Toast.success(t("task.responses.created", "Skapade Uppgift"));
				BoardHelper.updateRows([data.row], "add");
				await onCompleteAwaited?.(data.row);
				onComplete?.(data.row);
			} catch (error) {
				console.error(error);
				setError({ title: `Kunde inte skapa ${board?.singular}`, message: Toast.error(error) });
			}
		}

		setIsSaving(false);
	};

	const handleRemoveRow = async () => {
		setError(null);
		setIsRemoving(true);
		const rowToRemove = row as BoardRowType;
		try {
			form.removed = true;
			BoardHelper.removeRow(rowToRemove.id);

			const response = await API.delete(`/api/boards/${board.id}/rows/${rowToRemove.id}.json`);

			const data = response.data;
			if (data.error) {
				throw new Error(data.error); // Throw an error here
			}

			onClose?.();
			onComplete?.(data.row);

			Toast.success(t("row.responses.removed", "Tog bort rad") + " " + data.row.title);
		} catch (error) {
			console.error("handleRemoveRow: ", error);
			setError({ title: `Misslyckades med att ta bort ${board?.singular}`, message: Toast.error(error) });
		}

		setIsRemoving(false);
	};

	const getContactsFromRow = useCallback(
		async (row: BoardRowType) => {
			if (!row) return [];
			const board = row.board || store.getState().boards[row.board_id];

			const contactColumn = board?.columns?.find?.((column: BoardColumnType) => column.type === "contact");
			const contactIds = row?.column_values?.[contactColumn?.id]?.value;

			if (contactIds?.length) {
				const contactIdsToFetch = contactIds?.filter((contactId: number) => !boardContacts[String(contactId)]);
				if (contactIdsToFetch?.length) {
					await BoardHelper.fetchResources("contacts", contactIdsToFetch);
				}

				const contacts = contactIds?.reduce((acc: ContactType[], contactId: number) => {
					const contact = boardContacts[String(contactId)];
					if (contact) acc.push(contact);

					return acc;
				}, []);

				return contacts;
			}

			return [];
		},
		[boardContacts]
	);

	const getChangeHandler = useCallback(
		(columnId: string) => {
			return async (value: any) => {
				const updatedForm = {
					...form,
					column_values: {
						...form.column_values,
						[columnId]: {
							column_id: columnId,
							value,

							row_id: row?.id,
						},
					},
				};

				if (columnId === columnsByTitle?.["affär"]?.id) {
					const contacts = await getContactsFromRow(value);

					updatedForm.column_values[columnsByType?.contact.id] = {
						column_id: columnsByType?.contact.id,
						value: [
							...new Set([...(form.column_values[columnsByType?.contact.id]?.value || []), ...contacts.map((contact: ContactType) => contact.id)]),
						],
						row_id: row?.id,
					};

					updatedForm.column_values[columnId] = {
						column_id: columnId,
						value: [value?.id],
						row_id: row?.id,
					};
				}

				if (columnId === columnsByType?.person.id) {
					updatedForm.column_values[columnId] = {
						column_id: columnId,
						value: [value?.id],
						row_id: row?.id,
					};
				}

				setForm(updatedForm);
			};
		},
		[row?.id, columnsByTitle, columnsByType, form, getContactsFromRow]
	);

	const getFormChangeHandler = useCallback((field: string) => {
		return (value: any) => {
			setForm((form) => ({
				...form,
				[field]: value,
			}));
		};
	}, []);

	const getChangeContactHandler = (index: number) => {
		return (value: ContactType) => {
			const contacts = [...(form.column_values[columnsByType?.contact.id]?.value || [])];

			if (value) {
				contacts[index] = value.id;

				store.dispatch({
					type: "SET_BOARD_CONTACTS",
					contacts: [value],
				});
			} else {
				contacts.splice(index, 1);
			}

			getChangeHandler(columnsByType?.contact.id)(contacts);
		};
	};

	const onChangePrivateTask = useCallback(
		(checked) => {
			setPrivateTask(checked);

			setForm((form) => {
				const updatedForm = {
					...form,
					...(transformRowDataToDifferentBoard({
						row: form,
						board,
						toBoard: checked ? store.getState().user.todo_board : store.getState().account.todo_board,
					}) as any),
				};

				const newPersonColumn = updatedForm?.board?.columns?.find?.((column: BoardColumnType) => column.type === "person");
				if (checked || !updatedForm.column_values?.[newPersonColumn.id]?.value?.length) {
					updatedForm.column_values[newPersonColumn.id].value = [store.getState().user.id];
				}

				return updatedForm;
			});
		},
		[board]
	);

	const addContact = (value: ContactType) => {
		store.dispatch({
			type: "SET_BOARD_CONTACTS",
			contacts: [value],
		});

		const contactIds = form.column_values[columnsByType?.contact.id]?.value || [];
		contactIds.push(value.id);

		getChangeHandler(columnsByType?.contact.id)(contactIds);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const contacts =
		form?.column_values?.[columnsByType?.contact?.id]?.value?.map((id: number, index: number) => {
			return {
				...(boardContacts[String(id)] || boardCompanies[String(id)] || {}),
				id,
				index,
			};
		}) || [];

	const person = store.getState().users.find((u: UserType) => u.id == form.column_values[columnsByType?.person.id]?.value[0]);
	const dealId = form.column_values?.[columnsByTitle?.["affär"]?.id]?.value?.[0];
	const deal = store.getState().board_rows[dealId];

	const tabs = useMemo(() => {
		const items = [{ id: "task", content: row?.title || t("task.actions.new", "Ny uppgift") }] as any[];

		contacts.forEach((contact) => {
			if (!items.find((item) => item.id === `contact_${contact.id}`)) {
				items.push({ id: `contact_${contact.id}`, content: contact.parent ? contact.name : contact.fullname, contact });
			}

			if (contact.parent && !items.find((item) => item.id === `contact_${contact.id}`)) {
				items.push({ id: `contact_${contact.parent.id}`, content: contact.parent.name, contact: contact.parent });
			}
		});

		return items;
	}, [contacts, row?.title, t]);

	const handleTabChange = useCallback(
		(selectedTabIndex) => {
			setSelectedTabId(tabs[selectedTabIndex].id);
		},
		[tabs]
	);

	const link = columnsByType?.link && form.column_values[columnsByType?.link?.id]?.value;

	const getContent = (tab) => {
		if (!tab || tab.id === "task") {
			return (
				<div style={{ padding: "1.2500rem" }}>
					<FormLayout>
						{error && (
							<Banner status="critical" title={error?.title} onDismiss={() => setError(null)}>
								{error?.message}
							</Banner>
						)}

						<TextField
							label={t("task.fields.title.label", "Rubrik")}
							value={form.title}
							multiline
							onChange={getFormChangeHandler("title")}
							placeholder={t("task.fields.title.placeholder", "Rubrik..") as string}
						/>

						{columnsByType?.text && (
							<TextField
								label={t("task.fields.comment.label", "Beskrivning")}
								value={form.column_values[columnsByType?.text.id]?.value}
								multiline={2}
								onChange={getChangeHandler(columnsByType?.text.id)}
								placeholder={t("task.fields.comment.label", "Beskrivning...") as string}
							/>
						)}

						<div>
							<Labelled id="date" label={t("task.fields.date.label", "Datum")}>
								<CellDateTime
									value={form.column_values[columnsByType?.datetime.id]?.value}
									expanded
									column={columnsByType?.datetime}
									onChange={getChangeHandler(columnsByType?.datetime.id)}
								/>
							</Labelled>
						</div>

						<LegacyStack distribution="fillEvenly">
							<Checkbox
								disabled={!!form.id || !store.getState().user.todo_board_id || !store.getState().account.todo_board_id}
								label={t("todo.fields.private_task_checkbox", "Privat uppgift")}
								checked={privateTask}
								onChange={onChangePrivateTask}
							/>

							{!privateTask && (
								<SearchField
									// label={t("task.fields.person.label", "Person")}
									ref={personSearchField}
									placeholder="Person.."
									prefix={person && <ProfileAvatar user={person} size={20} />}
									// keepValue
									value={person?.name}
									resource="users_with_boards.json"
									onSelect={getChangeHandler(columnsByType?.person.id)}
									blurOnSelect
									id_handle="id"
									params={{
										enabled: 1,
									}}
									label_handle="name"
									resource_handle="users"
									resourceName={{
										singular: t("row.users.singular", "Person"),
										plural: t("row.users.plural", "Personer"),
									}}
									onFocus={() => {
										if (person?.id === store.getState().user?.id) {
											personSearchField.current?.clearInput();
										}
									}}
									clearOption={t("common.actions.clear", "Rensa")}
									// onBlur={() => {

									// }}
								/>
							)}
						</LegacyStack>

						{columnsByTitle?.["affär"] && (
							<SearchField
								label={t("task.fields.deal.label", "Affär")}
								placeholder={`${t("task.fields.deal.placeholder", "affär")}..`}
								keepValue
								value={deal?.title}
								resource="board_rows/search.json"
								params={{
									board_id: store.getState().account.sales_board_id,
									contact_ids: contacts.map((contact: ContactType) => contact.id),
								}}
								onSelect={getChangeHandler(columnsByTitle?.["affär"]?.id)}
								blurOnSelect
								id_handle="id"
								label_handle="title"
								resource_handle="rows"
								resourceName={{
									singular: t("row.deal.singular", "affär"),
									plural: t("row.deal.plural", "affärer"),
								}}
								clearOption={dealId && t("row.deal.none", "Ingen affär")}
							/>
						)}

						{columnsByType?.contact && (
							<Labelled id="" label={t("task.terms.contacts_company", "Kontakter/Företag") as string}>
								<LegacyStack vertical spacing="tight">
									{contacts?.map((contact: ContactType, index: number) => (
										<ContactSelector key={index} contact={contact} onChange={getChangeContactHandler(index)} />
									))}

									<ContactSelector
										prefix={<div style={{ height: 20, width: 20 }} />}
										key={form?.column_values?.[columnsByType?.contact.id]?.value?.length || 0}
										contact={null}
										onChange={addContact}
										// lock={!!this.props.contact}
										alwaysNull
										fixed
									/>
								</LegacyStack>
							</Labelled>
						)}

						{columnsByType?.link && link && (
							<Link to={link?.url} target="_blank">
								{link?.caption}
							</Link>
						)}
					</FormLayout>
				</div>
			);
		}

		if (tab?.contact) {
			return <RowContactForm row={row} loading={false} board={board} contact={tab.contact} style={{ padding: "0.6250rem" }} />;
		}
	};

	const primaryAction = {
		content: `${submitPrefix || ""}${row?.id ? (t("common:actions.save", "Spara") as string) : (t("common:actions.create", "Skapa") as string)}${
			submitSuffix || ""
		}`,
		disabled: isSaving,
		onAction: handleComplete,
		loading: isSaving,
	};

	const selectedTabIndex = tabs.findIndex((tab) => tab.id === selectedTabId);

	if (inline) {
		return (
			<div>
				{tabs?.length && <Tabs tabs={tabs as any} selected={selectedTabIndex} onSelect={handleTabChange} />}

				{getContent(tabs[selectedTabIndex])}
				{external && (
					<Button onClick={primaryAction.onAction} loading={primaryAction.loading} disabled={primaryAction.disabled}>
						{primaryAction.content}
					</Button>
				)}
			</div>
		);
	}

	const deadLineRows = row && formatDeadlineRows({ rows: [row as BoardRowType], boards: [board] });
	const deadLineRow = deadLineRows?.find((r) => r.id === row?.id && !r.deadline?.completed);

	return (
		<Sheet
			primaryAction={primaryAction}
			actions={[
				deadLineRow && !deadLineRow?.deadline?.completed && (
					<TaskCompleteModal
						activator={
							<Button primary icon={<Icon source={MobileAcceptMajor} color="success" />}>
								{t("task.actions.complete", "Klarmarkera") as string}
							</Button>
						}
						type="row"
						row={deadLineRow}
						onClose={(haveSubmit) => {
							// if (haveSubmit) refresh();
						}}
						onSuccess={(data: any) => {
							Toast.success(t("dashboard.terms.completed", "Uppgift slutförd"));
							if (data?.board_id) {
								setForm((form) => ({
									...form,
									row: data,
								}));
								BoardHelper.updateRows([data], "add");
								onComplete?.(data);
							}
						}}
					/>
				),
			].filter(Boolean)}
			secondaryActions={[
				row?.id &&
					({
						content: t("common:actions.delete", "Ta bort") as string,
						onAction: handleRemoveRow,
						loading: isRemoving,
						disabled: isRemoving,
						destructive: true,
						confirm: true,
					} as any),
				{
					content: t("common:actions.cancel", "Avbryt") as string,
					onAction: handleClose,
				},
			].filter(Boolean)}
			open={open}
			onClose={handleClose}
			title={row?.title || t("task.actions.new", "Ny uppgift")}
			scrollable
		>
			{tabs?.length && <Tabs tabs={tabs as any} selected={selectedTabIndex} onSelect={handleTabChange} />}
			<Wrapper> {getContent(tabs[selectedTabIndex])}</Wrapper>
		</Sheet>
	);
};

const mapStateToProps = (state) => ({
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
});

export default connect(mapStateToProps)(withTranslation(["tasks", "common"], { withRef: true })(TaskSheet));

const Wrapper = styled.div`
	.no_date_suffix > div {
		padding-left: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
`;
