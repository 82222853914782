import React from "react";
import IndexTable from "src/js/components/IndexTable";
import { useProspectContext } from "../Context/ProspectContext";
import { numberFormatter } from "src/js/Utilities";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import { useHistory } from "react-router-dom";
import getStatsBoxes from "../../Utilities/getStatsBoxes";

const BasicList = () => {
	const { id, t, stats, isLoadingStats, bulkActions } = useProspectContext();
	const history = useHistory();

	return (
		<IndexTable
			defaultSort="ID_ASC"
			promotedBulkActions={bulkActions}
			stats={getStatsBoxes(stats, isLoadingStats, t)}
			inline
			limit={20}
			resourceUrl={`/api/prospect_lists/${id}/prospects.json`}
			resourceHandle="prospects"
			resourceName={{
				singular: "prospekt",
				plural: "prospekt",
			}}
			onClick={(row) => {
				history.push(`/admin/prospect_lists/${id}/prospect/${row.id}`);
			}}
			columns={[
				{
					handle: "NAME",
					label: t("prospects.fields.name.label", "Namn"),
					render: (row: ProspectType) => row.axicon_company?.name,
				},
				{
					handle: "STATUS",
					label: t("prospects.fields.status.label", "Status"),
					render: (row: ProspectType) => {
						if (row.qualified) return <Badge color={Colors.green}>{t("prospects.fields.status.qualified", "Kvalificerad")}</Badge>;
						if (row.denied) return <Badge color={Colors.red}>{t("prospects.fields.status.denied", "Ignorerad")}</Badge>;

						return <Badge color={Colors.blue}>{t("prospects.fields.status.unhandled", "Ej bearbetad")}</Badge>;
					},
				},
				{
					handle: "ORGANISATIONAL_NUMBER",
					label: t("prospects.fields.organisational_number.label", "Organisationsnummer"),
					render: (row: ProspectType) => row.axicon_company?.org_number,
				},
				{
					handle: "TURNOVER",
					label: t("prospects.fields.turnover.label", "Omsättning"),
					render: (row: ProspectType) => {
						const serie = row.axicon_company?.graphs?.series?.find((s) => s.handle === "turnover");
						const value = serie?.data?.at(-1) as any;
						return numberFormatter({ value });
					},
				},
				{
					handle: "PROFIT",
					label: t("prospects.fields.profit.label", "Vinst/Förlust"),
					render: (row: ProspectType) => {
						const serie = row.axicon_company?.graphs?.series?.find((s) => s.handle === "profit");
						const value = serie?.data?.at(-1) as any;
						return numberFormatter({ value });
					},
				},
				{
					handle: "PROFIT_MARGIN",
					label: t("prospects.fields.profit_margin.label", "Vinstmarginal (%)"),
					render: (row: ProspectType) => {
						const serie = row.axicon_company?.graphs?.series?.find((s) => s.handle === "profit_margin");
						const value = serie?.data?.at(-1);
						return `${value || ""}%`;
					},
				},
				{
					handle: "CITY",
					label: t("prospects.fields.city.label", "Stad"),
					render: (row: ProspectType) => row.axicon_company?.city,
				},
			]}
			defaultSavedSearches={[
				{
					id: "qualified",
					title: t("prospects.saved_searches.qualified", "Kvalificerade"),
					filters: [
						{
							key: "qualified",
							value: 1,
						},
					],
				},
				{
					id: "denied",
					title: t("prospects.saved_searches.denied", "Ignorerade"),
					filters: [
						{
							key: "denied",
							value: 1,
						},
					],
				},
				{
					id: "unhandled",
					title: t("prospects.saved_searches.unhandled", "Ej bearbetade"),
					filters: [
						{
							key: "unhandled",
							value: 1,
						},
					],
				},
			]}
		/>
	);
};
export default BasicList;
