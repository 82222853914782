import { Button, DropZone, FormLayout, Icon, Select, Spinner, LegacyStack, Tooltip, ProgressBar, Checkbox, Label } from "@shopify/polaris";
import { CalendarMajor, CustomersMajor, ImportMinor, MentionMajor, MobileAcceptMajor, MobileCancelMajor, PlusMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import AdditionCommentAction from "src/js/components/Activities/AdditionCommentAction";
import Attachment from "src/js/components/Attachment";
import Mention from "src/js/components/Mentions";
import Modal from "src/js/components/modal";
import ResourcePicker from "src/js/components/resource_picker";
import { store } from "src/js/store";
import DatePickerCalendarTextField from "src/js/components/DatePickerCalendarTextField";
import ContactSelector from "src/js/components/ContactSelector";
import styled from "styled-components";
import API from "src/js/API";
import moment from "moment";
import TextField from "src/js/components/TextField";
import RenderEmail from "src/js/components/Emails/RenderEmail";
import { getRowTypeLabel } from "../Workspaces/boardutils/BoardUtils";
import BoardHelper from "../Workspaces/BoardHelper";
import Toast from "src/js/components/Toast";

interface BaseProps extends WithTranslation {
	open?: boolean;
	onClose?: (haveSubmit?: boolean) => void;
	activator?: React.ReactElement;
	onSuccess?: (res?: CommentType | BoardRowType) => any;
	comment?: CommentType;
	submitDisabled?: boolean;
}

interface RowTaskCompleteModalProps extends BaseProps {
	type?: "row";
	row: DeadlineBoardRowType;

	calendar?: any;
	contacts?: ContactType[];
}

interface CommentTaskCompleteModalProps extends BaseProps {
	type: "comment";

	row?: DeadlineBoardRowType;
	calendar?: any;
	contacts?: ContactType[];
}

type TaskCompleteModalProps = RowTaskCompleteModalProps | CommentTaskCompleteModalProps;

// This component is used to mark a row/task as compleeted, create a new comment or update an existing comment
const TaskCompleteModal = React.forwardRef(
	(
		{
			row,
			t,
			activator,
			open: propsOpen,
			onClose,
			onSuccess,
			calendar,
			comment: propsComment,
			type: propsType,
			contacts: propsContacts,
			submitDisabled,
		}: TaskCompleteModalProps,
		ref
	) => {
		const [loading, setLoading] = useState(false);
		const [removeLoading, setRemoveLoading] = useState(false);
		const [open, setOpen] = useState(false);
		const [type, setType] = useState<"row" | "comment">(propsType || (row ? "row" : "comment"));
		const [followUpIsChecked, setFollowUpIsChecked] = useState(false);
		const getDefaultComment = useCallback((data = {}): CommentType => {
			return {
				content: "",
				// date: row?.deadline?.date || moment().format("YYYY-MM-DD HH:mm"), // Ska vi använda raderad datum eller dagens datum?
				date: moment().format("YYYY-MM-DD HH:mm"), // Ska vi använda raderad datum eller dagens datum?
				activity: null,

				files: [],
				mentions: [],
				...(data || {}),
			};
		}, []);
		const [contactIds, setContactIds] = useState<number[]>(propsContacts?.filter(Boolean)?.map((c) => c.id) || row?.contactValue || []);
		const [comment, setComment] = useState<CommentType>(getDefaultComment());
		const [openFileDialog, setOpenFileDialog] = useState(false);
		const [progress, setProgress] = useState(0);

		useImperativeHandle(ref, () => ({
			setOpen(a) {
				setOpen(a);
			},
		}));

		useEffect(() => setOpen(!!propsOpen), [propsOpen]);

		useEffect(() => {
			if (open) {
				setComment(getDefaultComment(propsComment));
			}
		}, [open, getDefaultComment, propsComment]);

		useEffect(() => {
			if (open) {
				setContactIds(propsContacts?.map((c) => c.id) || row?.contactValue || []);
			}
		}, [open, row?.contactValue, propsContacts]);

		const activityOptions = [
			type === "comment" && propsContacts?.length
				? { label: t("activities.choose", "Välj aktivitet"), value: "", disabled: true }
				: { label: t("activities.none", "Ingen aktivitet"), value: "" },
		];

		store.getState().contact_activities?.forEach(({ title, id, type }) => {
			if (type !== "integration-email") {
				activityOptions.push({
					label: title,
					value: String(id),
					// disabled: type === "integration-email"
				});
			}
		});

		if (comment.activity?.type == "integration-email") {
			activityOptions.push({
				label: comment.activity?.title,
				value: String(comment.activity?.id),
				disabled: true,
			});
		}

		const handleCommentChange = useCallback((field: string) => {
			return (value) => {
				setComment((c) => {
					const newComment = { ...c, [field]: value };

					return newComment;
				});
			};
		}, []);

		const handleContactChange = useCallback((index) => {
			return (contact) => {
				setContactIds((c) => {
					const contactsIds = [...(c || [])];
					if (contact) {
						contactsIds?.splice(index, 1, contact?.id);
					} else {
						contactsIds?.splice(index, 1);
					}

					return contactsIds;
				});
			};
		}, []);

		const handleAddContact = useCallback((contact) => {
			setContactIds((c) => {
				const contactsIds = [...(c || [])];
				contactsIds.push(contact?.id);
				return contactsIds;
			});
		}, []);

		const handleOpen = () => {
			setOpen(true);
		};

		const reset = useCallback(
			(followUpIsChecked?: boolean) => {
				setComment(
					getDefaultComment(
						followUpIsChecked
							? {
									activity: store.getState().contact_activities?.find((a) => a.type === "follow_up"),
							  }
							: {}
					)
				);
				setFollowUpIsChecked(false);
				setLoading(false);
				setProgress(0);
			},
			[getDefaultComment]
		);

		const handleClose = useCallback(
			(final = false) => {
				onClose?.(final);
				setOpen(false);
				setContactIds([]);

				reset();
			},
			[onClose, reset]
		);

		const handleRemoveComment = useCallback(() => {
			setRemoveLoading(true);
			API.delete(`/api/comments/${comment.id}.json`)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					Toast.success(t("comments.responses.deleted", "Aktivitet har tagits bort"));
					onSuccess?.();
					handleClose?.(true);
					return result.data;
				})
				.catch((error) => {
					Toast.error(error);
				})
				.finally(() => {
					setRemoveLoading(false);
				});
		}, [t, comment.id, onSuccess, handleClose]);

		const submitStatus = async (color) => {
			const statusColumn = row?.deadline?.statusColumn;

			if (statusColumn) {
				const value = statusColumn.options.statuses.find((s) => s.color === color)?.id;
				// await this.updateCell(statusColumn, { value });

				try {
					const result = await BoardHelper.onUpdateValue(row, statusColumn, {
						value,
					});
					return result;
				} catch (e) {
					console.error("Error", e);
				}
			}

			return true;
		};

		const submitContactOnRow = async (contactsIds) => {
			const contactColumn = row?.contactColumn;

			try {
				const result = await BoardHelper.onUpdateValue(row, contactColumn, {
					value: contactsIds,
				});
				return result;
			} catch (e) {
				console.error("Error", e);
			}
		};

		const submitComment = async (comment: CommentType) => {
			if (row) {
				comment.completed_row = row;
			}
			return API.post(
				`/api/contacts/${comment.contact?.id}/comments.json`,
				{ ...comment },
				{
					params: {},
				}
			)
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					return result.data;
				})
				.catch((error) => {
					Toast.error(error);
				});
		};
		const submitRowComment = async (comment, row) => {
			return API.post(`/api/boards/${row?.board_id || row?.board.id}/rows/${row?.id}/comments.json`, { comment: comment.content }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						Toast.error(result.data.error);
						return;
					}
					return result.data;
				})
				.catch((error) => {
					Toast.error(error);
				});
		};

		const onFinalSubmit = async ({ row, comment }: { row?: DeadlineBoardRowType; comment?: CommentType }) => {
			onSuccess?.(row || comment);

			switch (row?.board_id) {
				case store.getState().user.todo_board_id: {
					const refreshEvent = new CustomEvent("refreshTasks");
					window.dispatchEvent(refreshEvent);
					break;
				}
				case store.getState().user.calendar_board_id: {
					const refreshEvent = new CustomEvent("refreshCalendar");
					window.dispatchEvent(refreshEvent);
					break;
				}
				case store.getState().account.sales_board_id: {
					const refreshEvent = new CustomEvent("refreshDeals");
					window.dispatchEvent(refreshEvent);
					break;
				}
				default:
					break;
			}

			handleClose(true);
		};

		const handleSubmitUpdateComment = async () => {
			await API.put(`/api/comments/${comment.id}.json`, {
				...comment,
				contact: contactIds?.[0]
					? {
							id: contactIds?.[0],
					  }
					: null,
				contacts: contactIds?.map((id) => ({ id })),
			})
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						Toast.error(result.data.error);
						return;
					}

					onSuccess?.(result.data.comment);

					Toast.success(t("timeline.responses.timeline_updated", "Aktivitetslog uppdaterad"));
				})
				.catch((error) => {
					console.error("error:", error);
					Toast.error(error);
				});
		};

		const submitLinkedRowComments = async (comment) => {
			if (!row) return true;
			const columns = row?.board.columns.filter((c) => c.type === "rowlink");
			const linkedRowsIds = columns.flatMap((c) => row?.column_values?.[c.id]?.value);
			const rows = linkedRowsIds.map((id) => store.getState().board_rows[id]);

			const promises = rows
				?.filter((r) => (r.id && r.board_id) || r.board)
				.map((row) => {
					return submitRowComment(comment, row);
				});

			return Promise.allSettled(promises);
		};

		const handleSubmit = async (comment, runOnSuccess = false) => {
			setProgress(10);
			setLoading(true);

			if (comment.id) {
				setProgress(30);
				await handleSubmitUpdateComment();
				setProgress(100);
				setOpen(false);
			} else {
				await Promise.allSettled([
					comment.activity &&
						comment?.content &&
						contactIds?.length &&
						submitComment({ ...comment, contact: { id: contactIds?.[0] }, contacts: contactIds?.map((id) => ({ id })) }).then((res) => {
							setProgress((p) => p + 30);
							return res;
						}),
					comment.activity &&
						row?.id &&
						row?.contactColumn &&
						contactIds?.length &&
						submitContactOnRow(contactIds).then((res) => {
							setProgress((p) => p + 30);
							return res;
						}),
					row?.id &&
						row?.board_id &&
						comment?.content &&
						submitRowComment(comment, row).then((res) => {
							setProgress((p) => p + 30);
							return res;
						}),
					submitLinkedRowComments(comment).then((res) => {
						setProgress((p) => p + 30);
						return res;
					}),
				])
					.then((results) => {})
					.catch((error) => {
						console.error("submitComments error:", error);
					});

				if (row?.id) await submitStatus("#00c875");
				setProgress(100);

				if (followUpIsChecked) {
					setType("comment");
				} else {
					setOpen(false);
				}

				if (runOnSuccess) {
					if (type == "row") {
						onFinalSubmit({ row });
					} else if (type == "comment") {
						onFinalSubmit({ comment });
					}
				}
			}

			reset(followUpIsChecked);
		};

		const updateFile = (index: number) => {
			return (file) => {
				setComment((prevComment) => {
					const updatedFiles = [...prevComment.files];
					updatedFiles[index] = file;
					return { ...prevComment, files: updatedFiles };
				});
			};
		};

		const addFile = (file: FileType) => {
			return (event) => {
				const data = event.target.result;

				setComment((c) => ({ ...c, files: [...(c.files || []), { name: file.name, size: file.size, data }] }));
			};
		};

		const getContactIdsFromRow = (row: BoardRowType) => {
			const contactColumn = store.getState().boards[row.board_id]?.columns.find((c) => c.type === "contact");
			return row?.column_values?.[contactColumn?.id]?.value || [];
		};

		const activatorComp =
			activator === undefined ? (
				<Button
					// loading={loading}
					onClick={handleOpen}
					plain
					icon={loading ? <Spinner size="small" /> : <Icon source={MobileAcceptMajor} color="success" />}
				/>
			) : (
				activator
			);

		const activatorButton = React.isValidElement(activatorComp)
			? React.cloneElement(activatorComp as any, {
					onClick: handleOpen,
					loading,
			  })
			: null;

		const disabled = submitDisabled;

		const primaryAction = (() => {
			if (disabled) return undefined;
			switch (type) {
				case "row": {
					return {
						content: t("tasks.mark_as_complete", "Klarmarkera") as string,
						loading,
						onAction: () => handleSubmit(comment, !followUpIsChecked),
						disabled: !!(comment.activity && (!comment.content || !contactIds?.length)),
					};
				}
				case "comment": {
					if (comment.id) {
						return {
							content: t("common.actions.save", "Spara") as string,
							loading,
							onAction: () => handleSubmit(comment, !followUpIsChecked),
							disabled: !comment.content || !contactIds?.length,
						};
					}
					return {
						content: t("common.actions.create", "Skapa") as string,
						loading,
						onAction: () => handleSubmit(comment, !followUpIsChecked),
						disabled: !comment.activity || !comment.content || !contactIds?.length,
					};
				}
				default:
					return undefined;
			}
		})();

		const title = (() => {
			switch (type) {
				case "row": {
					return `${t("tasks.mark_as_complete", "Klarmarkera")} "${row?.title}"`;
				}
				case "comment": {
					if (comment.id) return t("timeline.modals.edit.title", "Redigera aktivitet");
					return t("timeline.modals.create.title", "Skapa ny aktivitet");
				}
				default:
					return undefined;
			}
		})();

		const getDeal = () => {
			const column = row?.board?.columns?.find((c) => c.title === "Affär");
			const dealIds = row?.column_values?.[column?.id]?.value;

			return dealIds;
		};

		return (
			<>
				<div
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					{activatorButton}
				</div>
				<Modal
					title={title}
					open={open}
					onClose={() => handleClose()}
					primaryAction={primaryAction}
					secondaryActions={[
						{
							content: t("common.actions.close", "Stäng") as string,
							onAction: () => handleClose(),
						},
						{
							content: t("common.actions.remove", "Ta bort") as string,
							onAction: handleRemoveComment,
							confirm: true,
							destructive: true,
							loading: removeLoading,
							enabled: type === "comment" && !!comment.id,
						},
					].filter((i) => !("enabled" in i) || i.enabled)}
				>
					<Modal.Section>
						<Wrapper>
							<ProgressBarWrapper progress={progress}>
								<ProgressBar progress={progress} size="small" color="success" />
							</ProgressBarWrapper>
							<FormLayout>
								<Select
									label={t("aktivities.singular", "Aktivitet")}
									options={activityOptions}
									onChange={(v) => {
										setComment((c) => ({ ...c, activity: store.getState().contact_activities.find((ca) => ca.id == v) }));
									}}
									value={comment.activity?.id ? String(comment.activity?.id) : ""}
									disabled={!!comment.id}
								/>

								{(!comment?.activity || !["follow_up", "new_deal"].includes(comment?.activity?.type)) &&
									(comment?.activity?.type === "integration-email" ? (
										<RenderEmail email={comment.content} comment={comment} />
									) : (
										<TextField
											label={t("activities.comment.singular", "Kommentar")}
											autoComplete="off"
											value={comment.content}
											onChange={handleCommentChange("content")}
											// onBlur={() => this.handleOpenStatusModal()}
											placeholder={t("dashboard.todo.fields.comment.label", "kommentar...") as string}
											multiline={2}
											// focused={!this.props.justContent}
											labelHidden
											requiredIndicator
											readOnly={!!disabled}
											// label='Kommentar'
											suffix={
												<LegacyStack spacing="extraTight">
													<Tooltip preferredPosition="above" content={t("timeline.text.upload", "Ladda upp fil")}>
														<Button
															plain
															size="slim"
															onClick={() => {
																setOpenFileDialog(true);
															}}
														>
															{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
															{/* @ts-ignore */}
															<Icon color="base" source={ImportMinor} />
														</Button>
													</Tooltip>
													<Tooltip preferredPosition="above" content={t("timeline.text.mention.coworker", "Nämn en medarbetare")}>
														<ResourcePicker
															size="slim"
															caption={<Icon color="base" source={MentionMajor} />}
															fixedCaption
															resource="users"
															fullWidth={false}
															item={null}
															onChange={(user: UserType) => {
																setComment((c) => ({ ...c, mentions: [...(c.mentions || []), user] }));
															}}
															plain
															id_handle="id"
															resource_handle="users"
															label_handle="fullname"
															resourceName={{
																singular: t("timeline.users.singular", "användare"),
																plural: t("timeline.users.plural", "användare"),
															}}
														/>
													</Tooltip>
												</LegacyStack>
											}
										/>
									))}
								{comment.activity && (
									<React.Fragment>
										{contactIds?.map((id, index) => {
											const contact = BoardHelper.getContact(id);
											return (
												<div style={{ paddingBlock: "0.3125rem" }} key={index}>
													<LegacyStack>
														<Icon source={CustomersMajor} />

														<ContactSelector
															hideIcon
															placeholder={t("timeline.fields.add_contact", "Lägg till kontaktperson")}
															contact={contact}
															onChange={handleContactChange(index)}
															lock={disabled}
															// hideParent={comment.id && !contact?.is_company}

															disabled={disabled}

															// hidePerson={this.props.contact?.is_company}
														/>

														<RemoveButton
															onClick={() => {
																setContactIds((contactsIds) => {
																	contactsIds?.splice(index, 1);
																	return [...contactsIds];
																});
															}}
														>
															<Icon source={MobileCancelMajor} />
														</RemoveButton>
													</LegacyStack>
												</div>
											);
										})}

										{/* {!comment.id && !disabled && ( */}
										{!disabled && (
											<div style={{ paddingBlock: "0.3125rem" }} key={contactIds?.length}>
												<LegacyStack>
													<Icon source={PlusMinor} />

													<ContactSelector
														key={contactIds?.length}
														hideIcon
														placeholder={t("timeline.fields.add_contact", "Lägg till kontaktperson")}
														onChange={handleAddContact}
														// onCreateContact={onNewContact}
														alwaysNull
														// hidePerson={this.props.contact?.is_company}
													/>
												</LegacyStack>
											</div>
										)}
									</React.Fragment>
								)}
								{comment?.row && <TextField label={getRowTypeLabel(comment?.row)} readOnly value={comment.row?.title} />}
								{!comment?.row && (
									<AdditionCommentAction
										submitSuffix={type === "row" ? (t("timeline.ations.followup.suffix", " och klarmarkera") as string) : ""}
										// submitPrefix={t("timeline.ations.followup.suffix", "Klarmarkera och ") as string}
										inline
										open
										activity={comment.activity}
										onSuccess={(newComment) => {
											const rowContactIds = getContactIdsFromRow(newComment?.row);
											setContactIds(() => [...new Set([...contactIds, ...rowContactIds])]);

											if (["new_deal", "follow_up"].includes(newComment.activity?.type)) {
												newComment.content = `Skapade ${getRowTypeLabel(newComment.row)} "${newComment?.row?.title}"`;
											}

											const mergedComment = { ...comment, ...newComment };

											setComment(mergedComment);
											setTimeout(() => {
												handleSubmit(mergedComment, !followUpIsChecked);
											}, 1);
										}}
										contacts={BoardHelper.getContact(contactIds)}
										options={{ deal: getDeal() }}
										primaryActionContent={primaryAction?.content}
									/>
								)}

								{!!comment.mentions?.length && (
									<LegacyStack>
										{comment.mentions.map((user) => (
											<Mention
												key={user.id}
												user={user}
												onRemove={() => {
													setComment((prevComment) => ({
														...prevComment,
														mentions: prevComment.mentions.filter((usr) => usr.id != user.id),
													}));
												}}
												disabled={disabled}
											/>
										))}
									</LegacyStack>
								)}

								{!!comment.files?.length &&
									comment.files.map((file, index) => (
										<Attachment
											plain
											editable={!disabled}
											disabled={disabled}
											icon={null}
											key={index}
											onChange={updateFile(index)}
											file={file}
											onRemove={() => {
												setComment((prevComment) => ({
													...prevComment,
													files: prevComment.files.filter((_, i) => i !== index),
												}));
											}}
										/>
									))}

								{comment.activity && !["follow_up", "new_deal"].includes(comment.activity?.type) && (
									<LegacyStack alignment="center">
										<Icon source={CalendarMajor} />

										<DatePickerCalendarTextField
											icon={null}
											date={comment && comment.date}
											onChange={handleCommentChange("date")}
											style={{ margin: "0.3125rem 0" }}
											disabled={disabled}
										/>
									</LegacyStack>
								)}

								{!comment.id && !disabled && (
									<StyledCheckboxWrapper
										key={`new_activity_${String(!!followUpIsChecked)}`}
										onClick={(event) => {
											event.preventDefault();
											event.stopPropagation();
											setFollowUpIsChecked((c) => !c);
										}}
									>
										<Checkbox labelHidden label={t("agenda.fields.new_activity.label", "Skapa ny aktivitet efteråt")} checked={followUpIsChecked} />
										<Label id="followup_activity">{t("agenda.fields.new_activity.help", "Skapa en ny aktivitet efter att denna är klar")}</Label>
									</StyledCheckboxWrapper>
								)}

								<div style={{ width: 50, height: 50, display: "none" }}>
									<DropZone
										openFileDialog={openFileDialog}
										onFileDialogClose={() => {
											setOpenFileDialog(false);
										}}
										// eslint-disable-next-line @typescript-eslint/no-unused-vars
										onDrop={(files, acceptedFiles, rejectedFiles) => {
											acceptedFiles.forEach((file) => {
												const reader = new FileReader();
												reader.onload = addFile(file);
												reader.readAsDataURL(file);
											});
										}}
									>
										<DropZone.FileUpload
											actionTitle={t("common.upload.actions.choose", "Välj fil") as string}
											actionHint={t("common.upload.actions.helptext", "Eller dra och släpp en fil") as string}
										/>
									</DropZone>
								</div>
							</FormLayout>
						</Wrapper>
					</Modal.Section>
				</Modal>
			</>
		);
	}
);

export default withTranslation(["tasks", "acitvities", "common"], { withRef: true })(TaskCompleteModal);

const ProgressBarWrapper = styled.div<{ progress?: number }>`
	position: absolute;

	inset: -1px -1px 0px -1px;
	bottom: unset;
	width: calc(100% + 2px);

	.Polaris-ProgressBar {
		border-radius: 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		border-top-right-radius: 7px;
		border-top-left-radius: 7px;

		background-color: transparent;
	}
`;

const Wrapper = styled.div`
	.mention {
		transition: all 0.2s ease-in-out;

		&:hover {
			opacity: 0.75;
			filter: brightness(0.75);
		}
	}
`;

const RemoveButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.5;
	transition: opacity 0.2s ease-in-out;

	.Polaris-Icon {
		width: 0.9375rem;
		height: 0.9375rem;
	}

	&:hover {
		opacity: 1;
		.Polaris-Icon {
			fill: var(--red);
		}
	}
`;

const StyledCheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.3125rem;
	cursor: pointer;
	* {
		cursor: pointer;
	}
`;
