import React from "react";
import CustomCard from "src/js/components/CustomCard";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const LegalDescription = ({ company }) => {
	const { t } = useTranslation(["prospects", "common"]);
	const updatedText = (company?.legal_text || "")?.replace?.(/\n\s{4}/g, "\n - ");
	return (
		<Wrapper>
			<CustomCard sectioned title={t("prospects.subview.LegalDescription.title", "Juridisk information")} subdued collapsible open={false}>
				<Description>{updatedText}</Description>
			</CustomCard>
		</Wrapper>
	);
};
export default LegalDescription;

const Wrapper = styled.div`
	.Polaris-LegacyCard {
	}
`;
const Description = styled.p`
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
	overflow-wrap: break-word;
`;
