/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { RefObject, useEffect, useRef } from "react";
import { IndexTable as PolarisIndexTable } from "@shopify/polaris";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from "styled-components";
import type { IndexTableColumn, IndexTableRow as IndexTableRowType } from "./types";
// import { useEventListener } from "src/js/hooks/useEventListener";

const IndexTableRow = ({ selectedResources, columns, item, renderCell, index, id, onClick, total, toggleSelect }: IndexTableRowType) => {
	const ref: RefObject<any> = useRef(null);

	// useEventListener(
	// 	"click",
	// 	(e) => {
	// 		const row = ref.current?.closest(".Polaris-IndexTable__TableRow")?.querySelector(".Polaris-IndexTable__TableCell--first");
	// 		const isFirstCell = row?.contains(e.target);
	// 		e.stopPropagation();
	// 		if (isFirstCell && toggleSelect) {
	// 			toggleSelect?.(item);
	// 			return;
	// 		}

	// 		onClick(item);
	// 	},
	// 	ref.current?.closest(".Polaris-IndexTable__TableRow"),
	// 	Boolean(ref.current?.closest(".Polaris-IndexTable__TableRow"))
	// );

	return (
		<PolarisIndexTable.Row
			id={id}
			key={`id-${item?.updated_at || ""}`}
			selected={selectedResources?.includes(id)}
			position={index}
			onClick={onClick ? () => onClick(item) : undefined}
			subdued={!!total}
			disabled={!!total}
			// status={(!!total && "subdued") || undefined}
		>
			{columns.map((column: IndexTableColumn, index: number) => {
				const content = column.render ? (
					column.render(item, column)
				) : renderCell ? (
					renderCell(item, column)
				) : index === 0 ? (
					<div>
						<div className="CustomerListItem__Profile">
							<h3 className="CustomerListItem__Title">{item[column.handle?.toLowerCase()]}</h3>
						</div>
					</div>
				) : (
					item[column.handle?.toLowerCase()]
				);
				return (
					<PolarisIndexTable.Cell key={column.handle}>
						{total ? <b>{content}</b> : content}
						{index === 0 && <span ref={ref} />}
					</PolarisIndexTable.Cell>
				);
			})}
			{/* <Td ref={ref} /> */}
		</PolarisIndexTable.Row>
	);
};
export default IndexTableRow;
// const Td = styled.td<{ ref: any }>``;
