import React, { Component } from "react";
import moment from "moment";
import { ProfileMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Toast from "src/js/components/Toast";
import IndexTable from "src/js/components/IndexTable";

class Pdf extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	async bulkRemove() {
		this.setState({ removing: true });
		return await API.put("/api/pdf_templates/bulk_remove.json", { ids: this.state.selectedItems })
			.then((result) => {
				this.setState({ removing: false, selectedItems: null });
				this.refresh();
				if (result.data.error) {
					Toast.error(result.data.error);
				}
				return true;
			})
			.catch((error) => {
				Toast.error(error);
				return false;
			});
	}

	gotoPdf(item) {
		item.path = "/admin/pdf_templates/" + item.id;
		item.variables = [];
		this.props.history.push("/admin/pdf_templates/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("pdf.index.breadcumb", "PDFs"),
					item,
				},
			],
		});
	}

	render() {
		const filters = [];
		const columns = [
			{
				handle: "MEDIA",
				label: "",

				render: (item) => <img alt="" src={item.thumbnail_url} style={{ objectFit: "cover", height: "65px" }} />,
			},
			{
				handle: "NAME",
				label: this.props.t("pdf.columns.name.label", "Namn"),
				sortable: true,
				type: "text",
				render: (item) => item.title,
			},
			{
				handle: "NUMBER_OF_PAGES",
				label: this.props.t("pdf.columns.number_of_pages.label", "Antal sidor"),
				render: (item) => item.number_of_pages,
			},
			{
				handle: "UPDATE_AT",
				label: this.props.t("pdf.columns.updated_at.label", "Senast aktivitet"),
				sortable: true,
				type: "text",
				render: (item) => (item.updated_at ? moment(item.updated_at).locale("sv").calendar() : "–"),
			},
			{
				handle: "CREATED_AT",
				label: this.props.t("pdf.columns.created_at.label", "Skapad"),
				sortable: true,
				type: "text",
				render: (item) => (item.created_at ? moment(item.created_at).locale("sv").calendar() : "–"),
			},
		];

		const bulkActions = [
			{
				items: [
					{
						icon: ProfileMajor,
						content: this.props.t("pdf.bulk_actions.remove", "Ta bort PDFs"),
						onAction: this.bulkRemove.bind(this),
						loading: this.state.removing,
					},
				],
			},
		];

		return (
			<IndexTable
				history={this.props.history}
				setRefreshHandler={(refreshHandler) => {
					this.refresh = refreshHandler;
				}}
				title={this.props.t("pdf.templates.title", "PDF mallar")}
				primaryAction={{
					content: this.props.t("pdf.actions.new", "Ny PDF"),
					onAction: () => this.props.history.push("/admin/pdf_templates/new"),
				}}
				savedSearchHandle="pdf_mallar"
				resourceUrl="/api/pdf_templates.json"
				resourceHandle="pdf_templates"
				onSelectionChange={this.onSelectionChange.bind(this)}
				resourceName={{
					singular: this.props.t("pdf.tempaltes.singular", "PDF mall"),
					plural: this.props.t("pdf.tempaltes.plural", "PDF mallar"),
				}}
				selectedItems={this.state.selectedItems}
				filters={filters}
				columns={columns}
				defaultSort="LAST_ACTIVITY_DESC"
				onClickRow={this.gotoPdf.bind(this)}
				bulkActions={bulkActions}
			/>
		);
	}
}
export default withTranslation(["pdf", "common"], { withRef: true })(Pdf);
